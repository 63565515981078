import React from 'react';
// import { NavLink } from "react-router-dom";
import copy from 'copy-to-clipboard';
import './referral.css'
import config from '../../config/config'
import { retrunMyRefName, KeepDecimals, showHtmlText, balanceFormat } from "../../libs/Util"
import TopCompontent from '../base/TopCompontent/TopCompontent'
import { lang } from "../../i18n/lang/lang"
import i18next from 'i18next'

import questionMark from '../../static/images/referral/question-mark.svg'
import icon1 from '../../static/images/referral/icon1.png'
import icon2 from '../../static/images/referral/icon2.png'
import icon3 from '../../static/images/referral/icon3.png'
import trophy from '../../static/images/referral/trophy.png'
import pig from '../../static/images/referral/pig.png'
import comesoon from '../../static/images/referral/comesoon.png'
import invitationBgCn from '../../static/images/referral/invitation-bg-cn.png'
import invitationBgEn from '../../static/images/referral/invitation-bg-en.png'




import store from "../../stores";
const Store = store.store

export default class Referral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReferral: false,
            invitationLink: '',
            isShowWeakHintMain: false,
            isLoading: false,
            isClaimLoading: false,
            isError: false,

            total: '0',
            earned: '0',
            level1: '0',
            level2: '0',

            referralFee: 0,
            referralFeeDisplay: '1BNB'
        }
        this.t = lang
        this.account = Store.getStore('global','account')
        this.registeredInput = ''
        this.config = config
    }

    async initDate(){
        let _this = this
        this.config = config[Store.getStore('global', 'networkType')]
        let myRefName = await Store.getReferralName()
        let referralFee = await Store.getReferralFee()
        let decimals = this.config[this.config.defaultWalletToken].decimals
        let fee = Number(balanceFormat(String(referralFee), decimals))
        fee  = `${fee} ${this.config.defaultWalletToken.toUpperCase()}`
        
        this.setState({
            referralFeeDisplay: fee
        })

        let lastRefname = retrunMyRefName(myRefName)
        if (lastRefname) {
            _this.setState({isReferral: true})
            let invitationLink = window.location.origin + "#" + lastRefname
            this.setState({invitationLink: invitationLink})
            Store.getPlayerInfo().then(res => {
                let result = res
                var total = Number.parseInt(result[0], 10) / 1e18;
                var earned = Number.parseInt(result[1], 10) / 1e18;
                this.setState({
                    total: KeepDecimals(total.toString(), 3),
                    earned: KeepDecimals(earned.toString(), 3),
                    level1: result[2],
                    level2: result[3]
                })
            })
        } else {
            _this.setState({isReferral: false})
        }
    }

    componentDidMount () {
        if (this.account === '') return
        this.initDate()
    }

    async listenWallet(){
        this.initDate()
    }
    
    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }

    componentWillUnmount () {
    }
    submitReferral(){
        let _this = this
        return
        let refName = this.registeredInput.value
        if (!refName) {
            this.setState({isError: true})
            return
        }
        this.setState({isLoading: true})
        if (this.state.isLoading) return
        Store.registerNameXName({
            refName: refName
        }).then(res => {
            _this.setState({isLoading: false})
            if (!res) return
            _this.initDate()
        })
    }

    copyLink = () => {
        this.showWeakHintMain()
        copy(this.state.invitationLink)
    }
    showWeakHintMain() {
        let _this = this
        if (this.state.isShowWeakHintMain) return
        this.setState({isShowWeakHintMain: true})
        setTimeout(() => {
            _this.setState({isShowWeakHintMain: false})
        }, 3000)
    }
    
    playerBookClaim(){
        let _this = this
        this.setState({isClaimLoading: true})
        Store.playerBookClaim().then(res =>{
            _this.setState({isClaimLoading: false})
            _this.initDate()
        })
    }
    render() {
        return (
            <div className="referral-component">
            {
                this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.t[i18next.language].common["3"]}</div>:''
            }
            <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>
                <div className="referral-bottom-bg">
                    <div className="center-component">
                        <div className="left-component">
                            <div className="invitation-rule-main">
                                {
                                    i18next.language === 'cn'?<img alt="" className="invitation-bg-text" src={invitationBgCn} />:<img alt="" className="invitation-bg-text" src={invitationBgEn} />
                                }
                                
                                {
                                    this.state.isReferral?<div className="referral-info-container">
                                    <div className="referral-info-main">
                                        <img alt="" src={icon1} className="title-icon-1" />
                                        <p className="title">{this.t[i18next.language].Referral['19']}</p>
                                        <p className="invitation-link-main">{this.state.invitationLink}</p>
                                        <p className="copy-btn" onClick={()=>{
                                            this.copyLink()
                                        }}>{this.t[i18next.language].common['1']}</p>
                                    </div>
                                    <div className="referral-info-main">
                                        <img alt="" src={icon3} className="title-icon-2" />
                                        <p className="title">{this.t[i18next.language].Referral['20']}</p>
                                        <p className="tagging">{this.t[i18next.language].Referral['21']}</p>
                                        <p className="amount-main">
                                        {this.state.total}  
                                            <span className="unit">COCOS</span>
                                        </p>
                                        <ul className="withdrawal-btn" onClick={()=>{
                                            this.playerBookClaim()
                                        }}>{this.state.isClaimLoading?<p className="loading-base"></p>:this.t[i18next.language].Referral['22']}</ul>
                                    </div>
                                </div>:''
                                }

                                {
                                    !this.state.isReferral?
                                    <div className="invitation-rule-context">
                                        {/* <ul className="title">
                                            <img alt="" src={horn} className="horn-left-top" />
                                            {this.t.Referral['0']}
                                            <img alt="" src={horn} className="horn-right-bottom" />
                                        </ul> */}
    
                                        <div className="rule-compontents">
                                            <div className="rule-main">
                                                <img alt="" src={icon1} className="rule-main-icon-1" />
                                                <p className="rule-main-content">{this.t[i18next.language].Referral['1']}</p>
                                                <p className="explain-1">{this.t[i18next.language].Referral['2']}</p>
                                            </div>
                                            <div className="rule-main">
                                                <img alt="" src={icon2} className="rule-main-icon-2" />
                                                <p className="rule-main-content">{this.t[i18next.language].Referral['3']}</p>
                                                <p className="explain-2">{this.t[i18next.language].Referral['4']}</p>
                                            </div>
                                            <div className="rule-main">
                                                <img alt="" src={icon3} className="rule-main-icon-3" />
                                                <p className="rule-main-content">{this.t[i18next.language].Referral['5']}</p>
                                                <p className="explain-3">{this.t[i18next.language].Referral['6']}</p>
                                            </div>
                                        </div>
                                    </div>:''
                                }
                            </div>
                            
                            <div className="referral-container">
                                {
                                    !this.state.isReferral?<div className="referral-main">
                                    <p className="title">{this.t[i18next.language].Referral['7']}</p>
                                    <div className="referral-input-container">
                                        <input type="text" className={this.state.isError?"referral-input error-referral-input":"referral-input"} ref={(val) => this.registeredInput = val} placeholder={this.t[i18next.language].common.placeholder["0"]} />
                                        <div className="referral-submit disable" onClick={()=>{
                                            this.submitReferral()
                                        }}>{this.state.isLoading?<p className="loading-base"></p>:this.t[i18next.language].Referral['8']}</div>
                                    </div>

                                    <p className="remarks">{showHtmlText(this.t[i18next.language].Referral['11'], this.state.referralFeeDisplay, this.state.referralFeeDisplay)}</p>
                                    <ul className="remarks1">
                                        <p>{this.t[i18next.language].Referral['12']}</p>
                                        <p>{this.t[i18next.language].Referral['13']}</p>
                                        <p>{this.t[i18next.language].Referral['14']}</p>
                                        <p>{this.t[i18next.language].Referral['15']}</p>
                                        <p>{this.t[i18next.language].Referral['16']}</p>
                                        <p>{this.t[i18next.language].Referral['17']}</p>
                                        <p>{this.t[i18next.language].Referral['18']}</p>
                                    </ul>
                                </div>:''
                                }
                                {
                                    this.state.isReferral?<div>
                                        <div className="account-container">
                                        <p className="title">{this.t[i18next.language].Referral['23']}</p>
                                        <div className="account-info-container">
    
                                            <div className="account-info-main">
                                                <ul className="amount-con amount-1">
                                                    <span>{this.state.earned}</span>
                                                    <span className="unit"> COCOS</span>
                                                </ul>
                                                <ul className="remarks">{this.t[i18next.language].Referral['24']}</ul>
                                            </div>
    
                                            <div className="account-info-main">
                                                <ul className="amount-con amount-1">{this.state.level1}</ul>
                                                <div className="remarks">
                                                    {this.t[i18next.language].Referral['25']}
                                                    <div className="tips">
                                                        <img alt="" src={questionMark} />
                                                        <ul className="small-prompt-window">{this.t[i18next.language].Referral['28']}</ul>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div className="account-info-main">
                                                <ul className="amount-con amount-1">{this.state.level2}</ul>
                                                <div className="remarks">
                                                    {this.t[i18next.language].Referral['26']}
                                                    <div className="tips">
                                                        <img alt="" src={questionMark} />
                                                        <ul className="small-prompt-window">{this.t[i18next.language].Referral['29']}</ul>
                                                    </div>
                                                </div>
                                            </div>
    
                                            {/* <div className="account-info-main">
                                                <ul className="amount-con amount-3"> >999</ul>
                                                <ul className="remarks">{this.t.Referral['27']}</ul>
                                            </div> */}
    
                                        </div>
                                    </div>
    
                                    <div className="my-rebate-record-container">
                                        <div className="my-rebate-record-title">{this.t[i18next.language].Referral['23']}</div>
                                        <table className="rebate-record-table" cellPadding="0" cellSpacing="0" border="0">
                                            <thead>
                                                <tr>
                                                    <td>{this.t[i18next.language].common['0']}</td>
                                                    <td>{this.t[i18next.language].Referral['3']}</td>
                                                    <td>{this.t[i18next.language].Referral['24']}</td>
                                                </tr>
                                            </thead>
                                            {/* <tbody>
                                                <tr>
                                                    <td>2020/12/6</td>
                                                    <td>0XGDDGB…GVCDJFJBDD</td>
                                                    <td> <span className="fccf2584">453887</span>  BCX</td>
                                                </tr>
                                                <tr>
                                                    <td>2020/12/6</td>
                                                    <td>0XGDDGB…GVCDJFJBDD</td>
                                                    <td> <span className="fccf2584">453887</span>  BCX</td>
                                                </tr>
                                                <tr>
                                                    <td>2020/12/6</td>
                                                    <td>0XGDDGB…GVCDJFJBDD</td>
                                                    <td> <span className="fccf2584">453887</span>  BCX</td>
                                                </tr>
                                                <tr>
                                                    <td>2020/12/6</td>
                                                    <td>0XGDDGB…GVCDJFJBDD</td>
                                                    <td> <span className="fccf2584">453887</span>  BCX</td>
                                                </tr>
                                                
                                            </tbody> */}
                                        </table>
                                        <div className="comesoon-main">
                                            <div className="comesoon-icon-main">
                                                <img alt="" src={comesoon} />
                                                <p>{this.t[i18next.language].common['2']}...</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>:''
                                }
                                

                            </div>
                        </div>

                        <div className="right-component">
                            <div className="list-component">

                                <div className="title">{this.t[i18next.language].Referral['9']}
                                    <img alt="" src={pig} className="title-icon-pig" />
                                </div>
                                <ul className="list-header">
                                    <span className="header-text-left">{this.t[i18next.language].common['0']}</span>
                                    <span className="header-text-right">{this.t[i18next.language].Referral['10']}</span>
                                </ul>
                                <div className="list-container">

                                    <div className="comesoon-main">
                                        <div className="comesoon-icon-main">
                                            <img alt="" src={comesoon} />
                                            <p>{this.t[i18next.language].common['2']}...</p>
                                        </div>
                                    </div>
                                    {/* <div className="list-li-main">
                                        <img alt="" src={icon3} className="portrait" />
                                        <ul className="list-li-content">
                                            <p className="list-li-title">0XGB…GVCD</p>
                                            <p className="list-li-addr">0XGDDGB…GVCDJFJBDD</p>
                                        </ul>
                                        <ul className="amount-main">
                                        +31.26 <span className="unit">BCX</span>
                                        </ul>
                                    </div>

                                    <div className="list-li-main">
                                        <img alt="" src={icon3} className="portrait" />
                                        <ul className="list-li-content">
                                            <p className="list-li-title">0XGB…GVCD</p>
                                            <p className="list-li-addr">0XGDDGB…GVCDJFJBDD</p>
                                        </ul>
                                        <ul className="amount-main">
                                        +31.26 <span className="unit">BCX</span>
                                        </ul>
                                    </div> */}

                                </div>
                            </div>


                            <div className="list-component list-component-bottom mart30">
                                <div className="title">{this.t[i18next.language].Referral['30']}
                                    <img alt="" src={trophy} className="title-icon-pig" />
                                </div>
                                <ul className="list-header">
                                    <span className="header-text-left">{this.t[i18next.language].Referral['31']}</span>
                                    <span className="header-text-left">{this.t[i18next.language].Referral['32']}</span>
                                    <span className="header-text-right">{this.t[i18next.language].Referral['33']}</span>
                                </ul>
                                <div className="list-container">

                                    <div className="comesoon-main">
                                        <div className="comesoon-icon-main">
                                            <img alt="" src={comesoon} />
                                            <p>{this.t[i18next.language].common['2']}...</p>
                                        </div>
                                    </div>
                                    {/* <div className="list-li-main">
                                        <img alt="" src={icon3} className="portrait" />
                                        <ul className="list-li-content">
                                            <p className="list-li-title">0XGB…GVCD</p>
                                            <p className="list-li-addr">0XGDDGB…GVCDJFJBDD</p>
                                        </ul>
                                        <ul className="amount-main">
                                        +31.26 <span className="unit">BCX</span>
                                        </ul>
                                    </div>

                                    <div className="list-li-main">
                                        <img alt="" src={icon3} className="portrait" />
                                        <ul className="list-li-content">
                                            <p className="list-li-title">0XGB…GVCD</p>
                                            <p className="list-li-addr">0XGDDGB…GVCDJFJBDD</p>
                                        </ul>
                                        <ul className="amount-main">
                                        +31.26 <span className="unit">BCX</span>
                                        </ul>
                                    </div> */}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}