import React from 'react';
// import { NavLink } from "react-router-dom";
// import { RouterInfoConf } from '../../../Router/router.info.conf'
import cocosLogo from '../../../static/images/logo.png'
import backBtn from '../../../static/images/common/back.svg'
import styled from "styled-components";
import config from '../../../config/config'
import {retrunMyRefName} from '../../../libs/Util'

import WalletLinkConnector from "../../business/WalletLinkConnector/WalletLinkConnector.jsx"
import i18next from 'i18next'
import store from "../../../stores";
import './TopCompontent.css'

// const dispatcher = store.dispatcher
const Store = store.store
// const emitter = store.emitter

const menus = [{
    title: 'LaunchPad',
    url: '/launchpad'
}, {
    title: 'Mystery Box',
    url: '/mysterybox',
}, {
    title: 'Staking',
    routers:  [
      {
        title: 'Dividend',
        url: '/dividends',
      },
      {
        title: 'NFTCast',
        url: '/casting',
      }
    ]
}, {
    title: 'Marketplace',
    url: '/marketplace'
}, {
    title: 'Governance',
    url: 'https://snapshot.org/#/cocoslabs.eth'
  }, {
      title: 'Docs',
      url: 'https://docs.cocosbcx.io/'
  }]

export default class TopCompontent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            menusList: menus
        }
    }
    
    async componentDidMount () {
        let account =  Store.getStore("global",'account');
        if (account) {
            this.setState({address: account})
      
        }
    }


    async routerHome(){
        let { props } = this.props
        if (props) {
            props.history.push('/')
        }
    }

    async goBack () {
        let { props } = this.props
        if (props) {
            props.history.goBack()
        }
    }

    async listenWalletLinkConnector(val){
        let { props } = this.props
        if (val === "loginOut") {
            if (props) {
                props.history.push('/')
            }
        }
        let web3context =  Store.getStore("global",'web3context');
        if (web3context) {
            this.address = web3context.account
            if (this.address) {

                let myRefName = await Store.getReferralName()
                let lastRefname = retrunMyRefName(myRefName)

                if (lastRefname) {
                    let menuslists = menus
                    for (let i = 0; i < menuslists.length; i++) {
                        if (menuslists[i].title === 'Staking') {
                            menuslists[i].routers.push({
                                title: 'Referral',
                                url: '/referral',
                            })
                        }
                    }  
                }
            }
            
            this.setState({address: web3context.account})
            Store.setStore("global",{account: web3context.account})
            let type = await Store.getNetworkType();
            await Store.getWeb3()
            Store.setStore("global",{networkType: type})

            // if (props) {
            //     for (const key in RouterInfoConf) {
            //         let routerInfo = RouterInfoConf[key].path.split('/')[1]
            //         let locationPathname = props.location.pathname.split('/')[1]
            //         if(routerInfo === locationPathname) {
            //             if (!RouterInfoConf[key][type]) {
            //                 props.history.push('/')
            //             } else {
            //                 this.props.listenWallet(false)
            //             }
            //             continue;
            //         }
            //     }
            // } else {
            //     this.props.listenWallet(false)
            // }
            this.props.listenWallet(true)
        } else {
            this.props.listenWallet(true)
        }
        
        
    }

    changeLanguage(){
        let curLang = i18next.language
        if (curLang === 'en') {
            i18next.changeLanguage('cn')
        } else {
            i18next.changeLanguage('en')
        }
        this.props.listenLanguage(i18next.language)
    }
    render() {
        return (
            
        <div className="top-main-compontent">
            <div className="container-main">
                <div className="top-main">
                    <a href='https://www.cocosbcx.io/' className="logo-main">
                        <img alt="" src={cocosLogo} onClick={()=>{
                            this.routerHome()
                        }} />
                    </a>
                    

                    <TabMenuWrapper nums={this.state.menusList.length}>
                        {this.state.menusList.map(menu => <TabMenuItem selected={window.location.pathname === menu.url} key={menu.title} onClick={() => {
                            if (menu.url) {
                            // if (/^https?/.test(menu.url)) {
                            //     window.open(menu.url, menu.replace ? '_self' : '_blank')
                            // } else {
                            //     window.location.href = menu.url
                            // }
                                if (menu.url === '/dividends' || menu.url === '/referral') {
                                    window.location.href = menu.url
                                } else {
                                    if (/^https?/.test(menu.url)) {
                                        window.open(`${menu.url}`, menu.replace ? '_self' : '_blank')
                                    } else {
                                        window.location.href = `${config.Binance.newProUri}${menu.url}`
                                    }
                                    
                                }
                                
                            } else {
                                return
                            }
                            
                        }} routers={menu.routers} title={menu.title}/>)}
                    </TabMenuWrapper>

                    <div className="account-btn">
                        {/* <p className="change-language"
                        //  onClick={()=>{
                        //     this.changeLanguage()
                        // }}
                        >{i18next.language.toUpperCase()}</p> */}
                        <WalletLinkConnector  listenParent={(val)=>{
                            this.listenWalletLinkConnector(val)
                        }}/>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}


const TabMenuWrapper = styled.div`
  height: 46px;
  /* width: ${({nums}) => nums * 145}px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 7px;
  right: 235px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #135DBD;
    left: 0;
    bottom: 7px;
  }
`


export function TabMenuItem(props) {
    return <TabMenuItemWrapper className={props.className} selected={props.selected} onClick={props.onClick}>
        <span>{props.title}</span>
        {
          props.routers && <DropListMenu className="dropList">
            {
              props.routers.map((item, key) => {
                return <DropLi selected={window.location.pathname === item.url} onClick={() => {
                  if (item.url) {
                    if (/^https?/.test(item.url)) {
                        window.open(item.url, item.replace ? '_self' : '_blank')
                    } else {
                        if (item.url === '/dividends' || item.url === '/referral') {
                            window.location.href = item.url
                        } else {
                            if (/^https?/.test(item.url)) {
                                window.open(`${item.url}`, item.replace ? '_self' : '_blank')
                            } else {
                                window.location.href = `${config.Binance.newProUri}${item.url}`
                            }
                        }
                    }
                  }
                  
              }} key={key}>{item.title}</DropLi>
              })
            }
          </DropListMenu>
        }
        
    </TabMenuItemWrapper>
}
const DropListMenu = styled.div`
width: 145px;
background: #1E51BE;
border-radius: 8px;
position: absolute;
top: 46px;
left: 0px;
padding-left: 20px;
padding-right: 20px;
z-index: 300;
`
const DropLi = styled.p`
width: 100%;
height: 52px;
line-height: 52px;
font-size: 16px;
font-weight: 400;
color:  ${({selected}) => selected ? `#FFFFFF` : `#6090F8`};
display: flex;
justify-content: center;
align-items: center;
border-bottom: 1px solid #275FD7;
&:hover {
color: #FFFFFF;
}
`
const TabMenuItemWrapper = styled.div`
  /* width: 145px; */

padding-right: 25px;
  padding-left: 25px;
  height: 46px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background: #00F4E4;
    bottom: 7px;
    left: 0;
    position: absolute;
    visibility: ${({selected}) => selected ? 'visible': 'hidden'};
  }
  .dropList {
    display: none;
  }
  &:hover .dropList {
    display: block;
  } 
  &:hover {
    span {
      color: #00F4E4
    }
    &::after {
      visibility: visible;
    }
  }
  span {
    display: inline-block;
    transition: color linear .3s;
    color: ${({selected}) => selected ? '#00F4E4' : '#3D81D9'};
    line-height: 23px;
  }
`