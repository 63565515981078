export const cn = {
    "walletToken": {
        "Binance": "BNB",
        "Etherscan": "ETH"
    },
    "common": {
        "0": "用户",
        "1": "复制",
        "placeholder": {
            "0": "请输入用户名",
            "1": "填写将要转账的地址",
            "2": "填写售卖价格"
        },
        "timer": {
            "0": "天",
            "1": "时",
            "2": "分",
            "3": "秒"
        },
        "2": "待开启",
        "3": "复制成功",
        "4": "日期",
        "5": "余额",
        "6": "面值",
        "7": "出售",
        "8": "发送",
        "9": "分解",
        "10": "地址",
        "11": "售卖价格",
        "12": "取消",
        "13": "确认",
        "14": "最大",
        "15": "金额",
        "16": "您暂时没有NFT",
        "17": "我的账户",
        "18": "授权",
        "19": "你当前质押时间未满三天，提出会有10%的收益扣除，预计收到 <span style='color: #e02020'>##</span> ##",
        "20": "您没有质押NFT",
        "21": "## 余额不足",
        "22": "铸造费",
        "23": "授权",
        "24": "在我的NFT中查看",
        "25": "首页",
        "26": "尾页",
        "27": "已结束",
        "28": "去挖矿",
        "29": "去铸造",
        "30": "加入",
        "31": "确定",
        "32": "倒计时",
        "33": "分解",
        "34": "质押",
        "35": "创建战队",
        "36": "查看",
        "37": "收获",
        "38": "质 押",
        "39": "提 取",
        "40": "算力",
        "41": "你当前质押时间未满七天，提出会有10%的收益扣除，预计收到 <span style='color: #e02020'>##</span> ##",
        "42": "我的算力",
        "43": "挖矿效率",
        "44": "质押成功",
        "45": "赎回成功"
    },
    "home": {
        "0": "首页",
        "1": "我的NFT",
        "2": "文档"
    },
    "tips": {
        "0": "错误网络",
        "1": "未检测到可连接钱包，",
        "2": "请连接到以太坊网络。",
        "3": "请连接到币安智能链网络。",
        "4": "安装钱包。"
    },
    "Referral": {
        "0": "如何0成本致富",
        "1": "生成一个专属推广链接",
        "2": "购买推广员，生成专属推荐链接",
        "3": "邀请好友",
        "4": "与朋友分享推荐的链接",
        "5": "共同致富",
        "6": "当你的朋友完成交易后你可以获得高达10%的实时佣金",
        "7": "推广员注册",
        "8": "获得10%的返点",
        "9": "推广获益用户展示",
        "10": "邀请获益",
        // "11": "最初的推广员费用0.1ETH，每超过100人注册推广员，注册费用+0.1ETH",
        "11": "最初的推广员费用##，每超过100人注册推广员，注册费用+##",

        "12": "*名称必须唯一",
        "13": "*名称将转换为小写",
        "14": "*不能只是数字",
        "15": "*不能以0x开头",
        "16": "*名称必须至少为1个字符",
        "17": "*最大长度为32个字符",
        "18": "*允许的字符：a-z、0-9",

        "19": "您的专属邀请链接",
        "20": "您的推广收益",
        "21": "现有佣金",
        "22": "立即提现",
        "23": "我的返点账户",
        "24": "我已获收益",
        "25": "1级推广",
        "26": "2级推广",
        "27": "你的排名",
        "28": "7% 返佣收益",
        "29": "3% 返佣收益",
        "30": "收益排名",
        "31": "排名",
        "32": "用户",
        "33": "邀请获益总和",
        "34": "获邀好友",
        "35": "https://cn-docs.cocosbcx.io/"
    },
    "BlindBox": {
        "0": "您已获得",
        "1": "已存入您的卡包",
        "2": "开启盲盒次数为",
        "3": "盲  盒",
        "4": "邮箱订阅",
        "5": "瓜分8000 COCOS"
    },
    "mynft": {
        "0": "信息"
    },
    "nftDetails": {
        "1": "等级",
        "2": "品质",
        "3": "COCOS 数量",
        "4": "创建时间",
        "5": "区块数",
        "6": "作者",
        "7": "名称",
        "8": "分解获得"
    },
    "account": {
        // "0": "在Etherscan中查看",
        "0": "在##中查看",
        "1": "登出"
    },
    "fivefu": {
        "0": "活动说明",
        "1": "集齐五福，拼手气分",
        "2": "开奖倒计时",
        "3": "已集齐五福",
        "4": "人",
        "5": "COCOS感恩有你",
        "6": "开",
        "7": "开奖",

        "8": "当前账户未授权，需要授权之后才能开启红包～",
        "9": "恭喜你获得",
        "10": "将存入您的账户，稍后到账",
        "11": "查看>>",
        "12": "集五福",
        "13": "分奖池",
        "14": "集福活动规则",
        "15": "<span style='font-weight: bold;'>玩法：</span><br/>1.用户可通过开启盲盒的方式获得五福NFT+牛年系列，NFT里铸有 COCOS 且可进行后续矿池的挖矿，或通过集齐五福（和谐福、爱国福、敬业福、友善福、富强福）瓜分奖金池。<br/>2.五福（和谐福、爱国福、敬业福、友善福、富强福）集齐后将获得瓜分奖金池资格，瓜分奖金池后，五福将会被消耗。",
        "16": "活动时间：1月28日—2月7号"
    },
    "nft": {
        "Bull Year Of Great Fortune": "牛年大吉",
        "Bull Year Of Full Wishes": "牛年如意",
        "Bull Year Of New Chances": "牛转新机",
        "Bull Year Of Sharp Motivations ": "牛气冲天",
        "Bull Year Of Great Prosperity": "牛运亨通",
        "Bull Year of Successful Coin": "牛转钱坤",
        "Happy New Year": "新年快乐",
        "Harmony": "和谐福",
        "Patriotism": "爱国福",
        "Friendliness": "友善福",
        "Prosperity": "富强福",
        "Dedication": "敬业福",
        "Collet following Five Fortunes (Harmony, Patriotism, Dedication, Friendliness, Prosperity) and share 8000 COCOS bonus pool prize": "集齐五福（和谐福、爱国福、敬业福、友善福、富强福）后瓜分8000 COCOS奖金池"
    },
    "mining": {
        "common": {
            "1": "NFT挖矿规则",
            "2": "1）每个等级的COCOS NFT 算力不同，算力越高挖矿效率越高，大家根据自己的算力评估收益（见下图）",
            "3": "总算力=COCOS面值*挖矿效率",
            "4": "2）首个矿池总共产出 100000个 COCOS，持续挖矿30天。",
            "5": "2）质押没有上限，质押越多产出COCOS 越多。"
        },
        "0": {
            "name": "COCOS 的新年矿池",
            "describe": "质押你手里的COCOS NFT，赚取更多的COCOS。"
        },
        "1": {
            "name": "藏宝阁",
            "describe": "无尽的宝藏之地。"
        }
        
    },
    "miningDetails": {
        "0": "总算力",
        "1": "总收益",
        "2": "收益/算力（1天）",
        "3": "单价",
        "4": "倒计时",
        "5": "我的质押列表",
        "6": "已产出COCOS",
        "7": "收获",
        "8": "全部提取",
        "9": "COCOS面值",
        "10": "挖矿效率",
        "11": "提 取",
        "12": "提 取",
        "13": "我的NFT",
        "14": "质 押",
        "15": "我的算力"
    },
    "miningRule": {
        "head": ["等级", "面值", "挖矿效率"],
        "body": [
            ["LV1", "x", "1.1x"],
            ["LV2", "x", "1.2x"],
            ["LV3", "x", "1.3x"],
            ["LV4", "x", "1.4x"],
            ["LV5", "x", "1.6x"],
            ["LV6", "x", "1.8x"]
        ]
    },
    "casting": {
        "thead": ["名称", "ID", "面额",  "铸造时间"],
        "0": "没有选择铸造的nft",
        "1": "请填写铸造金额",
        "2": "铸造金额不能超过最大值",
        "3": "铸造金额不能低于最小值",
        "4": "铸造",
        "5": "铸造成功"
    },
    "dividends": {
        "0": "总分红",
        "1": "已分红",
        "2": "战队队长",
        "3": "总算力",
        "4": "分红",
        "5": "分红/算力(1 日)",
        "6": "战队名称",
        "7": "战队创建规则",
        "8": "创建战队",
        "9": "分红时间",
        "10": "战队挖矿效率",
        "11": "今日分红",
        "12": "当前挖矿数量",
        "13": "累计收益",
        "14": "队长收益",
        "15": "待解锁",
        "16": "已收获",
        "17": "我的收益",
        "18": "未领取",
        "19": "累计收益",
        "20": "分红规则",
        "21": "我的算力",
        "22": "我的质押",
        "23": "本期分红",
        "24": "已分红",
        "rule": {
            "0": "持有COCOS和COCOS NFT的用户，可以质押COCOS和NFT参与分红。",
            "1": "分红效率根据用户质押算力计算，算力越高分红占比越高",
            "2": "1COCOS = 1Power",
            "3": "NFT Power = COCOS Par Value * Mining Efficiency",
            "4": "质押时间不够一周，claim的收益10%将扣除进入分红池。",
            "5": "创建战队队长享有战队总收益的20%（持有英雄NFT的用户才可以创建站队）这种NFT的来源：邪心英雄、元素英雄、混沌英雄、暗黑英雄、假面英雄、命运英雄、赤炎英雄、极寒英雄NFT）",
            "6": "英雄NFT只能创建战队分红，不能质押分红。",
            "7": "当队长退出队伍后（提取创建战队质押的英雄NFT）队长收益将不再产生收益。",
            "8": "队长退出队伍后，战队将不再参与下一轮的分红。"
        },
        "teamrRule": {
            "0": "只有持有英雄 NFT 用户才能通过质押NFT，创建战队，成为战队队长。",
            "1": "这种NFT的来源：邪心英雄、元素英雄、混沌英雄、暗黑英雄、假面英雄、命运英雄、赤炎英雄、极寒英雄。"
        }
    }
}