import web3 from 'web3'
import store from "../stores/store"
import { RouterInfoConf } from "../Router/router.info.conf"
const Store = store.store

export const addZero = function (i) {
    return i < 10 ? '0' + i : i + '';
};

export const countDown = function (nowStamp, endStamp) {
    let nowtime = new Date(nowStamp);
    let endtime = new Date(endStamp);
    let lefttime = parseInt(Number(endtime.getTime() - nowtime.getTime()) / 1000, 0);
    let d = parseInt(lefttime / (24 * 60 * 60), 0);
    let h = parseInt((lefttime / (60 * 60)) % 24, 0);
    let m = parseInt((lefttime / 60) % 60, 0);
    let s = parseInt(lefttime % 60, 0);
    d = addZero(d);
    h = addZero(h);
    m = addZero(m);
    s = addZero(s);
    return {
        d,
        h,
        m,
        s
    };
};

export const timeFormat = function (lefttime) {
  let d = parseInt(lefttime / (24 * 60 * 60), 0);
  let h = parseInt((lefttime / (60 * 60)) % 24, 0);
  let m = parseInt((lefttime / 60) % 60, 0);
  let s = parseInt(lefttime % 60, 0);
  d = addZero(d);
  h = addZero(h);
  m = addZero(m);
  s = addZero(s);
  return {
      d,
      h,
      m,
      s
  };
};



export const myRefNameFormat = function (refName) {
    let newRefName = ''
    let refNameArr = refName.split('')
    for (let i = 0; i < refNameArr.length; i++) {
        if (refNameArr[i] !== "null") {
            newRefName += refNameArr[i]
        }
    }
    let name = newRefName
    return name
}


export const balanceFormat = function (balance, digitNum) {
      balance = String(balance)
      let digit = String(digitNum) || 18
      if (String(balance).length <= digit) {
        let supplyZeroNum = digit - String(balance).length
        let decimalPoint = '0.'
        for (let i = 0; i < supplyZeroNum; i++) {
          decimalPoint += '0'
        }
        balance = decimalPoint + balance
      } else {
        let after = balance.slice(String(balance).length-digit, String(balance).length)
        let before = balance.slice(0, String(balance).length-digit)
        balance = before +  '.' + after
      }
      return balance
}

export const balanceFormatAdd = function (balance, digitNum) {
  let result = ''
  balance = String(balance)
  let digit = String(digitNum) || 18
  let pointIndex = String(balance).indexOf('.')
  if (pointIndex > 0) {
    let first = ''
    let balanceArr = balance.split(".")
    if (balanceArr[0] === '0') {
      first = ''
    } else {
      first = balanceArr[0]
    }
    let surplusLength = digit - balanceArr[1].length
    result = first + balanceArr[1]
    for (let i = 0; i < surplusLength; i++) {
      result += "0"
    }
    // result = Number(result)
    result = String(result)
  } else {
    for (let i = 0; i < digit; i++) {
      balance += "0"
    }
    result = balance
  }
  return result
}


export const balanceFormatReverse = function (balance, digit) {
  let result = ''
  let zeroFillingNum = 0
  let balanceStr = String(balance)
  if (balanceStr.indexOf('.') > -1) {
    let bala = String(balanceStr).split('.')
    zeroFillingNum = Number(digit) - bala[1].length
    result = bala[0] + bala[1]
  } else {
    zeroFillingNum = digit
    result += balanceStr
  }
  
  for (let i = 0; i < zeroFillingNum; i++) {
    result += '0'
  }
  return result
}


export const KeepDecimals = function (num, digit) {
  let balance = num
  let indexPoint = String(balance).indexOf('.')
  if(indexPoint>0){
    if (balance.length > indexPoint + digit) {
        balance = balance.substr(0, indexPoint + digit)
    }
  }
  return balance
}



export const dateCommonFormat = function (date, fmt) {
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(),      
      "m+": (date.getMonth() + 1).toString(),     
      "d+": date.getDate().toString(),            
      "H+": date.getHours().toString(),           
      "M+": date.getMinutes().toString(),         
      "S+": date.getSeconds().toString()          
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
  };
  return fmt;
}

export const toPercent = function (point) {
  // var str = Number(point * 100).toFixed(2);
  var str = Number(KeepDecimals(String(point * 100), 3));
  str += "%";
  return str;
}


export const getCurLanguage = function () {
  let curLang = localStorage.getItem("curLang") || 'cn'
  return curLang;
}

export const showHtmlText = function () {
  let htmlTxt = arguments[0]
  let argumentsLen = arguments.length
  for (let i = 1; i < argumentsLen; i++) {
      htmlTxt = htmlTxt.replace("##", arguments[i])
  }
  return htmlTxt
}

export const langChange = function (lang) {
  localStorage.setItem("curLang", lang)
}


export const returnLocalize = function (key, curLang) {
  let localize = Store.getStore('global', 'localize')
  if (localize) {
    if (localize.hasOwnProperty(key)) {
      return localize[key][curLang]
    } else {
      return key
    }
  } else {
    return key
  }
}

export const retrunMyRefName = function (myRefName) {
  let  lastRefname = ''
  let emptyAscii = '0x0000000000000000000000000000000000000000000000000000000000000000'
  if (web3.utils.fromAscii(myRefName) !== emptyAscii && myRefName !== '') {
      let myRefNameAscii = web3.utils.fromAscii(myRefName)
      let myReAsciiStopNo = 0
      for (let i = 0; i < 33; i++) {
          let test = String(myRefNameAscii).slice(i, i+2)
          if (test === '00') {
              myReAsciiStopNo = i
              break;
          }
      }
      
      let myRefNameAsciiLast = ''
      if (myRefNameAscii.length === 66) {
          myRefNameAsciiLast = String(myRefNameAscii).slice(0, myReAsciiStopNo)
      } else {
          myRefNameAsciiLast = myRefNameAscii
      }
      
      lastRefname = web3.utils.toAscii(myRefNameAsciiLast);
  }
  return lastRefname
}


export const hideStr = function(str, startBit, endBit) {
  let startStr = str.slice(0, Number(startBit))
  let strLength = str.length
  let endStr = str.slice(strLength - Number(endBit), strLength)
  return startStr + '...' + endStr
}

export const reRouterPath = function(pathName, networkType) {
  networkType = networkType || "Binance"
  try {
    if (networkType) {
      let isRouter = RouterInfoConf[pathName][networkType]
      if (isRouter) {
        return RouterInfoConf[pathName].path
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (error) {
    console.log(error)
    return false
  }
}


export const KeepSecimals = function (num, digit) {
  let balance = num
  if (balance !== 0) {
    let indexPoint = String(balance).indexOf('.')
    if(indexPoint>0){
      if (balance.length > indexPoint + digit) {
          balance = balance.substr(0, indexPoint + digit)
      }
    }
  }
  return balance
}

export const getArrEqual = function (arr1, arr2) {
  let newArr = [];
  for (let i = 0; i < arr2.length; i++) {
      for (let j = 0; j < arr1.length; j++) {
          if(arr1[j] === arr2[i]){
              newArr.push(arr1[j]);
          }
    }
  }
  return newArr;
}
