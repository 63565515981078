import axios from "axios";
import api from "../config/httpInterFace/httpConfig";
import store from "../stores/store";
import { env } from "../env"

const Store = store.store;


const getRequestUrl = function (path) {
  let networkType = ''
  if (env === 'maintest') {
    networkType = 'maintest';
  } else {
    networkType = Store.getStore('global', 'networkType') || 'Binance'
  }
  let baseUrl = api[networkType].baseUrl
  let url = ''
  if (networkType === "Binance") {
    url = baseUrl + path;
  } else {
    url = baseUrl + "common/" + path;
  }
  
  return url
}


// 
export const getNftV2Details = function (nftid) {
  return new Promise((resolve)=>{
    let url = getRequestUrl('gego-token-v2/' + nftid)
    axios
    .get(url)
    .then(res => {
      if (res.status === 200) {
        let result = res.data.result.data
        resolve(result)
      } else {
        console.log('getNftV2Details...',res)
      }
    })
    .catch((e) => {
      console.log(e);
    })
  })
};


export const getNftTokenDetails = function (url) {
  return new Promise((resolve)=>{
    axios
    .get(url)
    .then(res => {
      if (res.status === 200) {
        let result = res.data
        resolve(result)
      } else {
        console.log('getNftV2Details...',res)
      }
    })
    .catch((e) => {
      console.log(e);
    })
  })
};


export const getLocalizeCsv = function () {
  return new Promise((resolve)=>{
    let url = "https://finance.cocosbcx.io/upload/cocos.json"
    axios
    .get(url)
    .then(res => {
      let data = res.data
      let dic = {}
      for (let i = 0; i < data.length; i++) {
        dic[data[i].id] = data[i]
      }
      Store.setStore('global', {
        localize: dic
      })
      resolve(dic)
    })
    .catch((e) => {
      console.log(e);
    })
  })
};



export const getKCSPrice = function (token) {
  return new Promise((resolve)=>{
  token = token || 'KCS'
  let url = `https://finance.cocosbcx.io/bsccommon/getPrice/${token}|USDT`
  // let url = getRequestUrl(pathUrl)
  // let url = "http://192.168.50.79:3002/getSalesList"
  // status 0 buying  1 buyed  2 cencel
    axios
    .get(url)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data)
      } else {
        console.log('getKCSPrice...',res)
      }
    })
    .catch((e) => {
      console.log(e);
    })
  })
};

// /cocos/nft/book/list
export const getCocosBookList = function () {
  return new Promise((resolve) => {
    let url = getRequestUrl("cocos/nft/book/list");
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          console.log("getCocosBookList...", res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });
};


// ================================================
// getMyAuctionHistory
export const getMintLogV2 = function (account) {
  return new Promise((resolve)=>{
    let url = getRequestUrl(`cocos/nft/mint/log/${account}`)
    axios
    .get(url)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data)
      } else {
        console.log('getAuctionList...',res)
      }
    })
    .catch((e) => {
      console.log("e...", e)
    })
  })
};

// getGegoBookList
export const getGegoBookList = function () {
  return new Promise((resolve) => {
    let url = getRequestUrl("getGegoBookList-v2");
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          console.log("getGegoBookList...", res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });
};