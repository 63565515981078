import React from 'react';
import './PagingComponent.css'
import { lang } from "../../../i18n/lang/lang"
import i18next from 'i18next'
// import store from '../../../stores/store'
// const Store = store.store
// const emitter = store.emitter

export default class PagingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagenum: 0
        }
        this.t = lang
    }

    componentDidMount(){
        // let curpage = this.props.curpage
        let pagasize = this.props.pagasize
        let count = this.props.countnum
        let pagenum = Math.ceil(count/pagasize)
        this.setState({pagenum: pagenum})
    }

    showDrop(e){
        e.stopPropagation()
        this.setState({isDrop: true})
    }

    pageTurning(val) {
        this.props.pageTurning(val)
    }

    nextPage(){
        let curpage = Number(this.props.curpage)
        let count = this.props.countnum
        let pagasize = this.props.pagasize
        let pagenum = Math.ceil(count/pagasize)
        curpage++
        if(curpage >pagenum) return false
        this.props.pageTurning(curpage)
    }

    previousPage(){
        let curpage = Number(this.props.curpage)
        curpage--
        if(curpage <1) return false
        this.props.pageTurning(curpage)
    }

    hideDrop(){
        this.setState({isDrop: false})
    }
    render() {
        return (
            <div className="paging-bar">
                <ul className="paging-min" onClick={()=>{
                    this.pageTurning(1)
                }}>{this.t[i18next.language].common['25']}</ul>
                <ul className="previous-page" onClick={()=>{
                    this.previousPage()
                }}>
                    <p className="arrow-left"></p>
                </ul>
                <ul className="cur-page"> {this.props.curpage}/{this.state.pagenum} </ul>
                <ul className="next-page" onClick={()=>{
                    this.nextPage()
                }}>
                    <p className="arrow-right"></p>
                </ul>
                <ul className="paging-max" onClick={()=>{
                    this.pageTurning(this.state.pagenum)
                }}>{this.t[i18next.language].common['26']}</ul>
            </div>
        )
    }
}