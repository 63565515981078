import React from 'react';
// import { NavLink } from "react-router-dom";
import './FiveFu.css'
import { KeepDecimals, balanceFormat, timeFormat, dateCommonFormat } from "../../libs/Util"
import logoMiddle from '../../static/images/common/logo-middle.png'
import lantern from '../../static/images/fivefu/lantern.png'
import config from '../../config/config'
// import dedicatedNot from '../../static/images/fivefu/fivefu/dedicated-not.png'
// import patriotismNot from '../../static/images/fivefu/fivefu/patriotism-not.png'
// import richNot from '../../static/images/fivefu/fivefu/rich-not.png'
// import harmoniousNot from '../../static/images/fivefu/fivefu/harmonious-not.png'
// import friendlyNot from '../../static/images/fivefu/fivefu/friendly-not.png'
import TopCompontent from '../base/TopCompontent/TopCompontent'
import { lang } from "../../i18n/lang/lang"
import { fivefu } from "../../config/business.conf"
import i18next from 'i18next'
import closeBtn from '../../static/images/fivefu/close-tip.svg'
// import { getNftTokenDetails } from "../../libs/Api"

import store from "../../stores";
const Store = store.store

export default class FiveFu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isApproveLoading: false,
            myFivefuList: {},
            mergeFiveBlessingNum: 0,
            isStart: false,
            countDownText: {
                d: '00',
                h: '00',
                m: '00',
                s: '00'
            },
            fivefuAllTotal: 0,
            isApprove: false,
            isMaskApprove: false,
            isMaskMerge: false,
            isMaskRule: false,

            curYear: "2020",
            fiveBlessingReward: "",
            drawPrizeTime: "",
            getCOCOSNum: 0,

            promptText: ""
        }
        this.nftVer = 'v1'
        this.config = config
        this.t = lang
        this.account = Store.getStore('global','account')
        this.timer = null
        this.nftType = 'v1'
        this.factoryType = 'blindbox'
    }

    initDate(){
        let networkType = Store.getStore('global', 'networkType')
        if (!networkType) return
        this.config = config[networkType]
        this.fiveBlessingReward()
        this.getApprovedForAll()
        this.startTime()
        this.fiveBlessingTotals()
        this.myNFT()
    }

    async startTime() {
        let _this = this
        let result = await Store.fiveBlessingEndTime()
        let resultDisplay = result + '000'
        let drawPrizeTime = dateCommonFormat(new Date(Number(resultDisplay)), "mm-dd HH:MM:SS")
        this.setState({drawPrizeTime: drawPrizeTime})
        async function countDownfn() {
            let curTimestamp = (new Date()).getTime()
            curTimestamp = String(curTimestamp).slice(0, 10)
            let countDownSec = result - curTimestamp
            if (countDownSec > 0) {
                let countDown = timeFormat(countDownSec)
                _this.setState({countDownText: countDown})
                _this.timer = setTimeout(()=>{
                    countDownfn()
                }, 1000)
            } else {
                clearTimeout(_this.timer)
                _this.setState({
                    isStart: true,
                    countDownText: {
                        d: '00',
                        h: '00',
                        m: '00',
                        s: '00'
                    }
                })
            }
            
        }
        countDownfn()
    }

    getApprovedForAll(){
        let _this = this
        Store.getApprovedForAll({
            nftType: this.nftType, 
            apprAddrKey: this.factoryType
        }).then(res => {
            _this.setState({isApprove: res})
        })
    }


    setApprovedForAll(){
        let _this = this
        if (this.state.isApproveLoading) return
        this.setState({isApproveLoading: true})
        Store.setApprovedForAll({
            nftType: this.nftType, 
            apprAddrKey: this.factoryType
        }).then(res => {
            _this.setState({
                isMaskApprove: false,
                isApproveLoading: false
            })
            _this.getApprovedForAll(res)
        })
    }
    fiveBlessingTotals(){
        Store.fiveBlessingTotals(5).then(res => {
            this.setState({fivefuAllTotal: res})
        })
    }
    myNFT() {
        let _this = this
        let myNftList = []
        let myFivefuList = {}
        this.setState({loading: true})
        for (let i = 0; i < fivefu.ruleId.length; i++) {
            myFivefuList[fivefu.ruleId[i]] = []
        }
        Store.getTokensOfOwner(_this.nftVer).then(async res=> {
            if (!res) return
            let ids = res
            ids.forEach(element => {
                myNftList.push({nftId: element})
            });

            for (let i = 0; i < myNftList.length; i++) {
                let id = myNftList[i].nftId
                let nftDetails = await Store.getFivefuNFT(id)
                if (fivefu.ruleId.indexOf(nftDetails.ruleId) === -1) {
                    continue;
                }
                myNftList[i] = Object.assign(nftDetails, myNftList[i])
            }
            for (let i = 0; i < myNftList.length; i++) {
                if (myNftList[i].ruleId) {
                    let ruleId = myNftList[i].ruleId
                    myFivefuList[ruleId].push(myNftList[i])
                }
            }
            
            let mergeFiveBlessingNum = myFivefuList[fivefu.ruleId[0]].length
            for (const key in myFivefuList) {
                if (myFivefuList[key].length < mergeFiveBlessingNum) {
                    mergeFiveBlessingNum = myFivefuList[key].length
                }    
            }
            this.setState({
                loading: false,
                myFivefuList: myFivefuList,
                mergeFiveBlessingNum: mergeFiveBlessingNum
            })
        })
    }
    componentDidMount () {

        // let curYear = (new Date()).getFullYear()
        if (this.account === '') return
        this.initDate()
    }
    
    async listenWallet(){
        this.initDate()
    }
    fiveBlessingReward() {
        let _this = this
        Store.fiveBlessingReward().then(res => {
            let decimals = _this.config[_this.config.defaultWalletToken].decimals
            let fiveBlessingReward = Number(KeepDecimals(balanceFormat(res, decimals), 3))
            _this.setState({fiveBlessingReward: fiveBlessingReward})
        })
    }
    async mergeFiveBlessing(){
        let _this = this 
        if (!this.state.isApprove) {
            this.setState({isMaskApprove: true})
            return
        }
        if (!this.state.isStart) {
            this.setState({promptText: _this.t[i18next.language].common["2"]})
            this.showWeakHintMain()
            return
        }
        if (Number(this.state.mergeFiveBlessingNum)<1) return
        this.setState({loading: true})
        let cocosNFTIds = []
        for (let i = 0; i < fivefu.ruleId.length; i++) {
            cocosNFTIds.push(Number(this.state.myFivefuList[fivefu.ruleId[i]][0].nftId))
        }
        let result = await Store.mergeFiveBlessing(cocosNFTIds)
        let fiveBlessingReward = this.state.fiveBlessingReward
        let fivefuAllTotal = this.state.fivefuAllTotal
        let getCOCOSNum = KeepDecimals(String(fiveBlessingReward/fivefuAllTotal), 3)
        this.setState({
            loading: false,
            getCOCOSNum: getCOCOSNum
        })
        if (result) {
            this.setState({isMaskMerge: true})
            this.initDate()
        }
    }

    showWeakHintMain() {
        let _this = this
        if (this.state.isShowWeakHintMain) return
        this.setState({isShowWeakHintMain: true})
        setTimeout(() => {
            _this.setState({isShowWeakHintMain: false})
        }, 3000)
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }
    closeApproveMask(val){
        this.setState({isMaskApprove: val})
    }

    closeMergeMask(val){
        this.setState({isMaskMerge: val})
    }

    operationRuleMask(val){
        this.setState({isMaskRule: val})
    }

    openBox(){
        this.setState({isMask: true})
    }

    closeIsMask(){
        this.setState({isMask: false})
    }

    routerJump(routerName){
        this.props.history.push(`/${routerName}`)
    }

    componentWillUnmount () {
        let _this = this
        clearTimeout(_this.timer)
    }

    render() {
        return (
            <div className="FiveFu-components">
            <div id='stars'></div>
            {/* <div id='stars2'></div> */}
            {/* <div id='stars3'></div> */}
                {
                    this.state.isMaskApprove?<div className="mask">
                    <div className="approve-container">
                        <p className="desc">{this.t[i18next.language].fivefu["8"]}～</p>
                        <ul className="approve-btn" onClick={()=>{
                            this.setApprovedForAll()
                        }}>
                            {
                                this.state.isApproveLoading?<p className="loading"></p>:this.t[i18next.language].common["18"]
                            }
                            
                        </ul>
                        <img alt="" className="close-approve-btn" src={closeBtn} onClick={()=>{
                            this.closeApproveMask(false)
                        }} />
                    </div>
                </div>:''
                }
                {
                    this.state.isMaskMerge?<div className="mask">
                    <div className="merge-container">
                        <div className="merge-main">
                            <img alt="" src={logoMiddle} className="logoMiddle-icon" />
                            <p className="title1">COCOS</p>
                            <p className="balance-bar">{this.state.getCOCOSNum} COCOS</p>
                            <p className="title4">{this.t[i18next.language].fivefu["10"]}</p>
                            <p className="nft-router" onClick={()=>{
                                this.routerJump('mynft')
                            }}>{this.t[i18next.language].fivefu["11"]}</p>
                        </div>
                        <img alt="" className="close-approve-btn" src={closeBtn} onClick={()=>{
                            this.closeMergeMask(false)
                        }} />
                    </div>
                </div>:''
                }
                {
                    this.state.isMaskRule?<div className="mask">
                    <div className={i18next.language === "cn"?"rule-container":"rule-container rule-container-en"}>
                        <p className="title">{this.t[i18next.language].fivefu["14"]}</p>
                        <div className="rule-main">
                            <p className="rule-contant" dangerouslySetInnerHTML={{__html: this.t[i18next.language].fivefu["15"]}}></p>
                            <p className="rule-date">{this.t[i18next.language].fivefu["16"]}</p>
                        </div>
                        <img alt="" className="close-rule-btn" src={closeBtn} onClick={()=>{
                            this.operationRuleMask(false)
                        }} />
                    </div>
                </div>:''
                }
                
                {
                    this.state.loading?<div className="full-mask">
                    <div className="loading-big"></div>
                </div>:''
                }
                
                {
                    this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.state.promptText}</div>:''
                }
                
                <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>
                
                <div className="container-main">
                    <div className="top-container">
                        <img alt="" src={lantern} className="lantern-icon" />
                        {
                            i18next.language === 'cn'?<ul className="activity-desc-icon" onClick={()=>{
                                this.operationRuleMask(true)
                            }}>
                                <p className="text">{this.t[i18next.language].fivefu["0"]}</p>
                            </ul>:<ul className="activity-desc-en-icon" onClick={()=>{
                            this.operationRuleMask(true)
                        }}>
                            <p className="text">{this.t[i18next.language].fivefu["0"]}</p>
                        </ul>
                        }
                        <p className="title1">{this.t[i18next.language].fivefu["1"]}</p>
                        <p className="balance-main">{this.state.fiveBlessingReward} COCOS</p>
                        <div className="count-down-container">
                            <p className="count-down-tit">{this.t[i18next.language].fivefu["2"]}</p>
                            <p className="count-down-num">{this.state.countDownText?this.state.countDownText.d:'00'}</p>
                            <p className="count-down-symbol">:</p>
                            <p className="count-down-num">{this.state.countDownText?this.state.countDownText.h:'00'}</p>
                            <p className="count-down-symbol">:</p>
                            <p className="count-down-num">{this.state.countDownText?this.state.countDownText.m:'00'}</p>
                            <p className="count-down-symbol">:</p>
                            <p className="count-down-num">{this.state.countDownText?this.state.countDownText.s: '00'}</p>
                        </div>
                        <div className="title2">
                            <span>{this.state.fivefuAllTotal}{this.t[i18next.language].fivefu["4"]}</span>
                            <span className="title2-con">{this.t[i18next.language].fivefu["3"]}</span>
                        </div>
                    </div>

                    <div className="fivefu-container">
                        {
                            fivefu.ruleId.map((item, key) => {
                                return key !== 4?this.state.myFivefuList[item]?<div key={key} className="fivefu-li-main">
                                {
                                this.state.myFivefuList[item].length > 0?<p className="num-con">× {this.state.myFivefuList[item].length}</p>:null
                                }
                                {
                                    this.state.myFivefuList[item].length === 0?<img alt="" src={fivefu.imgOwnNo[key]} />:<img alt="" src={fivefu.imgOwn[key]} />
                                }
                            </div>:<div key={key} className="fivefu-li-main"><img alt="" src={fivefu.imgOwnNo[key]} /></div>:""
                            })
                        }
                        <div className="fivefu-li-main draw-prize">
                            <p className="num-con">× {this.state.mergeFiveBlessingNum}</p>
                            <div className="draw-prize">
                                <p className="title">2021</p>
                                {/* <p className="title1">{this.t[i18next.language].fivefu["5"]}</p> */}
                                <ul className={this.state.isStart && Number(this.state.mergeFiveBlessingNum)>0?i18next.language === "cn"?"open-btn":"open-btn open-btn-en":i18next.language === "cn"?"open-btn disable":"open-btn open-btn-en disable"} onClick={()=>{
                                    this.mergeFiveBlessing()
                                }}>
                                    {this.t[i18next.language].fivefu["6"]}
                                </ul>
                                <p className="title2">{this.state.drawPrizeTime} {this.t[i18next.language].fivefu["7"]}</p>
                            </div>
                        </div>
                        {
                            this.state.myFivefuList[fivefu.ruleId[4]]?<div className="fivefu-li-main">
                            {
                            this.state.myFivefuList[fivefu.ruleId[4]].length > 0?<p className="num-con">× {this.state.myFivefuList[fivefu.ruleId[4]].length}</p>:null
                            }
                            {
                                this.state.myFivefuList[fivefu.ruleId[4]].length === 0?<img alt="" src={fivefu.imgOwnNo[4]} />:<img alt="" src={fivefu.imgOwn[4]} />
                            }
                        </div>:<div className="fivefu-li-main"><img alt="" src={fivefu.imgOwnNo[4]} /></div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}