
import kovan from "./kovan.config"
import main from "./mainnet.config"
import maintest from "./mainnet.test.config"
import Binance from "./Binance.config"
import bnb from "./bnb.config"
import { env } from "../env"

let envConfig = null

if (env === 'maintest') {
  
  envConfig = {
    kovan: kovan,
    main: maintest,
    bnb: bnb,
    Binance: Binance
  };

} else {

  envConfig = {
    kovan,
    main,
    bnb,
    Binance
  };
}

const config = envConfig
export default config;

