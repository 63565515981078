
import KeyBlade from "../static/images/casting/KeyBlade.png"
import KeyBladeSelect from "../static/images/casting/KeyBlade_select.png"
import questionMark from "../static/images/casting/questionMark.png"
import questionMarkSelect from "../static/images/casting/questionMark_select.png"
import sword from "../static/images/casting/sword.png"
import swordSelect from "../static/images/casting/sword_select.png"
import staff from "../static/images/casting/staff.png"
import staffSelect from "../static/images/casting/staff_select.png"
import sickle from "../static/images/casting/sickle.png"
import sickleSelect from "../static/images/casting/sickle_select.png"
import trident from "../static/images/casting/trident.png"
import tridentSelect from "../static/images/casting/trident_select.png"


export const castingTokenInfo = {
  "cocos": {
    "includeResBaseId": ["7", "16", "17", "18", "19"]
  }
}


export const castingTLevelInfo = {
  "cocos":{
    "2": {
      "color": "4A9AA5",
      "min": "1",
      "isIncludeMin": true,
      "max": "1000",
      "isIncludeMax": true
    },
  }
}

export const castingToken = [
  {
    "id": "cocos",
    "tokenId": "cocos",
    "name": "COCOS"
  }
]
// casting
export const castingNFT = {
    "0": {
      "resBaseIdDisplay": "7",
      "icon": KeyBlade,
      "iconSelect": KeyBladeSelect,
      "iconDetails": KeyBlade,
      "castingToken": ["cocos"],
      "includeResBaseId": ["7"],
      "miningId": ["miningdetails", "1"],
      "isLP": false
    },
    "1": {
      "resBaseIdDisplay": "16",
      "icon": sword,
      "iconSelect": swordSelect,
      "iconDetails": sword,
      "castingToken": ["cocos"],
      "includeResBaseId": ["16"],
      "miningId": ["dividends", "1"],
      "isLP": false
    },
    "2": {
      "resBaseIdDisplay": "17",
      "icon": sickle,
      "iconSelect": sickleSelect,
      "iconDetails": sickle,
      "castingToken": ["cocos"],
      "includeResBaseId": ["17"],
      "miningId": ["dividends", "1"],
      "isLP": false
    },
    "3": {
      "resBaseIdDisplay": "18",
      "icon": staff,
      "iconSelect": staffSelect,
      "iconDetails": staff,
      "castingToken": ["cocos"],
      "includeResBaseId": ["18"],
      "miningId": ["dividends", "1"],
      "isLP": false
    },
    "4": {
      "resBaseIdDisplay": "19",
      "icon": trident,
      "iconSelect": tridentSelect,
      "iconDetails": trident,
      "castingToken": ["cocos"],
      "includeResBaseId": ["19"],
      "miningId": ["dividends", "1"],
      "isLP": false
    },
    "5": {
      "name": 5,
      "resBaseIdDisplay": "",
      "icon": questionMark,
      "iconSelect": questionMarkSelect
    }
  }
  
export const castingNFTShow = []

export const castingHref = {
  "cocos": "https://www.binance.com/en/trade/COCOS_usdt",
  "course": {
    "cn": "https://cn-docs.cocosbcx.io/mo-kuai/nft-zhu-zao",
    "en": "https://en-docs.cocosbcx.io/mo-kuai/nft-cast"
  }
}