
import Home from '../components/home/home';
import Referral from '../components/referral/referral'
import BlindBox from '../components/BlindBox/BlindBox'
import MyNft from '../components/MyNft/MyNft'
import MyNftDetails from '../components/MyNftDetails/MyNftDetails'
import FiveFu from '../components/FiveFu/FiveFu'
import Mining from '../components/Mining/Mining'
import MiningDetails from '../components/MiningDetails/MiningDetails'
import Casting from '../components/Casting/Casting'
import Dividends from '../components/Dividends/Dividends'
import DividendsDetails from '../components/DividendsDetails/DividendsDetails'
export const RouterConf = [
    {
        "name": "Home",
        "path": "/",
        "component": Home
    },
    {
        "name": "Referral",
        "path": "/referral",
        "component": Referral
    },
    // {
    //     "name": "BlindBox",
    //     "path": "/blindbox",
    //     "component": BlindBox
    // },
    // {
    //     "name": "MyNft",
    //     "path": "/mynft",
    //     "component": MyNft
    // },
    // {
    //     "name": "MyNftDetails",
    //     "path": "/mynftdetails/:nftId",
    //     "component": MyNftDetails
    // },
    // {
    //     "name": "FiveFu",
    //     "path": "/fivefu",
    //     "component": FiveFu
    // },
    {
        "name": "Mining",
        "path": "/mining",
        "component": Mining
    },
    {
        "name": "MiningDetails",
        "path": "/miningdetails/:poolId",
        "component": MiningDetails
    },
    // {
    //     "name": "Casting",
    //     "path": "/casting",
    //     "component": Casting
    // },
    {
        "name": "Dividends",
        "path": "/dividends",
        "component": Dividends
    },
    {
        "name": "DividendsDetails",
        "path": "/dividendsdetails/:teamId",
        "component": DividendsDetails
    }
]
