import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

// import test from '../components/test/test';
// import testcopy from  '../components/testcopy/test'
// import test from '../components/test/test';
import { RouterConf } from './router.conf'

export default class Homepage extends Component {
    render() {
        return (
            <div className="router">
                    <Router >
                        <Switch >
                            {
                                RouterConf.map((item, key) => {
                                    return <Route key={key} path={item.path} exact component={item.component} />
                                })
                            }
                            {/* <Route path="/" exact component={Home} />
                            <Route path={routerConf.referral.path} exact component={routerConf.referral.component} />
                            <Route path="/blindbox" exact component={BlindBox} />
                            <Route path="/mynft" exact component={MyNft} />
                            <Route path="/mynftdetails/:nftId" exact component={MyNftDetails} />
                            <Route path="/fivefu" exact component={FiveFu} /> */}
                           <Route path='/' render={() => (
                                <Redirect to='/' />
                            )} />
                        </Switch>
                    </Router>
            </div>
        );
    }
}