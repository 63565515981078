import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { getLocalizeCsv } from "./libs/Api"
import './index.css';

getLocalizeCsv().then(()=>{
  console.log("update 2021-04-07 10:40")
  console.log("update 2023-01-31 13:17")
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
})

