import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import config from "../../../config/config"
import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';
import { RouterInfoConf } from "../../../Router/router.info.conf"
import logo from '../../../static/images/common/logo.png'
import nologin from '../../../static/images/common/nologin.svg'
import loggedInPortrait from '../../../static/images/common/avatar.png'
import close from '../../../static/images/common/close.png'
import { balanceFormat, KeepDecimals, showHtmlText } from '../../../libs/Util'
import { BinanceChainID } from '../../../config/tokenConfig'
import { hideStr } from '../../../libs/Util'
import { lang } from '../../../i18n/lang/lang'
import i18next from 'i18next'
import './WalletLinkConnector.css'

import {
  Web3ReactProvider,
  useWeb3React,
} from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import {
  ERROR,
  CONNECTION_DISCONNECTED,
  CONNECTION_CONNECTED
} from '../../../constants'

import Store from "../../../stores";

const emitter = Store.emitter
const store = Store.store

const styles = theme => ({
  root: {
    flex: 1,
    height: 'auto',
    display: 'flex',
    position: 'relative'
  },
  contentContainer: {
    margin: 'auto',
    textAlign: 'center',
    padding: '12px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  cardContainer: {
    marginTop: '60px',
    minHeight: '260px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  unlockCard: {
    padding: '24px'
  },
  buttonText: {
    marginLeft: '12px',
    fontWeight: '700',
  },
  instruction: {
    maxWidth: '400px',
    marginBottom: '32px',
    marginTop: '32px'
  },
  actionButton: {
    padding: '12px',
    backgroundColor: 'white',
    borderRadius: '3rem',
    border: '1px solid #E1E1E1',
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      padding: '15px',
    }
  },
  connect: {
    width: '100%'
  },
  closeIcon: {
    position: 'fixed',
    right: '12px',
    top: '12px',
    cursor: 'pointer'
  }
});

class WalletLinkConnector extends Component {

  constructor(props) {
    super(props)

    this.state = {
      error: null,
      metamaskLoading: false,
      ledgerLoading: false,
      isMask: false,
      account: '',
      isWallet: false,
      isMaskWalletTip: false,
      isShowWeakHintMain: false,
      tipContent: "",
      isErrorNetwork: false,

      cocosBalance: '--.--'
    }
    this.t = lang
    this.cocosDecimals = config.main.cocos.decimals
    this.networkType = store.getStore('global', 'networkType')
  }

  fnDown(e) {
      this.disX = e.clientX - this.refs.WalletLinkConnector.offsetLeft - (this.refs.WalletLinkConnector.offsetWidth / 2);
      this.disY = e.clientY - this.refs.WalletLinkConnector.offsetTop;
      document.onmousemove = this.fnMove.bind(this)
  }
  fnMove(e) {
      this.setState({
          needX: e.clientX - this.disX,
          needY: e.clientY - this.disY
      })
  }
  fnUp() {
      document.onmousemove = null
  }
  componentWillMount() {
    let _this = this
    let account = store.getStore('global', 'account')
    if (account) {
      this.setState({
        account: account
      })
    }
    if (window.ethereum || window.Web3) {
      this.setState({
        isWallet: true
      })
      emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
      emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
      emitter.on(ERROR, this.error);
      if (!window.ethereum) return
      window.ethereum.on('networkChanged', function (chainId) {
        let networkType = BinanceChainID[chainId]
        for (const key in RouterInfoConf) {
          let routerInfo = RouterInfoConf[key].path.split('/')[1]
          let locationPathname = _this.props.location.pathname.split('/')[1]
          if (routerInfo) {
            if(routerInfo === locationPathname) {
                if (!RouterInfoConf[key][networkType]) {
                  _this.setState({tipContent: _this.t[i18next.language].tips[RouterInfoConf[key].tipId]})
                  _this.showWeakHintMain(() => {
                    _this.loginOut()
                  })
                  return
                }
            }
          } else {
            if (!RouterInfoConf.Home[networkType]) {
              _this.setState({tipContent: _this.t[i18next.language].tips[RouterInfoConf.Home.tipId]})
              
              _this.showWeakHintMain(() => {
                _this.loginOut()
              })
              return
            }
          }
          
        }
      })
      window.ethereum.on('accountsChanged', function (accounts) {
        let account = accounts[0]
        let context = store.getStore('global', 'web3context')
        if (!context) return
        context.account = account
        store.setStore('global' ,{account: account })
        store.setStore('global' ,{web3context: context })
        // _this.props.history.push('/')
        // _this.props.listenParent(true)
      })
    }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.removeListener(ERROR, this.error);
  };

  error = (err) => {
    this.setState({ loading: false, error: err, metamaskLoading: false, ledgerLoading: false })
  };

  connectionConnected = async () => {
    let _this = this
    let isError = false
    let web3context = store.getStore('global' ,'web3context')
    let networkType = await store.getCurNetworkType()
    let account = web3context.account
    for (const key in RouterInfoConf) {
      let routerInfo = RouterInfoConf[key].path.split('/')[1]
      let locationPathname = this.props.location.pathname.split('/')[1]
      if (routerInfo) {
        if(routerInfo === locationPathname) {
            if (!RouterInfoConf[key][networkType]) {
              _this.setState({tipContent: _this.t[i18next.language].tips[RouterInfoConf[key].tipId]})
              _this.clearAccount()
              isError = true
              // _this.showWeakHintMain(() => {
              //   // _this.props.history.push('/')
              //   // _this.loginOut()
              // })
              return
            }
        }
      } else {
        if (!RouterInfoConf.Home[networkType]) {
          _this.setState({tipContent: _this.t[i18next.language].tips[RouterInfoConf.Home.tipId]})
          _this.clearAccount()
          isError = true
          // _this.showWeakHintMain(() => {
          //   // _this.props.history.push('/')
          //   // _this.loginOut()
          // })
          return
        }
      }
    }

    if (!isError) {
      this.setState({account: account})
    }
    
    this.props.listenParent({isShow: false})
  }

  connectionDisconnected = () => {
    let web3context = store.getStore('global' ,'web3context')
    let account = web3context.account
    this.setState({
      account: account
    })
    this.props.listenParent({isShow: false})
  }

  cancelLedger = () => {
    this.setState({ ledgerLoading: false })
  }

  cancelMetamask = () => {
    this.setState({ metamaskLoading: false })
  }

  clearAccount() {
    store.setStore('global' ,{account: '' })
    this.setState({
      account: ''
    })
    // let web3context = store.getStore('global' ,'web3context')
    // if (web3context) {
    //   web3context.account = ''
    // }
    store.setStore('global' ,{
      web3context: null,
      autoConnect: false
    })
    // store.setStore('global', {})
    this.setState({
      account: null
    })
  }

  loginOut = () => {
    let _this = this
    store.setStore('global' ,{account: '' })
    this.setState({account: ''})
    // let web3context = store.getStore('global' ,'web3context')
    // if (web3context) {
    //   web3context.account = ''
    // }
    store.setStore('global' ,{
      web3context: null,
      autoConnect: false
    })
    // store.setStore('global', {})
    this.setState({
      account: null
    }, ()=>{
      _this.closeAccountMask()
      _this.props.listenParent("loginOut")
    })
    
  }

  showAccountMask(){

    store.getCommonBalance('cocos').then(res => {
      if (res) {
        let balance = res
        balance = KeepDecimals(balanceFormat(balance, this.cocosDecimals), 3)
        this.setState({cocosBalance: balance})
      }
    })
    this.setState({isMask: true})
  }

  showWeakHintMain(callback) {
      let _this = this
      if (this.state.isShowWeakHintMain) return
      this.setState({isShowWeakHintMain: true})
      setTimeout(() => {
          _this.setState({isShowWeakHintMain: false})
          if (callback) callback()
      }, 3000)
  }
  closeAccountMask(){
    this.setState({isMask: false})
  }
  closeWalletTipMask() {
    this.setState({isMaskWalletTip: false})
  }
  openWalletTipMask(){
    this.setState({isMaskWalletTip: true})
  }

  listenChild(val) {
    // console.log('listenChild', val)
  }


  render() {
    //const { classes, closeModal } = this.props;

    return (
      <div className="account-container">

        {
            this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.state.tipContent}</div>:''
        }
        {
          this.state.isMaskWalletTip?<div className="mask-wallet">
          <div className="wallet-tip-container">
            <img alt="" src={close} className="close-btn" onClick={()=>{
                this.closeWalletTipMask()
            }}/>
            <p className="content mart73">
            {this.t[i18next.language].tips["1"]}<a target="_blank" rel="noopener noreferrer" href="https://metamask.io/">{this.t[i18next.language].tips["4"]}</a>
            </p>
          </div>
      </div>:""
        }
        
        {
          this.state.isWallet?
          // this.state.account?
          // <img alt="" src={loggedInPortrait} className="loginIn" onClick={()=>{
          //   this.showAccountMask()
          // }} />:
          <Web3ReactProvider getLibrary={getLibrary}>
          <MyComponent loginOut={this.Parent} listenParent={this.listenChild} />
        </Web3ReactProvider>:<div className="WalletLink-main">
              <img alt="" src={nologin} className="nologin-icon" />
              <ul className="item-text" onClick={()=>{
                this.openWalletTipMask()
              }}>Connect Wallet</ul>
            </div>
        }
        {
          
        }
            
            
      </div>
        
    )
  };
}

function getLibrary(provider) {

  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

function onConnectionClicked(currentConnector, name, setActivatingConnector, activate, setisMaskWallet) {
  localStorage.setItem("linkedWalletName", name)
  store.getNetworkType().then(res => {
    store.setStore('global', {networkType: res})
    const connectorsByName = store.getStore('global', 'connectorsByName')
    setActivatingConnector(currentConnector);
    if (setisMaskWallet) {
      setisMaskWallet(false)
    }
    
    activate(connectorsByName[name]);
  })
}


function MyComponent() {

    const context = useWeb3React();
    // const localContext = store.getStore('global','web3context');
    // var localConnector = null;
    // if (localContext) {
    //   localConnector = localContext.connector
    // }
    const {
      connector,
      library,
      account,
      activate,
      // deactivate,
      active,
      // error
    } = context;
    const t = lang
    const connectorsByName = store.getStore('global', 'connectorsByName')
    
  
    const cocosDecimals = config.main.cocos.decimals
    const [activatingConnector, setActivatingConnector] = React.useState();
    const [isMaskWallet, setisMaskWallet] = React.useState(false);
    const [isMaskAccount, setisMaskAccount] = React.useState(false);
    const [cocosBalance, setcocosBalance] = React.useState("--.--");
    
    
    

    React.useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
      }
    }, [activatingConnector, connector]);
  
    React.useEffect(() => {
      if (account && active && library) {
        let oldAccount = store.getStore('global','account')
        store.setStore('global' ,{account: account })
        store.setStore('global' ,{web3context: context })

        if( oldAccount !== account){
          emitter.emit(CONNECTION_CONNECTED)

        }
      } else {
        let connectorsName = store.getStore('global', 'connectorsByName')
        let autoConnect = store.getStore('global', 'autoConnect')
        if (autoConnect) {
          if (setActivatingConnector && activate) {
            let linkedWalletName = localStorage.getItem("linkedWalletName")
            connectorsName = connectorsName[linkedWalletName]
            onConnectionClicked(connectorsName, linkedWalletName, setActivatingConnector, activate)
          }
        }
      }
    }, [account, active, library, context]);


    // React.useEffect(() => {
    //   console.log("====333===")
    //   let account = store.getStore('global','account')
    //   if (account) return


    //   // setTimeout(()=>{
    //   //   let connectorsName = store.getStore('global', 'connectorsByName')
    //   //   let name = "MetaMask"
    //   //   connectorsName = connectorsName[name]
    //   //   let autoConnect = store.getStore('global', 'autoConnect')
    //   //   if (autoConnect) {
    //   //     if (setActivatingConnector && activate) {
    //   //       onConnectionClicked(connectorsName, name, setActivatingConnector, activate)
    //   //     }
    //   //   }
    //   // })
      
      
    // }, [ activatingConnector, activate]);
    function linkJump(){
      let networkType = store.getStore('global', 'networkType')
      let etherscanSite = config[networkType].etherscanSite
      let account = store.getStore('global', 'account')
      window.open(`${etherscanSite}/address/${account}`)
    }
    function openConnectWalletTipMask(){
      setisMaskWallet(true)
    }

    function closeAccountMask(){
      setisMaskAccount(false)
    }

    function closeWalletMask(){
      setisMaskWallet(false)
    }
    

    function changeAccount() {
      setisMaskAccount(false)
      setisMaskWallet(true)
    }

    function showAccountMask(){
      store.getCommonBalance('cocos').then(res => {
        if (res) {
          let balance = res
          balance = KeepDecimals(balanceFormat(balance, cocosDecimals), 3)
          setcocosBalance(balance)
        }
      })
      setisMaskAccount(true)
    }

    // const width = window.innerWidth
    return (
      <div className="WalletLink-main">
        {
        account && context.chainId === 56?
        <>
        <img alt="" src={loggedInPortrait} className="loginIn" onClick={()=>{
           window.location.href = `${config[store.getStore("global","networkType")].newProUri}/account`
          // showAccountMask()
        }} />
        <WalletButton>{account && hideStr(account, 6, 4)}</WalletButton>
        </>:<div className="WalletLink-main-container">
          <WalletButton onClick={()=>{
            openConnectWalletTipMask()
          }}>Connect Wallet</WalletButton>
        </div>
        }
        
        

        {
          isMaskAccount?<div className="account-mask">
            <div className="account-container">
              <div className="account-container-title">
                {t[i18next.language].common['17']}
                <img alt="" src={close} className="close-btn" onClick={()=>{
                  closeAccountMask()
                }} />
              </div>
              <img alt="" className="logo" src={logo} />
              <p className="amount">{cocosBalance}</p>
              <p className="tit1">COCOS {t[i18next.language].common['5']}</p>
              <p className="account-mask-btn account-mask-btn-1" onClick={()=>{
              linkJump()
              }}>{showHtmlText(t[i18next.language].account['0'], config.Binance.name)}</p>
              <p className="account-mask-btn account-mask-btn-2" onClick={()=>{changeAccount()}}>{t[i18next.language].account['1']}</p>
            </div>
          </div>:''
        }

        {
          isMaskWallet?<div className="mask-wallet">

          <div className="connect-wallet-tip-container">
            <div className="title">Connect Wallet
              <img alt="" src={close} className="close-btn" onClick={()=>{closeWalletMask()}} />
            </div>

            <div className="WalletLinkConnector-container">
              {Object.keys(connectorsByName).map((name, index) => {
                const currentConnector = connectorsByName[name];
                let url;
                if (name === 'MetaMask') {
                  url = require('../../../static/images/wallet/MetaMask.png')
                } else if (name === 'MathWallet') {
                  url = require('../../../static/images/wallet/MathWallet.png')
                } else if (name === 'CoinBase') {
                  url = require('../../../static/images/wallet/CoinBase.png')
                }
        
                return (

                  <div className="WalletLink-item" onClick={() => {
                    onConnectionClicked(currentConnector, name, setActivatingConnector, activate, setisMaskWallet)
                  }} key={index}>
                    {
                      localStorage.getItem("linkedWalletName") === name?<ul className="connect-state"></ul>:""
                    }
                    
                    <ul className="item-con">{name}</ul>
                    <ul className="item-icon">
                      <img alt="" src={url} />
                    </ul>
                  </div>
                )

              }) }
        
            </div>
          </div>
        </div>:""
        }
        
      </div>
    
    )
  
  }


const WalletButton = styled.p`
display: inline-block;
width: 137px;
height: 41px;
border-radius: 21px;
background: ${({active}) => active ? 'transparent' : '#00F4E4'} ;
border-style: solid;
border-width: 1px;
border-color: ${({active}) => active ? '#FFF' : '#00F4E4'} ;
color:${({active}) => active ? '#FFFFFF': '#0041A0'};
text-align: center;
line-height: 41px;
font-size: 16px;
transition: opacity .2s linear;
&:hover {
  opacity: .8;
}
`
export default (withRouter(withStyles(styles)(WalletLinkConnector)));