import erc20 from "./abi/erc20";
import playerBook from "./abi/playerBook";
// import CocosNFTClaim from "./mainAbi/CocosNFTClaim.json"
import CocosNFT from "./mainAbi/nft_v1/CocosNFT.json"
import CocosFiveFuClaimOx from "./mainAbi/fivefu/CocosNFTClaimOx.json"
import CocosFiveFuFactory from "./mainAbi/fivefu/CocosNFTFactory.json"

const config = {
  "name": "Etherscan",
  "etherscanSite":"https://kovan.etherscan.io",
  "nodeUri": "https://mainnet.infura.io/v3/9cf149c39e364cdabc3d5129fa7fa1af",

  "common": {
    "playerBookABI": playerBook,
    "playerBookAddress": "0x98ad9D54bCC358619fb34E3B1D43501C0C158a3c"
  },

  "blindbox": {
    "address": "0xAD5E1a0D5f11Be0F39CA8b02e0e0Fa825cF45ED3",
    "ABI": CocosFiveFuClaimOx
  },

  "fiveFu": {
    "factory": {
      "address": "0x26e5f40c9e132e73063edbb14C8d00425E0E404B",
      "ABI": CocosFiveFuFactory
    }
  },
  
  "v1": {
    "nft": {
      "address": "0x40637Bc38039CB2CdF8DeD10229D300AEb469FE7",
      "ABI": CocosNFT
    }
  },
  "cocos": {
    "address": "0x19bF0a1f5F2B29F7D9E7920298C91Fc3E2041BED",
    "decimals": 18,
    "ABI": erc20
  },
  "eth": {
    "decimals": 18
  }
};
  
export default config;