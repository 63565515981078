
export const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED'
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED'

export const ERROR = 'ERROR'

export const CONFIGURE = 'CONFIGURE'
export const CONFIGURE_RETURNED = 'CONFIGURE_RETURNED'

export const REGISTRY_REFERRAL = 'REGISTRY_REFERRAL'
export const REGISTRY_REFERRAL_RETURNED = 'REGISTRY_REFERRAL_RETURNED'


export const GET_REFERRAL_NAME = 'GET_REFERRAL_NAME'
export const GET_REFERRAL_NAME_RETURN = 'GET_REFERRAL_NAME_RETURN'

export const GET_REFERRAL_FEE = 'GET_REFERRAL_FEE'
export const GET_REFERRAL_FEE_RETURN = 'GET_REFERRAL_FEE_RETURN'

export const GET_TOTAL_REFER_REWARD = 'GET_TOTAL_REFER_REWARD'
export const GET_TOTAL_REFER_REWARD_RETURN = 'GET_TOTAL_REFER_REWARD_RETURN'

export const GET_PLAYER_INFO = 'GET_PLAYER_INFO'
export const GET_PLAYER_INFO_RETURN = 'GET_PLAYER_INFO_RETURN'

export const GET_BALANCE_OF = 'GET_BALANCE_OF'
export const GET_BALANCE_OF_RETURN = 'GET_BALANCE_OF_RETURN'
export const APPROVE_UPDATE = 'APPROVE_UPDATE'
export const ALLOWANCE_RETURNED = 'ALLOWANCE_RETURNED'

export const GET_EARNED = 'GET_EARNED'
export const GET_EARNED_RETURN = 'GET_EARNED_RETURN'

export const STAKE_RETURNED = 'STAKE_RETURNED'

export const WITHDRAW_RETURNED = 'WITHDRAW_RETURNED'

export const PLAYERBOOKCLAIM_RETURNED = 'PLAYERBOOKCLAIM_RETURNED'

export const TRANS_RETURNED = 'TRANS_RETURNED'

export const MYINFO_RETURNED = 'MYINFO_RETURNED'

export const STORE_RETURNED = 'STORE_RETURNED'

export const TIME_STORE_RETURNED = 'TIME_STORE_RETURNED'

export const GET_EXCHANGERATE_RETURNED = 'GET_EXCHANGERATE_RETURNED'

export const CLAIM_RETURNED = 'CLAIM_RETURNED'

export const LAST_STAKED_TIME_RETURNED = 'LAST_STAKED_TIME_RETURNED'

// Nft

// export const GET_NFT_AMOUNT_RETURNED = 'GET_NFT_AMOUNT_RETURNED'
// export const GET_NFT_DETAILS_RETURNED = 'GET_NFT_DETAILS_RETURNED'
// export const GET_NFT_ICON_RETURNED = 'GET_NFT_ICON_RETURNED'
export const GET_CLAIM_MEMBERS_RETURN = 'GET_CLAIM_MEMBERS_RETURN'
export const GET_IS_CLAIM_START_RETURN = 'GET_IS_CLAIM_START_RETURN'
export const GET_AIRDROP_DEGO_AMOUNT_RETURN = 'GET_AIRDROP_DEGO_AMOUNT_RETURN'
export const GET_STAKE_DEGO_FOR_KRYPTONITE_RETURN = 'GET_STAKE_DEGO_FOR_KRYPTONITE_RETURN'
export const GET_NFT_CLAIM_RETURN = 'GET_NFT_CLAIM_RETURN'
export const GET_TOKEN_OF_OWNER_RETURN = 'GET_TOKEN_OF_OWNER_RETURN'
export const GET_NFT_CLAIM_ERROR_RETURN = 'GET_NFT_CLAIM_ERROR_RETURN'

export const WITHDRAW_GEGO = 'WITHDRAW_GEGO'


// common
export const CHANGE_ACOUNT = 'CHANGE_ACOUNT'

// prompt
export const MINING_PROMPT_RETURN = 'MINING_PROMPT_RETURN'
export const MINING_DETAIL_CHANGE_ACOUNT_RETURN = 'MINING_DETAIL_CHANGE_ACOUNT_RETURN'
export const COMMON_PROMPT_MINING_DETAILS = 'COMMON_PROMPT_MINING_DETAILS'

// account change
export const MYNFT_CHANGE_ACOUNT_RETURN = 'MYNFT_CHANGE_ACOUNT_RETURN'