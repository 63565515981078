import React from 'react';
// import { NavLink } from "react-router-dom";
import './BlindBox.css'
// import { getCurLanguage } from "../../libs/Util"
import TopCompontent from '../base/TopCompontent/TopCompontent'
import { lang } from "../../i18n/lang/lang"
import { getNftTokenDetails } from '../../libs/Api'
import { timeFormat } from '../../libs/Util'
import i18next from 'i18next'

import title from "../../static/images/blindbox/title.png"
import titleEn from "../../static/images/blindbox/title-en.png"
import fiveFuEnter from '../../static/images/fivefu/fivefu/fivefu-enter.png'
import fiveFuEnterEn from '../../static/images/fivefu/fivefu/fivefu-enter-en.png'
// import title2 from "../../static/images/blindbox/title2.png"
import photoFrame from "../../static/images/blindbox/photo-frame.png"
import box from "../../static/images/blindbox/box.png"
import closeBtn from '../../static/images/fivefu/close-tip.svg'
// import b1 from "../../static/images/blindbox/b1.png"
// import b2 from "../../static/images/blindbox/b2.png"
// import b3 from "../../static/images/blindbox/b3.png"
// import b4 from "../../static/images/blindbox/b4.png"
// import b5 from "../../static/images/blindbox/b5.png"
// import b6 from "../../static/images/blindbox/b6.png"

import store from "../../stores";
const Store = store.store

export default class Referral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           isMask: false,
           isMaskTick: true,
           countDownText: {
               d: '00',
               h: '00',
               m: '00',
               s: '00'
           },
           loading: false,
           isClaimStart: false,
           isClaim: true,
           isShowWeakHintMain: false,
           nftDetails: {},
           promptText: '',
           isWhitelist: false,

           routerPath: ""
        }
        this.t = lang
        this.account = Store.getStore('global','account')
        this.tickTimer = null
        this.localize  = Store.getStore("global", "localize")
    }

    async initDate(){
        // let isClaimStart = await Store.getIsClaimStart()
        let isClaimMembers = await Store.getClaimMembers()
        let isWhitelist = await Store.getIsWhitelist()
        this.setState({
            // isClaimStart: isClaimStart,
            isClaim: isClaimMembers,
            isWhitelist: isWhitelist
        })
    }
    async claimBox(){
        let _this = this
        // if (!this.state.isClaimStart) {
        //     this.setState({promptText: _this.t[i18next.language].common["2"]})
        //     this.showWeakHintMain()
        //     return
        // }
        if (!this.state.isWhitelist) {
            this.setState({promptText: `${this.t[i18next.language].BlindBox["2"]} 0`})
            this.showWeakHintMain()
            return
        }
        if (this.state.isClaim) {
            this.setState({promptText: `${_this.t[i18next.language].BlindBox["2"]} 0`})
            this.showWeakHintMain()
            return
        }
        this.setState({loading: true})
        Store.claimBox().then(async result => {
            if (result.events) {
                let nftId = result.events.eveCocosNFTAdded.returnValues.id
                let nftDetailsUri = await Store.getTokenURI({
                    nftVer: "v1",
                    nftId: nftId
                })
                let nftDetails = await getNftTokenDetails(nftDetailsUri)
                // /mynftdetails/:nftId
                let routerPath = `mynftdetails/${nftDetails.id}`
                this.setState({routerPath: routerPath})
                _this.setState({
                    loading: false,
                    nftDetails: nftDetails,
                    isMask: true
                })
                _this.initDate()
            } else {
                _this.setState({
                    loading: false
                })
            }
        })
    }

    componentDidMount () {
        let _this = this
        function tickFn() {
            let startTime = 1611813600
            // let startTime = 1611733200
            let curTimestamp = (new Date()).getTime()
            curTimestamp = String(curTimestamp).slice(0, 10)
            let countDownSec = startTime - curTimestamp
            if (countDownSec > 0) {
                let countDown = timeFormat(countDownSec)
                _this.setState({countDownText: countDown})
                _this.tickTimer = setTimeout(()=>{
                    tickFn()
                }, 1000)
            } else {
                clearTimeout(_this.tickTimer)
                _this.initDate()
                _this.setState({
                    isMaskTick: false
                })
            }
        }
        tickFn()
        if (this.account === '') return
        this.initDate()
    }

    showWeakHintMain() {
        let _this = this
        if (this.state.isShowWeakHintMain) return
        this.setState({isShowWeakHintMain: true})
        setTimeout(() => {
            _this.setState({isShowWeakHintMain: false})
        }, 3000)
    }
    async listenWallet(){
        this.initDate()
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }

    routerJump(routerName){
        this.props.history.push(`/${routerName}`)
    }

    openBox(){
        this.setState({isMask: true})
    }

    closeIsMask(){
        this.setState({isMask: false})
    }

    componentWillUnmount () {
        let _this = this
        clearTimeout(_this.tickTimer)

        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        return (
            <div className="blind-box-component">
                {
                    this.state.isMaskTick?<div className="tick-mask">
                    <div className="count-down-container">
                        <p className="count-down-num">{this.state.countDownText?this.state.countDownText.d:'00'}</p>
                        <p className="count-down-symbol">:</p>
                        <p className="count-down-num">{this.state.countDownText?this.state.countDownText.h:'00'}</p>
                        <p className="count-down-symbol">:</p>
                        <p className="count-down-num">{this.state.countDownText?this.state.countDownText.m:'00'}</p>
                        <p className="count-down-symbol">:</p>
                        <p className="count-down-num">{this.state.countDownText?this.state.countDownText.s: '00'}</p>
                    </div>
                </div>:null
                }
                
                {
                    this.state.loading?<div className="full-mask">
                    <div className="loading-big"></div>
                </div>:''
                }
                
                {
                    this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.state.promptText}</div>:''
                }

                {
                    this.state.isMask?<div className="open-box-mask">
                    <ul className="photo-frame-main">
                        <img alt="" src={closeBtn} className="close-btn" onClick={()=>{this.closeIsMask()}}/>
                        <img alt="" src={photoFrame} className="photo-frame-img" />
                        <img alt="" src={this.state.nftDetails.big_image} className="nft-img" />
                    </ul>
                    <p className="open-box-content">{this.t[i18next.language].BlindBox["0"]}<span className="fcffd483 cursor-pointer" onClick={()=>{
                        this.routerJump(this.state.routerPath)
                    }}>{this.t[i18next.language].nft[this.state.nftDetails.name]} NFT >></span>，{this.t[i18next.language].BlindBox["1"]}</p>
                </div>:''
                }
                
                <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>

                <div className="blind-box-coutainer">
                    {
                        <ul className="activity-desc-icon" onClick={()=>{this.routerJump('fivefu')}}>
                            {
                                i18next.language  === "cn"?<img alt="" src={fiveFuEnter} />:<img alt="" src={fiveFuEnterEn} />
                            }
                            <p className="text">{this.t[i18next.language].BlindBox["5"]}</p>
                        </ul>
                    }
                    {
                        i18next.language === 'cn'?<img alt="" src={title} className="title-icon" />:<img alt="" src={titleEn} className="title-icon" />
                    }
                    <div className="title-1">{this.t[i18next.language].BlindBox["2"]} {!this.state.isClaim && this.state.isWhitelist?"1":"0"}</div>
                </div>
                
                <div className="blind-box-coutainer">
                    <div className="blind-box-main" onClick={()=>{this.claimBox()}}>
                        <img alt="" src={box} />
                        {/* <img alt="" src={b1} className="box-cover" /> */}
                    </div>
                    <div className="blind-box-main" onClick={()=>{this.claimBox()}}>
                        <img alt="" src={box} />
                        {/* <img alt="" src={b2} className="box-cover" /> */}
                    </div>
                    <div className="blind-box-main" onClick={()=>{this.claimBox()}}>
                        <img alt="" src={box} />
                        {/* <img alt="" src={b3} className="box-cover" /> */}
                    </div>
                    <div className="blind-box-main" onClick={()=>{this.claimBox()}}>
                        <img alt="" src={box} />
                        {/* <img alt="" src={b4} className="box-cover" /> */}
                    </div>
                    <div className="blind-box-main" onClick={()=>{this.claimBox()}}>
                        <img alt="" src={box} />
                        {/* <img alt="" src={b5} className="box-cover" /> */}
                    </div>
                    <div className="blind-box-main" onClick={()=>{this.claimBox()}}>
                        <img alt="" src={box} />
                        {/* <img alt="" src={b6} className="box-cover" /> */}
                    </div>
                </div>
            </div>
        )
    }
}