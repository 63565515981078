import erc20 from "./abi/erc20";
import playerBook from "./abi/playerBook";
// import CocosNFTClaim from "./mainAbi/CocosNFTClaim.json"
import CocosNFT from "./mainAbi/nft_v1/CocosNFT.json"
import baseProxy from "./mainAbi/nft_v1/baseProxy.js"
import CocosFactory from "./mainAbi/nft_v1/CocosFactory.json"
import CocosFiveFuClaimOx from "./mainAbi/fivefu/CocosNFTClaimOx.json"
import CocosFiveFuFactory from "./mainAbi/fivefu/CocosNFTFactory.json"
import CocosCowNFTReward from "./mainAbi/mining/CocosCowNFTReward.json"
import CocosDividend from "./mainAbi/dividends/CocosDividend.json"
import CocosDividendTeam from "./mainAbi/dividends/CocosDividendTeam.json"

const config = {
  "name": "Bscscan",
  "defaultWalletToken": "bnb",
  "etherscanSite":"https://testnet.bscscan.com",
  "nodeUri": "https://bsc-dataseed1.defibit.io/",
  "newProUri": "http://tests.cocosbcx.io",

  "common": {
    "playerBookABI": playerBook,
    "playerBookAddress": "0x41c6069E97eD7DcE4ebef3D174cF9C63Af42D938"
  },

  "fiveFu": {
    "factory": {
      "address": "0xaDa65FC5f4916BDce06552A19A2C987F16CeFdd0",
      "ABI": CocosFiveFuFactory
    }
  },
  "blindbox": {
    "address": "0x3ef4532571EbB26ABDe815D3C5e2DbbBd97D7457",
    "ABI": CocosFiveFuClaimOx
  },
  "v1": {
    "nft": {
      "address": "0xc9c288c4f480d20f26cacc2323b48630ca3f0e87",
      "ABI": CocosNFT
    },
    "factory": {
      // "address": "0xaDa65FC5f4916BDce06552A19A2C987F16CeFdd0",
      "address": "0xaDa65FC5f4916BDce06552A19A2C987F16CeFdd0",
      "ABI": CocosFactory
    },
    "baseProxy": {
      "address": "0x409E5dAABdE6371B58e5ef6C09c96ae76f9814D5",
      "ABI": baseProxy
    }
  },
  "mining": {
    "0": {
      "address": "0x293562996809254964C7fea6cE1A47D30b41f8c0",
      "ABI": CocosCowNFTReward
    },
    "1": {
      "address": "0x25A7478E3E103e8cA2eb96696B770B1c8103cAEc",
      "ABI": CocosCowNFTReward
    }
  },

  "Dividends": {
    "address": "0xF449B44387fa91c9eBA93dD04eE38d34e0855578",
    "ABI": CocosDividend
  },

  "DividendsTeam": {
    "address": "0xb9b6c20EA75Ddd1941CfFD41AABD33Fa52b2967D",
    "ABI": CocosDividendTeam
  },

  "cocos": {
    "address": "0x98ad9D54bCC358619fb34E3B1D43501C0C158a3c",
    "ABI": erc20,
    "decimals": 18
  },
  "bnb": {
    "decimals": 18
  },


  "math": {
    "address": "0xd119eee754df307c757812c21177139a8d22f56c",
    "ABI": erc20,
    "decimals": 18
  },

  "bot": {
    "address": "0xfb5dCa6561647EFB84e492352Bdd7D9A59C2a99d",
    "ABI": erc20,
    "decimals": 18
  },


  "cocos1": {
    "address": "0xAb301DaE71f5B386C566f484e636Aee60318F12F",
    "decimals": 18,
    "ABI": erc20
  }
};
  
export default config;