export const en = {
    "walletToken": {
        "Binance": "BNB",
        "Etherscan": "ETH"
    },
    "common": {
        "0": "User",
        "1": "Copy",
        "placeholder": {
            "0": "Please enter user name",
            "1": "Fill in the transfer address",
            "2": "Import sale Price"
        },
        "timer": {
            "0": "Days",
            "1": "Hrs",
            "2": "Min",
            "3": "Sec"
        },
        "2": "Ready to be Opened",
        "3": "Copy successful",
        "4": "Date",
        "5": "Balance",
        "6": "par value",
        "7": "Sale",
        "8": "Send",
        "9": "Disassemble",
        "10": "Address",
        "11": "Sale price",
        "12": "Cancel",
        "13": "Confirm",
        "14": "Max",
        "15": "Amount",
        "16": "You haven’t NFT",
        "17": "My account",
        "18": "Approved",
        "19": "The current staking period is less than 72 hours so the proposed rewards will be deducted by 10%. It is expected to receive <span style='color: #e02020'>##</span> ##",
        "20": "You haven’t staking",
        "21": "Insufficient ## balance",
        "22": "Casting Fee",
        "23": "Approve",
        "24": "View in My NFT",
        "25": "First",
        "26": "Last",
        "27": "END",
        "28": "Farming Now",
        "29": "To Cast",
        "30": "Join",
        "31": "OK",
        "32": "Countdown",
        "33": "Disassemble",
        "34": "Stake",
        "35": "Create",
        "36": "View",
        "37": "Harvest",
        "38": "Staking",
        "39": "Withdraw",
        "40": "Power",
        "41": "The current staking period is less than 7 days so the proposed rewards will be deducted by 10%. It is expected to receive <span style='color: #FFD244'>##</span> ##",
        "42": "Total Power",
        "43": "Mining efficiency",
        "44": "Successful Staking",
        "45": "Successful Withdraw"
    },
    "tips": {
        "0": "Wrong Network",
        "1": "No connectable wallet is detected,",
        "2": "Please connect to the appropriate Ethereum network.",
        "3": "Please connect to the appropriate Binance Smart Chain network.",
        "4": "install the wallet."
    },
    "home": {
        "0": "Home",
        "1": "My NFT",
        "2": "Document"
    },
    "Referral": {
        "0": "How to get rich at zero cost",
        "1": "Generate an exclusive promotion link",
        "2": "Buy promoters and generate exclusive referral links",
        "3": "Invite Friends",
        "4": "Share your referral link with friends",
        "5": "Get rich together",
        "6": "You can get up to 10% real time commission after your friend completes the transaction",
        "7": "Promoter Registration",
        "8": "Get 10% rebate",
        "9": "Users display of promotion benefits ",
        "10": "Invite benefit",
        // "11": "The initial promoter fee is 0.1ETH, and for every more than 100 promoters registered, the registration fee +0.1ETH",
        "11": "The initial promoter fee is ##, and for every more than 100 promoters registered, the registration fee +##",
        "12": "*Name must be unique",
        "13": "*Name will be converted to lowercase",
        "14": "*Cannot be just numbers",
        "15": "*Cannot start with 0x",
        "16": "*Name must be at least 1 character",
        "17": "*Maximum length is 32 characters",
        "18": "*Allowed characters: a-z, 0-9",
        "19": "Your exclusive invitation link",
        "20": "Your promotion income",
        "21": "Current Commission",
        "22": "Withdraw immediately",
        "23": "My Rebate Account",
        "24": "I have received income",
        "25": "Level 1 Promotion",
        "26": "Level 2 Promotion",
        "27": "Your ranking",
        "28": "7% rebate income",
        "29": "3% rebate income",
        "30": "Revenue Ranking",
        "31": "Ranking",
        "32": "Users' name",
        "33": "Sum of invitation benefits",
        "34": "For invited Friends",
        "35": "https://en-docs.cocosbcx.io/"
    },
    "BlindBox": {
        "0": "You have obtained your ",
        "1": "Saved in your wallet",
        "2": "Times of opening BlindBox is",
        "3": "Blind box",
        "4": "Email Subscript",
        "5": "Get 8000 COCOS"
    },
    "mynft": {
        "0": "Information"
    },
    "nftDetails": {
        "1": "Grade",
        "2": "Quality",
        "3": "COCOS Amount",
        "4": "Created Time",
        "5": "Block Num",
        "6": "Author",
        "7": "Name",
        "8": "Decomposition is expected to get"
    },
    "account": {
        // "0": "View in Etherscan",
        "0": "View in ##",
        "1": "Sign out"
    },
    "fivefu": {
        "0": "Activity",
        "1": "Gather the Five Fortune, and try your luck",
        "2": "Lottery countdown",
        // "2": "Prize pool",
        "3": "Five Fortune have been collected",
        "4": " people",
        "5": "COCOS is grateful to have you",


        "6": "Open",
        "7": "",

        "8": "The current account is not authorized, the red envelope can only be opened after authorization~",
        "9": "Congratulations on gaining",
        "10": "It will be deposited into your account soon",
        "11": "View >>",
        // "12": "Collection of Five Fortunes",
        "13": "Prize pool",
        "14": "Rules for Collecting Fortunes Activities",
        "15": "<span style='font-weight: bold;'>How to play:</span><br/>1. Users can get the Five Fortunes NFT+Year of the Ox series by opening the blind boxes. COCOS is cast in the NFT and subsequent mining pool mining can be performed, or the bonus can be shared by collecting the Five Fortunes（Patriotism NFT,Dedication NFT,Prosperity NFT,Harmony NFT,Friendliness NFT） Pool.<br/>2. After the Five Fortunes are collected, they will be eligible to share the bonus pool. After the bonus pool is divided, the Five Fortunes（Patriotism NFT,Dedication NFT,Prosperity NFT,Harmony NFT,Friendliness NFT） will be consumed.",
        "16": "Activity time: January 28th-February 7th"
    },
    "nft": {
        "Bull Year Of Great Fortune": "Bull Year Of Great Fortune",
        "Bull Year Of Full Wishes": "Bull Year Of Full Wishes",
        "Bull Year Of New Chances": "Bull Year Of New Chances",
        "Bull Year Of Sharp Motivations ": "Bull Year Of Sharp Motivations",
        "Bull Year Of Great Prosperity": "Bull Year Of Great Prosperity",
        "Bull Year of Successful Coin": "Bull Year of Successful Coin",
        "Happy New Year": "Happy New Year",
        "Harmony": "Harmony",
        "Patriotism": "Patriotism",
        "Friendliness": "Friendliness",
        "Prosperity": "Prosperity",
        "Dedication": "Dedication",
        "Collet following Five Fortunes (Harmony, Patriotism, Dedication, Friendliness, Prosperity) and share 8000 COCOS bonus pool prize": "Collet following Five Fortunes (Harmony, Patriotism, Dedication, Friendliness, Prosperity) and share 8000 COCOS bonus pool prize"
    },
    "mining": {
        "common": {
            "1": "Mining Rules",
            "2": "1）Each level of COCOS NFT has different computing power. The higher the computing power, the higher the mining efficiency.",
            "3": "Total Power = COCOS Nominal value * mining efficiency",
            "4": "2）The first mining pool produced a total of 100,000 COCOS and continued mining for 30 days.",
            "5": "2）There is no upper limit to stake, the more the stake, the more COCOS will be produced."
        },
        "0": {
            "name": "COCOS New Year's Pool",
            "describe": "Stake your COCOS NFT in hand to make more COCOS."
        },
        "1": {
            "name": "Treasure Collection Pavilion",
            "describe": "Pavilion for collecting endless treasures."
        }
        
    },
    "miningDetails": {
        "0": "Total Power",
        "1": "Total Revenue",
        "2": "Earnings/Power (1 day)",
        "3": "Unit Price",
        "4": "Countdown",
        "5": "My Staking List",
        "6": "COCOS Produced",
        "7": "Obtain",
        "8": "Extract All",
        "9": "COCOS nominal value",
        "10": "Mining efficiency",
        "11": "Withdraw",
        "12": "Withdraw",
        "13": "My NFT",
        "14": "Staking",
        "15": "My Power"
    },
    "miningRule": {
        "head": ["等级", "面值", "挖矿效率"],
        "body": [
            ["LV1", "x", "1.1x"],
            ["LV2", "x", "1.2x"],
            ["LV3", "x", "1.3x"],
            ["LV4", "x", "1.4x"],
            ["LV5", "x", "1.6x"],
            ["LV6", "x", "1.8x"]
        ]
    },
    "casting": {
        "thead": ["NAME", "ID", "Amount",  "Created Time"],
        "0": "NFT has not been selected for mint",
        "1": "Please fill in the amount for mint ",
        "2": "The amount for mint cannot be higher than the maximum value",
        "3": "The amount for mint cannot be lower than the minimum value",
        "4": "Casting",
        "5": "Casting Success"
    },
    "dividends": {
        "0": "Total Dividends",
        "1": "Current Dividend",
        "2": "Team Captain",
        "3": "Total Power",
        "4": "Dividends",
        "5": "Dividend/Power(1 Day)",
        "6": "Team Name",
        "7": "Team Creation Rules",
        "8": "Create Team",
        "9": "Dividend Time",
        "10": "Team Mining Power",
        "11": "Dividend For Today",
        "12": "Current Mining Yield",
        "13": "Cumulative Dividends",
        "14": "Captain Income",
        "15": "Locked",
        "16": "My Earned",
        "17": "My Dividend",
        "18": "Unpaid Dividend",
        "19": "Cumulative Dividends",
        "20": "Dividends Rewarding Rules",
        "21": "Staked mining power",
        "22": "My Stake",
        "23": "Current Dividend",
        "24": "Dividend Mined",
        "rule": {
            "0": "Users who hold COCOS NFT and COCOS can pledge NFT and COCOS to participate in this rewarding.",
            "1": "Dividends Rewarding Efficiency is calculated based on the user's pledged computing power, the higher the computing power is, the higher the proportion of dividends is.",
            "2": "1 COCOS = 1 Power",
            "3": "NFT Power = COCOS Par Value * Mining Efficiency",
            "4": "If the pledge time is less than one week, 10% of the claimed income will be deducted into the dividend pool.",
            "5": "The captain of the team built enjoys 20% of the team’s total revenue (only users who hold the hero NFT can build a team) source of this kind of NFT: evil heroes, elemental heroes, chaos heroes, dark heroes, masked heroes, destiny heroes, red flame heroes  , Arctic Heroes NFT)",
            "6": "Hero NFT can only create team dividends, not pledge dividends.",
            "7": "When the captain leaves the team (withdrawing the NFT of the hero who created the team pledge), the captain's income will no longer generate income.",
            "8": "After the captain withdraws from the team, the team will no longer participate in the next round of dividends."
        },
        "teamrRule": {
            "0": "Only users with Heroes NFT can build a team and become a Team Captain by staking NFT.",
            "1": "Names of this kind of Heroes NFT: Evil hero, Elements hero, Chaos hero, Dark hero, Mask hero,Fate hero, Ashing hero,Icy Hero."
        }
    }
}