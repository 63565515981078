import React from 'react';
import './MyNftDetails.css'
// import Loading from "../base/Loading/Loading"
import { balanceFormat, KeepDecimals, dateCommonFormat, hideStr, returnLocalize, countDown } from "../../libs/Util"
import MaskFullScreen from "../base/MaskFullScreen/MaskFullScreen"
import  close from "../../static/images/common/close.png"
import disassemble from '../../static/images/nft/disassemble.png'
import i18next from 'i18next'
import { getNftTokenDetails } from "../../libs/Api"
import TopCompontent from '../base/TopCompontent/TopCompontent'
import { lang } from "../../i18n/lang/lang"
import store from "../../stores";
import config from "../../config/config"
const Store = store.store

export default class Referral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myNftList: [],
            myNftDetails: {},

            isSaleMask: false,
            isSendMask: false,
            saleLoading: false,
            sendLoading: false,
            isAddressWarming: false,
            isShowWeakHintMain: false,
            isLoading: true,

            isMaskDisassemble: false,
            isDisassembleComplete: false,
            isDisassemble: false,
            isLoadingDisassemble: false,
            isDisassembleApproved: false,
            countdownTimeDisplay: `00:00:00:00`,
            isLoadingDisassembleApproved: false
        }
        this.t = lang
        this.account = Store.getStore('global','account')
        let networkType = Store.getStore("global", "networkType")
        this.config = config[networkType]
        this.nftVer = "v1"
        this.localize  = Store.getStore("global", "localize")
        this.countdownTimer = null
    }

    async initDate(){
        let _this = this
        this.account = Store.getStore('global','account')
        if (this.account === '') return
        let { nftId } = this.props.match.params
        let networkType = Store.getStore("global", "networkType")
        this.config = config[networkType]
        
        let nftDetailsUri = await Store.getTokenURI({
            nftVer: this.nftVer,
            nftId: nftId
        })
        let nftDetails = await getNftTokenDetails(nftDetailsUri)
        let myNftDetails = nftDetails
        let erc20 = myNftDetails.attributes.erc20
        let curNftToken = null
        for (const key in _this.config) {
            if (_this.config[key].hasOwnProperty('address')) {
                if (_this.config[key].address.toLowerCase() === erc20.toLowerCase()) {
                    curNftToken = _this.config[key]
                }
            }
        }
        let amount = myNftDetails.attributes.amount
        myNftDetails.amountDisplay = KeepDecimals(balanceFormat(amount, curNftToken.decimals), 3)
        let createdTime = myNftDetails.attributes.createdTime
        createdTime = createdTime + '000'
        myNftDetails.createdTimeDisplay = dateCommonFormat(new Date(Number(createdTime)), 'YY-mm-dd')
        this.setState({
            myNftDetails: myNftDetails,
            isLoading: false
        })
    }
    
    confirmSend(){
        if (this.state.sendLoading) return
        
        let _this = this
        let toAddress = this.inputAddressData.value
        let { nftId } = this.props.match.params
        _this.setState({isAddressWarming: false})
        if (toAddress === '') {
            _this.setState({isAddressWarming: true})
            return false
        }
        let params = {
            nftVer: 'v1',
            toAddress: toAddress,
            tokenId: Number(nftId)
        }
        this.setState({sendLoading: true})
        Store.transferFromNft(params).then(res => {
            _this.setState({sendLoading: false})
            if (res) {
                _this.props.history.push(`/mynft`)
            }

        })
    }

    componentDidMount () {
        if (this.account === '') return
        this.setState({isLoading: true})
        this.initDate()
    }

    async listenWallet(){
        this.initDate()
    }


    componentWillUnmount () {
        let _this = this
        clearTimeout(_this.countdownTimer)
    }

    openSendMask() {
        this.setState({isSendMask: true})
    }

    closeSendMask() {
        this.setState({isSendMask: false})
    }

    closeSaleMask() {
        this.setState({isSaleMask: false})
    }

    openSaleMask() {
        this.setState({isSaleMask: true})
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }


    closeDisassemble() {
        let _this = this
        if (this.state.isLoadingDisassemble) return
        clearTimeout(_this.countdownTimer)
        this.setState({isMaskDisassemble: false})
    }

    async disassemble(){
        if (this.state.isLoadingDisassemble || !this.state.isDisassemble) return
        this.setState({isLoadingDisassemble: true})
        let result = await Store.burn({
            nftVer: this.nftVer,
            tokenId: this.state.myNftDetails.id
        })
        this.setState({isLoadingDisassemble: false})
        if (result) {
            this.setState({isDisassembleComplete: true})
        }
    }

    async setFactoryApprovedForAll (){
        let _this = this
        if (this.state.isLoadingDisassembleApproved) return
        this.setState({isLoadingDisassembleApproved: true})
        let result = await Store.setFactoryApprovedForAll({
            nftVer: this.nftVer
        })
        let isDisassembleApproved = false
        if (result) {
            isDisassembleApproved = await Store.getFactoryApprovedForAll({
                nftVer: _this.nftVer
            })
        }
        this.setState({
            isDisassembleApproved: isDisassembleApproved,
            isLoadingDisassembleApproved: false
        })
    }

    async disassembleShow(){
        let _this = this
        this.setState({isDisassembleComplete: false})
        let createdTimestamp = this.state.myNftDetails.attributes.createdTime
        let ruleId = this.state.myNftDetails.attributes.ruleId
        let nftType = this.state.myNftDetails.attributes.nftType
        createdTimestamp = Number(createdTimestamp) * 1000
        let curDateTimestamp = (new Date()).getTime()
        
        let proxyAddress = await Store.getRuleProxys({
            nftVer: _this.nftVer,
            nftType: nftType
        })
        let ruleData = await Store.getAutoRuleData({
            ruleID: ruleId,
            proxyAddress: proxyAddress,
            nftVer: _this.nftVer
        })
        let minBurnTime = ruleData.minBurnTime
        let gegoBurnTime = Number(curDateTimestamp) - Number(createdTimestamp)
        gegoBurnTime = String(gegoBurnTime).slice(0, String(gegoBurnTime).length-3)
        let isDisassembleApproved = await Store.getFactoryApprovedForAll({
            nftVer: _this.nftVer
        })
        if (Number(gegoBurnTime) >= Number(minBurnTime)) {
            _this.setState({
                isDisassemble: true,
                isMaskDisassemble: true,
                isDisassembleApproved: isDisassembleApproved
            })
        } else {
            function countdownFn () {
                gegoBurnTime = Number(gegoBurnTime) + 1
                let countdownTime = countDown(Number(gegoBurnTime)*1000, Number(minBurnTime)*1000)
                let countdownTimeDisplay = `${countdownTime.d}:${countdownTime.h}:${countdownTime.m}:${countdownTime.s}`
                if (countdownTimeDisplay === '00:00:00:00') {
                    clearTimeout(_this.countdownTimer)
                    _this.disassembleShow()
                    return false
                }
                _this.setState({countdownTimeDisplay: countdownTimeDisplay})
                _this.countdownTimer = setTimeout(function () {
                    countdownFn()
                }, 1000)
            }
            countdownFn()
            _this.setState({
                isMaskDisassemble: true,
                isDisassemble: false,
                isDisassembleApproved: isDisassembleApproved
            })
        }
    }


    linkJumpSale () {
        let { nftId } = this.props.match.params
        // let router = `https://www.treasureland.market/#/nft-detail?version=cocos&token_id=${nftId}`
        // window.open(router)
        let contractAddr = this.config[this.nftVer].nft.address
        let uri = `https://treasureland.market/assets/${contractAddr.toLowerCase()}/${nftId}`
        window.open(uri)
    }
    routerJumpAddress (myAccount) {
        let router = config[Store.getStore('global','networkType')].etherscanSite + '/address/' + myAccount
        window.open(router)
    }

    routerJump(){
        this.props.history.push(`/mynft`)
    }

    showWeakHintMain() {
        let _this = this
        if (this.state.isShowWeakHintMain) return
        this.setState({isShowWeakHintMain: true})
        setTimeout(() => {
            _this.setState({isShowWeakHintMain: false})
        }, 3000)
    }
    render() {
        return (
            <div className="bg-component">
                {
                    this.state.isLoading?<div className="mask">
                    <MaskFullScreen />
                    </div>:""
                }
                
                
                {
                    this.state.isMaskDisassemble?
                    <div className="mask">
                    {this.state.isDisassembleComplete?<div className="mask-disassemble">

                        <img alt="" src={close} className="close-btn" onClick={()=>{
                            this.routerJump()
                        }}/>
                        
                        <ul className="disassemble-icon">
                            <img alt="" src={this.state.myNftDetails.small_image}/>
                        </ul>
                        <ul className="disassemble-nft-Amount">{this.t[i18next.language].nftDetails['8']} <span>{this.state.myNftDetails.amountDisplay} COCOS</span> </ul>
                        <p className="disassemble-btn" onClick={()=>{
                            this.routerJump()
                        }}>{this.t[i18next.language].common['31']}</p>

                        </div>:<div className="mask-disassemble">
                        <img alt="" src={close} className="close-btn" onClick={()=>{
                            this.closeDisassemble()
                        }}/>
                        <ul className="disassemble-icon">
                            <img alt="" src={this.state.myNftDetails.small_image} />
                        </ul>
                        {
                            this.state.isDisassemble?<p className="disassemble-nft-Amount">{this.t[i18next.language].common['15']}：{this.state.myNftDetails.amountDisplay} COCOS</p>:""
                        }
                        {
                            !this.state.isDisassemble?<ul className="disassemble-nft-Amount">{this.t[i18next.language].common['32']}: <span className="countdown">{this.state.countdownTimeDisplay}</span></ul>:""
                        }
                        
                        {
                            this.state.isDisassembleApproved?<ul className={this.state.isDisassemble?"disassemble-btn":"disable-btn disassemble-btn"} onClick={()=>{
                                this.disassemble()
                            }}>{this.state.isLoadingDisassemble?<p className="loading"></p>:this.t[i18next.language].common['33']}</ul>:<div className="disassemble-btn" onClick={()=>{
                                this.setFactoryApprovedForAll()
                            }}> {this.state.isLoadingDisassembleApproved?<p className="loading"></p>:this.t[i18next.language].common['23']}</div>
                        }
                    </div>}
                </div>:""
                }
                
                
                {
                    this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.t[i18next.language].common["2"]}</div>:''
                }
                {
                    this.state.isSendMask?<div className="mask">
                    <div className="mask-container send-mask-container">
                        <img alt="" src={close} className="close-btn" onClick={()=>{
                            this.closeSendMask()
                        }}/>
                        <p className="title mart73">{this.t[i18next.language].common['10']}:</p>
                        <ul className="mask-input-container">
                            <input type="text" ref={(val) => this.inputAddressData = val} placeholder={this.t[i18next.language].common.placeholder['1']} className={this.state.isAddressWarming?"mask-input isWarming":"mask-input"} />
                        </ul>
                        <p className="confirm-btn" onClick={() => {
                            this.confirmSend()
                        }}>{this.state.sendLoading?<b className="loading"></b>:this.t[i18next.language].common['13']}</p>
                    </div>
                </div>:''
                }
                {
                    this.state.isSaleMask?<div className="mask">
                    <div className="mask-container send-mask-container">
                        <img alt="" src={close} className="close-btn" onClick={()=>{
                            this.closeSaleMask()
                        }}/>
                        <p className="title mart67">{this.t[i18next.language].common['11']}:</p>
                        <ul className="mask-input-container">
                            <input type="text" placeholder={this.t[i18next.language].common.placeholder['2']} className="mask-input" />
                            <p className="unit">BNB</p>
                        </ul>
                        <p className="confirm-btn">{this.state.saleLoading?this.t[i18next.language].common['13']:<b className="loading"></b>}</p>
                    </div>
                </div>:''
                }
                

            <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>
                <div className="container-main">
                    <div className="nftDetails-container">
                        <ul className="nftDetails-icon">
                            <img alt="" src={this.state.myNftDetails.small_image} />
                        </ul>

                        <div className="nftDetails-info">
                            <div className="disassemble-btn" onClick={() => {
                                this.disassembleShow()
                            }}>
                                <ul className="disassemble-container" onClick={() => {
                                }}>
                                    <img alt="" src={disassemble} className="disassemble-icon" />
                                    <span className="disassemble-text">{this.t[i18next.language].common['9']}</span>
                                </ul>
                            </div>
                            <p className="title">{returnLocalize(this.state.myNftDetails.i18n_name,i18next.language)}</p>
                            {/* <p className="isowner">owner by you</p> */}
                            <p className="describe">{returnLocalize(this.state.myNftDetails.i18n_description,i18next.language)}</p>
                            <ul className="btn-group">
                                <p className="btn send-btn" onClick={()=>{
                                    this.openSendMask()
                                }}>{this.t[i18next.language].common['8']}</p>
                                <p className="btn sale-btn" onClick={() => {
                                    this.linkJumpSale()
                                }}>{this.t[i18next.language].common['7']}</p>
                            </ul>
                            <p className="title1">{this.t[i18next.language].mynft['0']}</p>
                            <div className="attr-group">
                                <ul className="attr-li">
                                    <p className="key">Id:</p>
                                    <p className="val">{this.state.myNftDetails.id?this.state.myNftDetails.id:'-'}</p>
                                </ul>
                                <ul className="attr-li">
                                    <p className="key">{this.t[i18next.language].nftDetails['4']}:</p>
                                    <p className="val">{this.state.myNftDetails.createdTimeDisplay?this.state.myNftDetails.createdTimeDisplay:'-'}</p>
                                </ul>
                                <ul className="attr-li">
                                    <p className="key">{this.t[i18next.language].nftDetails['1']}:</p>
                                    <p className="val">{this.state.myNftDetails.attributes?this.state.myNftDetails.attributes.grade:'-'}</p>
                                </ul>
                                <ul className="attr-li">
                                    <p className="key">{this.t[i18next.language].nftDetails['5']}:</p>
                                    <p className="val">{this.state.myNftDetails.attributes?this.state.myNftDetails.attributes.blockNum:'-'}</p>
                                </ul>
                                <ul className="attr-li">
                                    <p className="key">{this.t[i18next.language].nftDetails['2']}:</p>
                                    <p className="val">{this.state.myNftDetails.attributes?this.state.myNftDetails.attributes.quality:'-'}</p>
                                </ul>
                                <ul className="attr-li">
                                    <p className="key">{this.t[i18next.language].nftDetails['6']}:</p>
                                    <p className="val cursor-addr" onClick={()=>{
                                        this.routerJumpAddress(this.state.myNftDetails.attributes.author)
                                    }}>{this.state.myNftDetails.attributes?hideStr(this.state.myNftDetails.attributes.author, 4, 4):'-'}</p>
                                </ul>
                                <ul className="attr-li">
                                    <p className="key">{this.t[i18next.language].nftDetails['3']}:</p>
                                    <p className="val">{this.state.myNftDetails.amountDisplay?this.state.myNftDetails.amountDisplay:'-'}</p>
                                </ul>
                                <ul className="attr-li">
                                    <p className="key">{this.t[i18next.language].nftDetails['7']}:</p>
                                    <p className="val">{returnLocalize(this.state.myNftDetails.i18n_name,i18next.language)}</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}