import React from 'react'
import './Dividends.css'
import close from '../../static/images/common/close.svg'
import createIcon from '../../static/images/common/create.svg'
import defaultIcon from '../../static/images/Dividends/default_icon.png'
import icon1 from '../../static/images/Dividends/icon11.svg'
import icon2 from '../../static/images/Dividends/icon22.svg'
import icon3 from '../../static/images/Dividends/icon33.svg'
import ruleBtn from '../../static/images/common/rule-btn.png'
import selectIcon from '../../static/images/Dividends/select-icon.png'
import TopCompontent from '../base/TopCompontent/TopCompontent'
import { getNftTokenDetails, getKCSPrice } from '../../libs/Api'
import { returnLocalize, KeepSecimals, balanceFormat, hideStr, timeFormat, KeepDecimals } from '../../libs/Util'
import config from '../../config/config'
import { lang } from '../../i18n/lang/lang'
import i18next from 'i18next'

import store from '../../stores'
const Store = store.store

export default class Dividends extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoadingApproved: false,
      isLoadingStake: false,
      isSelectHero: false,
      isDrop: false,
      isMaskChooseCaptain: false,
      isMaskRules: false,
      countdownTime: {
        d: '00',
        h: '00',
        m: '00',
        s: '00'
      },
      dropDownList: [],
      teamList: [],
      selectOption: null,
      totalInitReward: 0,
      startTime: 0,
      periodFinish: 0,
      cueCycleRewardConfigDisplay: 0,
      isStart: false,
      isShowWeakHintMain: false,
      totalTVL: 0,
      cocosPrice: 0
    }
    this.t = lang
    let networkType = Store.getStore('global', 'networkType') || 'bnb'
    this.config = config[networkType]
    this.account = Store.getStore('global', 'account')
    this.nftType = 'v1'
    this.cocosDecimals = 18
    this.timer = null
  }

  async startAndEnd() {
    return new Promise(async (resolve) => {
      // let startTime = await Store.getDividentStartTime()
      let startTime = await Store.getCurCycleStartTime()
      let periodFinish = await Store.getDividentPeriodFinish()
      this.setState({
        startTime: startTime,
        periodFinish: periodFinish
      })
      resolve({
        startT: startTime,
        finishT: periodFinish
      })
    })
  }

  async setApprovedForAll() {
    let _this = this
    this.setState({ isLoadingApproved: true })
    await Store.setApprovedForAll({
      nftType: _this.nftType,
      apprAddrKey: 'Dividends'
    })
    this.setState({ isLoadingApproved: false })
    this.getApprovedForAll()
  }

  async getApprovedForAll() {
    let _this = this
    this.setState({ isMask: true })
    let isApproved = await Store.getApprovedForAll({
      nftType: _this.nftType,
      apprAddrKey: 'Dividends'
    })
    this.setState({
      isApproved: isApproved
    })
  }
  getMyHerosNft() {
    let _this = this
    Store.getTokensOfOwner(_this.nftType).then(async (msy) => {
      if (msy.length === 0) {
        this.setState({
          isHttpMask: false,
          dropDownList: []
        })
      } else {
        let herosList = []
        for (let i = 0; i < msy.length; i++) {
          let nftId = msy[i]
          let isHero = await Store.isHeros(nftId)
          if (isHero) {
            herosList.push({
              id: nftId,
              isChoose: false
            })
          }
        }

        _this.setState({ dropDownList: herosList })
        if (herosList.length > 0) {
          for (let i = 0; i < herosList.length; i++) {
            let nftDetailsUri = await Store.getTokenURI({
              nftVer: _this.nftType,
              nftId: herosList[i].id
            })
            let heroDetail = await getNftTokenDetails(nftDetailsUri)
            // if (heroDetail.status !== 10001) {
            herosList[i] = heroDetail
            // }
          }
        }
        _this.setState({ dropDownList: herosList })
      }
    })
  }

  chooseTeamNft(e, id) {
    if (e) {
      e.stopPropagation()
    }
    this.setState({ isSelectHero: true })
    let dropDownList = this.state.dropDownList
    dropDownList.forEach((item) => {
      if (item.id === id) {
        item.isChoose = true
      } else {
        item.isChoose = false
      }
    })
    let selectOption = dropDownList.filter((item) => {
      return item.id === id
    })[0]
    this.setState({
      dropDownList: dropDownList,
      selectOption: selectOption,
      isSelectHero: true,
      isDrop: false
    })
  }

  getTeamInfoList() {
    let _this = this
    let teamList = _this.state.teamList

    Store.getTeamIndexes().then(async (heroIds) => {
      if (heroIds.length === 0) {
        _this.setState({ teamList: [] })
      } else {
        for (let i = 0; i < heroIds.length; i++) {
          let filterItem = teamList.filter((item) => {
            return String(item.id) === String(heroIds[i])
          })
          if (filterItem.length === 0) {
            teamList.push({ id: heroIds[i] })
          }
        }
        _this.setState({ teamList: teamList })

        for (let i = 0; i < teamList.length; i++) {
          let teamId = teamList[i].incomeDisplay

          if (!teamList[i].small_image) {
            let nftDetailsUri = await Store.getTokenURI({
              nftVer: _this.nftType,
              nftId: teamId
            })
            let teamGegoDetails = await getNftTokenDetails(nftDetailsUri)

            teamList[i] = Object.assign(teamList[i], teamGegoDetails)
          }
        }
        _this.setState({
          teamList: teamList
        })
      }
    })
  }
  getTeamList() {
    let _this = this
    let teamList = _this.state.teamList

    let _dividentStartTime = this.state.startTime
    let _dividendPeriodFinish = this.state.periodFinish
    Store.getTeamIndexes().then(async (heroIds) => {
      if (heroIds.length === 0) {
        _this.setState({ teamList: [] })
      } else {
        for (let i = 0; i < heroIds.length; i++) {
          let filterItem = teamList.filter((item) => {
            return String(item.id) === String(heroIds[i])
          })
          if (filterItem.length === 0) {
            teamList.push({ id: heroIds[i] })
          }
        }
        _this.setState({ teamList: teamList })
        let totalInitReward = 0
        let totalTVL = 0
        for (let i = 0; i < teamList.length; i++) {
          let teamId = teamList[i].id

          let teamDetailsData = await Store.getTeams(teamId)
          let totalReward = await Store.getTeamTotalInitReward(teamId)
          totalReward = KeepSecimals(balanceFormat(totalReward, this.cocosDecimals), 0)
          let teamDetails = Object.assign(teamList[i], teamDetailsData)
          // let teamDetails = teamList[i]
          teamDetails.totalPowerDisplay = KeepSecimals(balanceFormat(teamDetails.totalPower, this.cocosDecimals), 3)
          teamDetails.totalReward = totalReward
          teamDetails.rewardRateDiplay = KeepSecimals(balanceFormat(teamDetails.rewardRate, this.cocosDecimals), 7)
          let initRewardDisplay = KeepSecimals(balanceFormat(teamDetails.initReward, this.cocosDecimals), 0)

          let curTimestamp = String(new Date().getTime())
          curTimestamp = String(curTimestamp).slice(0, 10)
          let lastTime =
            Number(_dividendPeriodFinish) > Number(curTimestamp) ? Number(curTimestamp) : Number(_dividendPeriodFinish)
          let curPeriodFinish = Number(lastTime) - Number(_dividentStartTime)
          curPeriodFinish = curPeriodFinish < 0 ? 0 : curPeriodFinish
          teamDetails.Dividend =
            totalReward - Number(initRewardDisplay) + Number(teamDetails.rewardRateDiplay) * curPeriodFinish
          teamDetails.Dividend = KeepSecimals(String(teamDetails.Dividend), 3)
          totalInitReward += Number(teamDetails.Dividend)
          teamDetails.totalSupply =
            String(teamDetails.totalSupply) === '0'
              ? 0
              : KeepSecimals(balanceFormat(teamDetails.totalSupply, this.cocosDecimals), 5)
          let denominator =
            (Number(teamDetails.totalPowerDisplay) - Number(teamDetails.totalSupply)) / 1.5 +
            Number(teamDetails.totalSupply)
          teamDetails.tvl = 0
          let cocosPrice = 0
          if (this.state.cocosPrice) {
            cocosPrice = this.state.cocosPrice
          } else {
            cocosPrice = await getKCSPrice('COCOS')
            cocosPrice = cocosPrice.result
            _this.setState({ cocosPrice: cocosPrice })
          }
          cocosPrice = Number(KeepDecimals(String(cocosPrice), 3))
          if (String(teamDetails.captainStatus) === '1') {
            teamDetails.tvl =
              (Number(teamDetails.totalPowerDisplay) - Number(teamDetails.totalSupply)) / 1.1 +
              Number(teamDetails.totalSupply) +
              50000
          } else {
            teamDetails.tvl =
              (Number(teamDetails.totalPowerDisplay) - Number(teamDetails.totalSupply)) / 1.1 +
              Number(teamDetails.totalSupply)
          }
          teamDetails.tvl = Number(KeepDecimals(String(teamDetails.tvl), 0)) * cocosPrice
          totalTVL += Number(teamDetails.tvl)
          let APY =
            String(denominator) === '0' ? '0' : (Number(teamDetails.rewardRateDiplay) * 24 * 3600 * 365) / denominator
          teamDetails.APY = KeepSecimals(String(Number(KeepSecimals(String(APY), 3)) * 100), 0) + '%'
          let income = 0
          if (Number(teamDetails.totalPowerDisplay) === 0) {
            income = String(Number(initRewardDisplay))
          } else {
            income = (Number(teamDetails.rewardRateDiplay) / Number(teamDetails.totalPowerDisplay)) * 3600 * 24
          }
          let incomeDisplay = income ? KeepSecimals(String(income), 6) : '0'
          teamDetails.incomeDisplay = incomeDisplay
          let res = await Store.getAccPowerBalances({
            poolType: 'DividendsTeam',
            heroId: teamId
          })

          let accPowerBalancesDisplay = KeepSecimals(balanceFormat(res, _this.cocosDecimals), 3)
          teamDetails.accPowerBalancesDisplay = accPowerBalancesDisplay

          teamList[i] = teamDetails
        }
        totalInitReward = KeepSecimals(String(totalInitReward), 0)
        totalTVL = KeepDecimals(String(totalTVL), 0)
        _this.setState({
          teamList: teamList,
          totalInitReward: totalInitReward,
          totalTVL: totalTVL
        })
      }
    })
  }

  async stakeHero() {
    let _this = this

    if (this.state.isStart) {
    }
    if (this.state.isLoadingStake) return
    this.setState({
      isSelectHero: true
    })
    if (!_this.state.selectOption) {
      this.setState({ isSelectHero: false })
      return false
    }
    this.setState({
      isLoadingStake: true
    })
    let result = await Store.stakeHero({ teamId: _this.state.selectOption.id })
    if (!result) {
      _this.setState({
        isLoadingStake: false
      })
    }
    _this.setState({
      isMaskChooseCaptain: false,
      createTeamIsClose: false,
      isLoadingStake: false
    })
    // _this.getTeamList()
  }
  async businessFn() {
    let _this = this
    // _this.getMyHerosNft()
    _this.getTeamList()
  }

  getCueCycleRewardConfig() {
    let _this = this
    Store.getCueCycleRewardConfig().then((res) => {
      let cueCycleRewardConfig = Number(KeepSecimals(balanceFormat(res, _this.cocosDecimals), 3))
      _this.setState({ cueCycleRewardConfigDisplay: cueCycleRewardConfig })
    })
  }

  async initDate() {
    let _this = this
    let startTime = this.state.startTime
    let periodFinish = this.state.periodFinish

    _this.getApprovedForAll()
    if (!startTime) {
      let { startT, finishT } = await this.startAndEnd()
      startTime = startT
      periodFinish = finishT
    }
    let cutTimeStamp = new Date().getTime()
    cutTimeStamp = Number(String(cutTimeStamp).slice(0, 10))
    if (Number(startTime) > Number(cutTimeStamp)) {
      _this.setState({ isStart: false })
      _this.getCueCycleRewardConfig()
    } else if (Number(startTime) < Number(cutTimeStamp) && Number(cutTimeStamp) < Number(periodFinish)) {
      _this.setState({ isStart: true })
      let countdownDate = periodFinish - cutTimeStamp
      let countdownTime = timeFormat(countdownDate)
      this.setState({ countdownTime: countdownTime })
      this.businessFn()
    } else {
      _this.setState({ isStart: false })
      _this.getCueCycleRewardConfig()
      _this.businessFn()
    }
  }

  enterDetails(nftId) {
    this.props.history.push(`/dividendsdetails/${nftId}`)
  }

  closeSendMask() {
    this.setState({ isMask: false })
  }

  componentDidMount() {
    let _this = this
    if (this.account === '') return
    clearTimeout(_this.timer)

    _this.getCueCycleRewardConfig()
    _this.getTeamInfoList()

    function tickFn() {
      _this.initDate()
      _this.timer = setTimeout((res) => {
        tickFn()
      }, 3000)
    }
    tickFn()
  }

  async listenWallet() {
    let _this = this
    this.account = Store.getStore('global', 'account')
    let networkType = Store.getStore('global', 'networkType')
    this.config = config[networkType]
    clearTimeout(_this.timer)
    _this.getCueCycleRewardConfig()
    _this.getTeamInfoList()
    _this.setState({
      isLoadingApproved: false,
      isLoadingStake: false,
      isSelectHero: false,
      isDrop: false,
      isMaskChooseCaptain: false,
      isMaskRules: false
    })
    // _this.initDate()
    function tickFn() {
      _this.initDate()
      _this.timer = setTimeout((res) => {
        tickFn()
      }, 10000)
    }
    tickFn()
  }

  listenLanguage(val) {
    this.setState({ lang: i18next.language })
  }

  showChooseCaptain() {
    this.getMyHerosNft()
    this.setState({ isMaskChooseCaptain: true })
  }

  changeDropList() {
    let isDrop = !this.state.isDrop
    this.setState({ isDrop: isDrop })
  }
  showMaskRules() {
    this.setState({ isMaskRules: true })
  }
  closeMaskRules() {
    this.setState({ isMaskRules: false })
  }
  closeChooseCaptainMain() {
    this.setState({ isMaskChooseCaptain: false })
  }
  componentWillUnmount() {
    let _this = this
    clearTimeout(_this.timer)
    this.setState = (state, callback) => {
      return
    }
  }
  // showWeakHintMain() {
  //     let _this = this
  //     if (this.state.isShowWeakHintMain) return
  //     this.setState({isShowWeakHintMain: true})
  //     setTimeout(() => {
  //         _this.setState({isShowWeakHintMain: false})
  //     }, 3000)
  // }

  render() {
    return (
      <div className="dividends-bg">
        <img
          alt=""
          src={ruleBtn}
          className="rule-btn"
          onClick={() => {
            this.showMaskRules()
          }}
        />
        {this.state.isMaskRules ? (
          <div className="mask">
            <div className="Dividends-Rewarding-Rules-mask">
              <img
                alt=""
                src={close}
                className="close-btn "
                onClick={() => {
                  this.closeMaskRules()
                }}
              />
              <p className="title">{this.t[i18next.language].dividends['20']}</p>
              <p className="con mart20">1) {this.t[i18next.language].dividends.rule['0']}</p>
              <p className="con mart15">2) {this.t[i18next.language].dividends.rule['1']}</p>
              <p className="con mart15 text-indent2"> {this.t[i18next.language].dividends.rule['2']}</p>
              <p className="con mart15 text-indent2"> {this.t[i18next.language].dividends.rule['3']}</p>
              <p className="con mart15">3) {this.t[i18next.language].dividends.rule['4']}</p>
              <p className="con mart15">4) {this.t[i18next.language].dividends.rule['5']}</p>
              <p className="con mart15">5) {this.t[i18next.language].dividends.rule['6']}</p>
              <p className="con mart15">6) {this.t[i18next.language].dividends.rule['7']}</p>
              <p className="con mart15">7) {this.t[i18next.language].dividends.rule['8']}</p>
            </div>
          </div>
        ) : (
          ''
        )}

        {this.state.isMaskChooseCaptain ? (
          <div className="mask">
            <div className="choose-captain-mask">
              <img
                alt=""
                src={close}
                className="close-btn"
                onClick={() => {
                  this.closeChooseCaptainMain()
                }}
              />
              <div className="choose-captain-container">
                <div className="captain-nft-icon">
                  <img alt="" src={defaultIcon} />
                </div>
                <div className="choose-operation-main">
                  <p className="choose-operation-main-title mart41">{this.t[i18next.language].common['34']} NFT: </p>
                  <div
                    className={this.state.isSelectHero ? 'drop-down-container' : 'border-red drop-down-container'}
                    onClick={() => {
                      this.changeDropList()
                    }}
                  >
                    {this.state.selectOption
                      ? returnLocalize(this.state.selectOption.i18n_name, i18next.language)
                      : `${this.t[i18next.language].common['34']} NFT`}

                    {this.state.isDrop ? (
                      <div className="drop-down-list">
                        {this.state.dropDownList.map((item, key) => {
                          return (
                            <ul
                              className="drop-down-li"
                              key={key}
                              onClick={(e) => {
                                this.chooseTeamNft(e, item.id)
                              }}
                            >
                              <ul className="nft-icon">
                                {item.small_image ? (
                                  <img alt="" src={item.small_image} />
                                ) : (
                                  <p className="loading-percentage"></p>
                                )}
                              </ul>
                              <p className="nft-name">{returnLocalize(item.i18n_name, i18next.language)}</p>
                              {item.isChoose ? <img alt="" className="select-icon" src={selectIcon} /> : ''}
                            </ul>
                          )
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <p className="choose-operation-main-title mart22">{this.t[i18next.language].dividends['6']}: </p>
                  <div className="drop-down-container">
                    {this.state.selectOption
                      ? returnLocalize(this.state.selectOption.i18n_name, i18next.language)
                      : this.t[i18next.language].dividends['6']}
                  </div>
                </div>
              </div>

              <div className="team-creation-rules-container">
                <p className="team-creation-rules-title">{this.t[i18next.language].dividends['7']}</p>
                <p className="team-creation-rules-content">{this.t[i18next.language].dividends.teamrRule['0']}</p>
                <p className="team-creation-rules-content">{this.t[i18next.language].dividends.teamrRule['1']}</p>
              </div>
              {this.state.isApproved ? (
                <ul
                  className="team-creation-rules-btn"
                  onClick={() => {
                    this.stakeHero()
                  }}
                >
                  {this.state.isLoadingStake ? <p className="loading"></p> : this.t[i18next.language].dividends['8']}
                </ul>
              ) : (
                <ul
                  className="team-creation-rules-btn"
                  onClick={() => {
                    this.setApprovedForAll()
                  }}
                >
                  {this.state.isLoadingApproved ? <p className="loading"></p> : this.t[i18next.language].common['18']}
                </ul>
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        <TopCompontent
          props={this.props}
          listenLanguage={this.listenLanguage.bind(this)}
          listenWallet={this.listenWallet.bind(this)}
        ></TopCompontent>
        <div className="dividends-component">
          <div className="banner">
            <p className="dividends-title">End</p>
            <div className="dividends-total-info-main">
              <div className="total-dividends-info line">
                <img alt="" className="total-dividends-icon left-icon" src={icon1} />
                <ul className="dividends-info-main">
                  <p className="dividends-info-title">TVL</p>
                  <p className="dividends-info-con">$ {this.state.totalTVL} </p>
                </ul>
              </div>
              <div className="total-dividends-info line">
                <img alt="" className="total-dividends-icon center-icon" src={icon2} />
                <ul className="dividends-info-main">
                  <p className="dividends-info-title">{this.t[i18next.language].dividends['24']}</p>
                  <p className="dividends-info-con">
                    {this.state.totalInitReward} <span className="unit">COCOS</span>{' '}
                  </p>
                </ul>
              </div>
              <div className="total-dividends-info">
                <img alt="" className="total-dividends-icon right-icon" src={icon3} />
                <ul className="dividends-info-main">
                  <p className="dividends-info-title">{this.t[i18next.language].dividends['23']}</p>
                  <p className="dividends-info-con">
                    {this.state.cueCycleRewardConfigDisplay} <span className="unit">COCOS</span>{' '}
                  </p>
                </ul>
              </div>
            </div>
          </div>

          <div className="dividends-team-container">
            <div className="dividends-team-main create-team-main">
              <img
                alt=""
                className="create-icon"
                src={createIcon}
                onClick={() => {
                  this.showChooseCaptain()
                }}
              />
              <p
                className="create-con"
                onClick={() => {
                  this.showChooseCaptain()
                }}
              >
                {this.t[i18next.language].common['35']}
              </p>
            </div>
            {this.state.teamList.map((item, key) => {
              return (
                <div className="dividends-team-main" key={key}>
                  <img
                    alt=""
                    src={item.small_image}
                    className={item.incomeDisplay === '0' ? 'nft-portrait card-gary' : 'nft-portrait'}
                  />
                  <p className="nft-name">{item.i18n_name ? returnLocalize(item.i18n_name, i18next.language) : ''}</p>
                  <div className="attribute-group">
                    <p className="attribute-key">{this.t[i18next.language].dividends['2']}: </p>
                    <p className="attribute-value-1">
                      {String(item.captainStatus) === '1' ? hideStr(item.captain, 4, 3) : ''}
                      {String(item.captainStatus) === '2' ? ' ---' : ''}
                    </p>
                  </div>
                  {/* <div className="attribute-group">
                                  <p className="attribute-key">APY: </p>
                                  <p className="attribute-value-1">{item.APY?item.APY:0}</p>
                              </div> */}
                  <div className="attribute-group">
                    <p className="attribute-key">{this.t[i18next.language].dividends['3']}: </p>
                    <p className="attribute-value-1">
                      {item.totalPowerDisplay} {this.t[i18next.language].common['40']}
                    </p>
                  </div>
                  <div className="attribute-group">
                    <p className="attribute-key">{this.t[i18next.language].dividends['4']}: </p>
                    <p className="attribute-value-1">{item.Dividend} COCOS</p>
                  </div>
                  <div className="attribute-group">
                    <p className="attribute-key">{this.t[i18next.language].dividends['5']}: </p>
                    <p className="attribute-value-1">{item.incomeDisplay} COCOS</p>
                  </div>
                  <div className="attribute-group">
                    <p className="attribute-key">My Power: </p>
                    <p className="attribute-value-1">{item.accPowerBalancesDisplay} POWER</p>
                  </div>
                  <ul
                    className="join-btn"
                    onClick={() => {
                      this.enterDetails(item.id)
                    }}
                  >
                    {this.t[i18next.language].common['30']}
                  </ul>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
