import erc20 from "./abi/erc20";
import playerBook from "./abi/playerBook";
// import CocosNFTClaim from "./mainAbi/CocosNFTClaim.json"
// import CocosNFT from "./mainAbi/nft_v1/CocosNFT.json"
// import CocosNFTFactory from "./mainAbi/nft_v2/CocosNFTFactory.json"

const config = {
  "name": "Etherscan",
  "defaultWalletToken": "eth",
  "etherscanSite":"https://etherscan.io",
  "nodeUri": "https://mainnet.infura.io/v3/9cf149c39e364cdabc3d5129fa7fa1af",
  
  "common":{
    "playerBookABI": playerBook,
    "playerBookAddress": "0x63062B5f2CC762632684734574BAff42d3F756CA",
  },
  
  "blindbox": {
    "address": "0x90569fE78047886319C6beA439546f00AAD03108",
    "ABI": ""
  },
  
  "v1": {
    "nft": {
      "address": "",
      "ABI": ""
    },
    "factory": {
      "address": "",
      "ABI": ""
    }
  },
  "cocos": {
    "address": "0xD2b593EA64f6733E564319731367e374072d7d15",
    "decimals": 18,
    "ABI": erc20
  },
  "eth": {
    "decimals": 18
  }
};
  
export default config;