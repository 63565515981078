import React from 'react';
// import Bus from '../../libs/eventBus'

import Referral_n from '../../static/images/home/Referral_n.png'
import Referral_h from '../../static/images/home/Referral_h.png'

import team_n from '../../static/images/home/team_n.png'
import team_h from '../../static/images/home/team_h.png'

import nftcast_n from '../../static/images/home/nftcast_n.png'
import nftcast_h from '../../static/images/home/nftcast_h.png'

import Stake_n from '../../static/images/home/Stake_n.png'
import Stake_h from '../../static/images/home/Stake_h.png'

import Governance_n from '../../static/images/home/Governance_n.png'
import Governance_h from '../../static/images/home/Governance_h.png'


import NFTcast from '../../static/images/home/NFTcast.png'
import NFTcastEn from '../../static/images/home/NFTcast-en.png'
import TeamDividend from '../../static/images/home/TeamDividend.png'
import TeamDividendEn from '../../static/images/home/TeamDividend-en.png'
import Stake from '../../static/images/home/Stake.png'
import StakeEn from '../../static/images/home/Stake-en.png'
import Governance from '../../static/images/home/Governance.png'
import GovernanceEn from '../../static/images/home/Governance-en.png'
import Referral from '../../static/images/home/Referral.png'
import ReferralEn from '../../static/images/home/Referral-en.png'

import home from '../../static/images/home/home.png'
import mynft from '../../static/images/home/mynft.png'
import Wiki from '../../static/images/home/Wiki.png'

import Blindbox from '../../static/images/home/Blindbox.png'
import EmailSubscript from '../../static/images/home/EmailSubscript.png'

import TopCompontent from '../base/TopCompontent/TopCompontent'
import i18next from 'i18next'

// import { RouterConf } from "../../Router/router.conf"
import { reRouterPath } from '../../libs/Util'

import './home.css'



import { lang } from "../../i18n/lang/lang"

import store from '../../stores/store'
const Store = store.store


export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WalletLinkConnectorIsClose: false,
            referralIsClose: false,
            isShowWeakHintMain: false,
            address: ''
        }
        this.address=''
        this.networkType = ''
        this.comingSoonTit = ''
        // this.curLang = getCurLanguage()
        // this.t = lang[this.curLang]
        this.t = lang
    }


    componentDidMount () {
        window.location.href = `https://www.cocosbcx.io/`
    }



    componentWillUnmount () {

        this.setState = (state, callback) => {
            return;
        };
    }

    routerJump(routerName){
        let networkType = Store.getStore('global', 'networkType')
        let curRouterPath = reRouterPath(routerName, networkType)
        if (curRouterPath) {
            this.props.history.push(curRouterPath)
        } else {
            this.showWeakHintMain()
        }
    }

    linkJump(){
        window.open(this.t[i18next.language].Referral["35"])
    }

    showWeakHintMain() {
        let _this = this
        if (this.state.isShowWeakHintMain) return
        this.setState({isShowWeakHintMain: true})
        setTimeout(() => {
            _this.setState({isShowWeakHintMain: false})
        }, 3000)
    }
    
    listenWallet(val){
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }
    render() {
        return (
            <div className="home-component">
            <TopCompontent listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet}></TopCompontent>
    
                {
                    this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.t[i18next.language].common['2']}</div>:''
                }

                <div className="right-nav blind-box-nav" onClick={()=>{
                    this.routerJump('BlindBox')
                    // this.showWeakHintMain() 
                }}>
                    <img alt="" src={Blindbox} className="right-nav-icon" />
                    <p className="right-nav-text">{this.t[i18next.language].BlindBox['3']}</p>
                </div>
                <div className="right-nav email-nav" onClick={()=>{
                    this.showWeakHintMain()
                }}>
                    <img alt="" src={EmailSubscript} className="right-nav-icon" />
                    <p className="right-nav-text">{this.t[i18next.language].BlindBox['4']}</p>
                </div>

            <div className="home-main-component">
                <div className="nft-entr map-compontents" onClick={()=>{
                    this.routerJump('Casting')
                    // this.showWeakHintMain()
                }}>
                    <img alt="" src={nftcast_n} className="bg" />
                    <img alt="" src={nftcast_h} className="bg-select" />
                    {
                        i18next.language === 'cn'?<img alt="" src={NFTcast} className="map-entr-main"/>:<img alt="" src={NFTcastEn} className="map-entr-main"/>
                    }
                    
                </div>
                <div className="dividents-1-entr map-compontents" onClick={()=>{
                    this.routerJump('Dividends')
                    // this.showWeakHintMain()
                }}>
                    <img alt="" src={team_n} className="bg" />
                    <img alt="" src={team_h} className="bg-select" />
                    <ul className="map-entr-main">
                        {
                            i18next.language === 'cn'?<img alt="" src={TeamDividend} />:<img alt="" src={TeamDividendEn} />
                        }
                    </ul>
                </div>
                <div className="mining-entr map-compontents" onClick={()=>{
                    this.routerJump('Mining')
                    // this.showWeakHintMain()
                }}>
                    <img alt="" src={Stake_n} className="bg" />
                    <img alt="" src={Stake_h} className="bg-select" />
                    <ul className="map-entr-main">
                        {i18next.language === 'cn'?<img alt="" src={Stake} />:<img alt="" className="stake-entr-en" src={StakeEn} />}
                    </ul>
                </div>
                <div className="govern-entr map-compontents" onClick={()=>{
                    this.showWeakHintMain()
                }}>
                    <img alt="" src={Governance_n} className="bg" />
                    <img alt="" src={Governance_h} className="bg-select" />
                    <ul className="map-entr-main">
                        {
                            i18next.language === 'cn'?<img alt="" src={Governance} />:<img alt="" className="govern-entr-en" src={GovernanceEn} />
                        }
                        
                    </ul>
                </div>
                <div className="promoter-entr map-compontents" onClick={()=>{
                    this.routerJump('Referral')
                    // this.showWeakHintMain()
                }}>
                    <img alt="" src={Referral_n} className="bg" />
                    <img alt="" src={Referral_h} className="bg-select" />
                    <ul className="map-entr-main">
                        {
                            i18next.language === 'cn'?<img alt="" src={Referral} />:<img alt="" className="promoter-entr-en" src={ReferralEn} />
                        }
                    </ul>
                </div>
            </div>

            <div className="bottom-nav">
                <div className="nav-li">
                    <img alt="" src={home} className="nav-icon" />
                    <p className="nav-text">{this.t[i18next.language].home['0']}</p>
                </div>
                <div className="nav-li" onClick={()=>{
                    this.routerJump('MyNft')
                    // this.showWeakHintMain()
                }}>
                    <img alt="" src={mynft} className="nav-icon" />
                    <p className="nav-text">{this.t[i18next.language].home['1']}</p>
                </div>
                <div className="nav-li" onClick={()=>{
                    this.linkJump()
                }}>
                    <img alt="" src={Wiki} className="nav-icon" />
                    <p className="nav-text">{this.t[i18next.language].home['2']}</p>
                </div>
            </div>
        </div>
        )
    }
}