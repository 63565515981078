
import dedicatedNot from '../static/images/fivefu/fivefu/dedicated-not.png'
import patriotismNot from '../static/images/fivefu/fivefu/patriotism-not.png'
import richNot from '../static/images/fivefu/fivefu/rich-not.png'
import harmoniousNot from '../static/images/fivefu/fivefu/harmonious-not.png'
import friendlyNot from '../static/images/fivefu/fivefu/friendly-not.png'
import mining0 from '../static/images/mining/mining-0.png'
import mining1 from '../static/images/mining/mining-1.png'

export const fivefu = {
    "ruleId": ["502", "404", "35", "1024", "996"],
    // "ruleId": ["12", "13", "14", "15", "16"],
    "imgOwn": [
        "https://finance.cocosbcx.io//images/big/Harmony.png",
        "https://finance.cocosbcx.io/images/big/Patriotism.png",
        "https://finance.cocosbcx.io/images/big/Friendliness.png",
        "https://finance.cocosbcx.io/images/big/Prosperity.png",
        "https://finance.cocosbcx.io/images/big/Dedication.png"
    ],
    "imgOwnNo": [harmoniousNot, patriotismNot, friendlyNot, richNot, dedicatedNot]
}

export const miningPoorOrder = ["1", "0"]

export const miningDetails = {
    "0": {
        "stakeNft": ["v1"],
        "rewardsToken": "cocos",
        "bgColor": "d0223b",
        "pic": mining0,
        "decimals": 18,
        "isEnd": true
    },
    "1": {
        "stakeNft": ["v1"],
        "rewardsToken": "cocos",
        "bgColor": "fbeb9d",
        "pic": mining1,
        "isEnd": true,
        "stakeRule": [
            {
                "nftType": "1",
                "ruleId": "2021"
            },
            {
                "nftType": "1",
                "ruleId": "502"
            },
            {
                "nftType": "1",
                "ruleId": "404"
            },
            {
                "nftType": "1",
                "ruleId": "35"
            },
            {
                "nftType": "1",
                "ruleId": "1024"
            },
            {
                "nftType": "1",
                "ruleId": "996"
            },
            {
                "nftType": "3",
                "ruleId": "1"
            },
        ],
        "decimals": 18
    }
}
