import React, { Component } from 'react';
import Routermy from './Router/router'
import { I18nextProvider } from 'react-i18next'
import { getLocalizeCsv } from './libs/Api'
import i18next from 'i18next'
import './App.css';





i18next.init({    
  lng: 'en', 
  fallbackLng: 'en',
  resources: {
      en: { translation: {} },
      cn: { translation: {} }
  }
});


class App extends Component {
  constructor() {
      super();
      this.state = {
      }
  }
  componentDidMount() {
    console.log('update2023')
    let initTimeStamp = new Date().getTime()
    localStorage.setItem('initTimeStamp', initTimeStamp)
    getLocalizeCsv()
  }
  render() {
    if (window.location.hash) {
      let parentRefName = window.location.hash.replace('#', '')
      localStorage.setItem('parentRefName', parentRefName)
    }
    return (
      <div className="App">
      <I18nextProvider i18n={i18next}>
        <Routermy />
      </I18nextProvider>
      </div>
    );
  }
}

export default App;
