import React from 'react';
import './Loading.css'

export default class Loading extends React.Component {
    constructor(props) {
        super();
        this.state = {
        }
    }
    render() {
        // this.setState({warningCon: this.props.warningCon})
        return (
            <div className="common-compontent-loading">
               <div className="loading-base"></div>
            </div>
        )
    }
}