import React from 'react';
import './MyNft.css'
import Loading from "../base/Loading/Loading"
import { balanceFormat, KeepDecimals, returnLocalize } from "../../libs/Util"
import i18next from 'i18next'
import { BinanceChainID } from "../../config/tokenConfig"
import { getNftTokenDetails } from "../../libs/Api"
import TopCompontent from '../base/TopCompontent/TopCompontent'
import { lang } from "../../i18n/lang/lang"
import store from "../../stores";
import config from "../../config/config"
import emptyList from "../../static/images/common/emptyList.png"
const Store = store.store

export default class MyNft extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myNftList: [],

            cocosBalance: 0,
            ethBalance: 0
        }
        this.t = lang
        let web3context = Store.getStore('global' ,'web3context')
        if (web3context) {
            let networkType = BinanceChainID[web3context.chainId]
            this.config = config[networkType]
        } else {
            this.config = config
        }
        this.account = Store.getStore('global','account')
        this.nftVer = "v1"
        this.cocosDecimals = config.main.cocos.decimals
        this.ethDecimals = config.main.eth.decimals
        this.defaultWalletToken = ''
        this.localize  = Store.getStore("global", "localize")
    }

    async initDate(){
        let _this = this
        this.getBalance()
        let myNftList = []
        this.account = Store.getStore('global','account')
        if (this.account === '') return
        // let networkType = Store.getStore("global", "networkType")
        let web3context = Store.getStore('global' ,'web3context')
        let networkType = BinanceChainID[web3context.chainId]
        this.config = config[networkType]
        this.defaultWalletToken = this.config.defaultWalletToken.toUpperCase()
        Store.getTokensOfOwner(_this.nftVer).then(async res=> {
            if (!res) return
            let ids = res
            ids.forEach(element => {
                myNftList.push({id: element})
            });
            _this.setState({myNftList: myNftList},async ()=>{
                for (let i = 0; i < myNftList.length; i++) {
                    let nftId = myNftList[i].id
                    let nftDetailsUri = await Store.getTokenURI({
                        nftVer: _this.nftVer,
                        nftId: nftId
                    })
                    let nftDetails = await getNftTokenDetails(nftDetailsUri)
                    myNftList[i] = Object.assign(nftDetails, myNftList[i])
                    let erc20 = myNftList[i].attributes.erc20
                    let curNftToken = null
                    let unit = ''
                    for (const key in _this.config) {
                        if (_this.config[key].hasOwnProperty('address')) {
                            if (_this.config[key].address.toLowerCase() === erc20.toLowerCase()) {
                                curNftToken = _this.config[key]
                                unit = key
                                break;
                            }
                        }
                    }
                    let amount = myNftList[i].attributes.amount
                    myNftList[i].amountDisplay = KeepDecimals(balanceFormat(amount, curNftToken.decimals), 3)
                    myNftList[i].unit = unit
                    _this.setState({myNftList: myNftList})
                }
                
            })
            
        })
    }

    componentDidMount () {
        if (this.account === '' || !this.account) return
        this.initDate()
    }

    async listenWallet(){
        this.initDate()
    }


    componentWillUnmount () {
        this.setState = (state, callback) => {
            return;
        };
    }

    getBalance(){
        Store.getCommonBalance('cocos').then(res => {
          if (res) {
            let balance = res
            balance = KeepDecimals(balanceFormat(balance, this.cocosDecimals), 3)
            this.setState({cocosBalance: balance})
          }
        })
        Store.getWalletBalance().then(res => {
            if (res) {
              let balance = res
              balance = KeepDecimals(balanceFormat(balance, this.ethDecimals), 3)
              this.setState({ethBalance: balance})
            }
            
        })
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }

    async routerDetails(nftId){
        this.props.history.push(`/mynftdetails/${nftId}`)
    }
    render() {
        return (
            <div className="bg-component">
            {
                this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.t[i18next.language].common['3']}</div>:''
            }
            <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>
                <div className="container-main">

                    <div className="mynft-info-container">
                        <div className="mynft-info-main">
                            <ul className="tit">{this.t[i18next.language].common['5']} ({this.defaultWalletToken}):</ul>
                            <ul className="con">{this.state.ethBalance}</ul>
                        </div>
                        <div className="mynft-info-main">
                            <ul className="tit">{this.t[i18next.language].common['5']} (COCOS):</ul>
                            <ul className="con">{this.state.cocosBalance}</ul>
                        </div>
                    </div>

                    <div className="mynft-list-container">
                        {
                            this.state.myNftList.length === 0?
                            <div className="empty-list">
                                <img alt="" src={emptyList} className="empty-list-icon" />
                                <p className="empty-list-text">{this.t[i18next.language].common['16']}</p>
                            </div>
                            :
                            this.state.myNftList.map((item, key)=>{
                                return  item.small_image?<div className="mynft-list-main" key={key} onClick={() => {
                                    this.routerDetails(item.id)
                                }}>
                                    <ul className="nft-pic">
                                        <img alt="" src={item.small_image} />
                                    </ul>
                                    <div className="mynft-attr-main">
                                        <p className="title">{returnLocalize(item.i18n_name,i18next.language)}</p>
                                        <p className="attr mart18">ID: {item.id}</p>
                                        <p className="attr mart10">{this.t[i18next.language].common['6']}: {item.amountDisplay} {item.unit.toUpperCase()}</p>
                                    </div>
                                </div>:<div className="mynft-list-main" key={key}><Loading /></div>
                            })
                        }
                        
                        
                    </div>

                </div>
            </div>
        )
    }
}