import erc20 from "./abi/erc20";
import playerBook from "./abi/playerBook";
// import CocosNFTClaim from "./mainAbi/CocosNFTClaim.json"
import CocosNFT from "./mainAbi/nft_v1/CocosNFT.json"
import baseProxy from "./mainAbi/nft_v1/baseProxy.js"
import CocosFactory from "./mainAbi/nft_v1/CocosFactory.json"
import CocosFiveFuClaimOx from "./mainAbi/fivefu/CocosNFTClaimOx.json"
import CocosFiveFuFactory from "./mainAbi/fivefu/CocosNFTFactory.json"
import CocosCowNFTReward from "./mainAbi/mining/CocosCowNFTReward.json"
import CocosDividend from "./mainAbi/dividends/CocosDividend.json"
import CocosDividendTeam from "./mainAbi/dividends/CocosDividendTeam.json"

const config = {
  "name": "Bscscan",
  "defaultWalletToken": "bnb",
  "etherscanSite":"https://bscscan.com",
  "nodeUri": "https://bsc-dataseed1.defibit.io/",
  "newProUri": "https://cocosbcx.io",

  "common":{
    "playerBookABI": playerBook,
    "playerBookAddress": "0x63062B5f2CC762632684734574BAff42d3F756CA"
  },

  "fiveFu": {
    "factory": {
      "address": "0xf5a33E4079c025f88387240fc4de3757659c06a7",
      "ABI": CocosFiveFuFactory
    }
  },
  "blindbox": {
    "address": "0xeDe89aA1881E8FAD2D3E11585Aaa79A4381ff8DF",
    "ABI": CocosFiveFuClaimOx
  },
  "v1": {
    "nft": {
      "address": "0xEEdb8A865e2908dAA203A4b119B52805564E947A",
      "ABI": CocosNFT
    },
    "factory": {
      "address": "0xf5a33E4079c025f88387240fc4de3757659c06a7",
      "ABI": CocosFactory
    },
    "baseProxy": {
      "address": "0xf08a7274fD5FF3154Ffd2325cE5af976C30C366B",
      "ABI": baseProxy
    }
  },
  "mining": {
    "0": {
      "address": "0xaa8ADcCDb88eb29C58dE0d5f83A13ee15Ade2D69",
      "ABI": CocosCowNFTReward
    },
    "1": {
      "address": "0x7D93A7E7e2F4C46AEB73f208704C22DB21C4b3c0",
      "ABI": CocosCowNFTReward
    }
  },

  "Dividends": {
    "address": "0x5D1d5495626eA3FE196966e4f844cB4238423C1D",
    "ABI": CocosDividend
  },

  "DividendsTeam": {
    "address": "0x957f22A61E587Fdf1EA9094A0E19a9ABa66F0eBd",
    "ABI": CocosDividendTeam
  },

  "cocos": {
    "address": "0xab301dae71f5b386c566f484e636aee60318f12f",
    "decimals": 18,
    "ABI": erc20
  },
  "bnb": {
    "decimals": 18
  }
};
  
export default config;