import React from 'react';
import './DividendsDetails.css'
// import Loading from "../base/Loading/Loading"
// import testicon from "../../static/images/common/logined.svg"
// import icon1 from "../../static/images/Dividends/icon1.svg"
// import icon2 from "../../static/images/Dividends/icon2.svg"

import close from "../../static/images/common/close.svg"
import TopCompontent from '../base/TopCompontent/TopCompontent'
import { getNftTokenDetails } from '../../libs/Api'
import { returnLocalize, KeepSecimals, balanceFormat, showHtmlText, toPercent, balanceFormatAdd, KeepDecimals, timeFormat } from '../../libs/Util'
import config from '../../config/config'
import { lang } from "../../i18n/lang/lang"
import i18next from 'i18next'

import store from "../../stores";
const Store = store.store

export default class DividendsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dividendsDetails: null,
            isMaskStaking: false,
            isApprove: false,
            accPowerBalances: 0,
            accPowerBalancesDisplay: 0,
            promptText: 0,
            myEarned: 0,
            myEarnedInPuni: 0,
            isMaskStakingType: "",
            myNftList: [],
            accPlayerNft: [],
            maskStakingTab: 'nft',
            isCocosApprove: 0,
            isLoadingApproved: false,
            maskDisplayList: [],
            cocosBalance: 0,
            stakingBalance: 0,
            stakingBalanceDisplay: 0,
            isMask: false,
            isCaptain: false,
            accPlayerTotalPower: 0,
            isLoadingMyNft: false,
            isLoadingAccPlayer: false,
            dictionaries: {},
            isShowWeakHintMain: false,
            promptCon: "",
            punishDate: ""
        }
        this.t = lang
        let networkType = Store.getStore("global", "networkType") || "bnb"
        this.config = config[networkType]
        this.account = Store.getStore('global','account')
        this.nftType = 'v1'
        this.cocosDecimals = 18
        this.inputAmount = 0
        this.timer = null
        this.punishTimer = null
    }

    getBalance(){
        let _this = this
        Store.getCommonBalance('cocos').then(res => {
          if (res) {
            let balance = res
            balance = KeepDecimals(balanceFormat(balance, _this.cocosDecimals), 3)
            this.setState({cocosBalance: balance})
          }
        })
    }

    getAccPowerBalances(){
        let _this = this
        let { teamId } = this.props.match.params
        Store.getAccPowerBalances({
            poolType: "DividendsTeam",
            heroId: teamId
        }).then(res=>{
            _this.setState({accPowerBalances: res})
            let accPowerBalancesDisplay = KeepSecimals(balanceFormat(res, _this.cocosDecimals), 3)
            _this.setState({accPowerBalancesDisplay: accPowerBalancesDisplay})
        })
    }

    
    getDividendEarned () {
        let _this = this
        let { teamId } = this.props.match.params
        Store.getDividendEarned({
            poolType: "Dividends",
            heroId: teamId
        }).then(res => {
            let myEarned = res

            let playerBook = 0.1
            let punish = 0.1
            let teamRatio = 0.2
            myEarned = KeepSecimals(balanceFormat(myEarned, _this.cocosDecimals), 3)
            let earned_actual = Number(myEarned)*(1-playerBook)*(1-punish)*(1-teamRatio)
            earned_actual = KeepSecimals(String(earned_actual), 3)
            let earned_actual_puni = Number(earned_actual)*(1-punish)
            earned_actual_puni = KeepSecimals(String(earned_actual_puni), 3)

            let promptText = showHtmlText(_this.t[i18next.language].common["19"], earned_actual_puni)
            _this.setState({
                promptText: promptText,
                myEarned: earned_actual,
                myEarnedInPuni: earned_actual_puni
            })
            
        })
    }
    
    getAccTotalRewards(){
        let _this = this
        let { teamId } = this.props.match.params
        Store.getAccTotalRewards({
            poolType: "DividendsTeam",
            heroId: teamId
        }).then(res => {
            let accTotalRewards = res
            let accTotalRewardsDisplay = KeepSecimals(balanceFormat(accTotalRewards, _this.cocosDecimals), 3)
            accTotalRewardsDisplay = KeepSecimals(String(accTotalRewardsDisplay), 3)
            _this.setState({
                accTotalRewards: accTotalRewards,
                accTotalRewardsDisplay: accTotalRewardsDisplay
            })
        })
    }

    getAccBalances(){
        let _this = this
        let { teamId } = this.props.match.params
        Store.getAccBalances({
            poolType: "DividendsTeam",
            heroId: teamId
        }).then(res => {
            _this.setState({stakingBalance: res})
            let accountBalanceDisplay = KeepSecimals(balanceFormat(res, _this.cocosDecimals), 3)
            _this.setState({stakingBalanceDisplay: accountBalanceDisplay})
        })
    }
    async initDate(){
        let _this = this
        let unlock = 0
        let { teamId } = this.props.match.params
        this.getAccPowerBalances()
        this.getDividendEarned()
        this.getCocosAllowance()
        // this.getAccBalances()
        this.getAccTotalRewards()
        let totalReward = await Store.getTeamTotalInitReward(teamId)
        
        let teamTotalInitRewardDisplay = KeepSecimals(balanceFormat(totalReward, _this.cocosDecimals), 3)
        let teamNft = this.state.dividendsDetails
        if (!teamNft) {
            let nftDetailsUri = await Store.getTokenURI({
                nftVer: _this.nftType,
                nftId: teamId
            })
            teamNft = await getNftTokenDetails(nftDetailsUri)
            // teamNft = await gegoToken(teamId)
            // teamNft = teamNft.result.data
        }
        let result = await Store.getTeams(teamId)
        // let totalReward = await Store.getTeamTotalInitReward({poolType: 'DegoDividendTeam', heroId: _this.teamId})

        totalReward = KeepSecimals(balanceFormat(totalReward, this.cocosDecimals), 0)

        let _periodFinish = await Store.getBasePeriodFinish({poolType: "Dividends"})
        // let Duration = await Store.getBaseDuration({poolType: 'Dividends'})
        // let _dividentStartTime = Number(_periodFinish) - Number(Duration)
        let _dividentStartTime = await Store.getCurCycleStartTime()

        result = Object.assign(teamNft, result)
        if (String(result.captainStatus) === "1") {
            if (String(this.account).toLowerCase() === String(result.captain).toLowerCase()) {
                this.setState({
                    isCaptain: true
                })
            }
        } else if (String(result.captainStatus) === "2") {
            let myNft = await Store.getTokensOfOwner(_this.nftType)
            for (let i = 0; i < myNft.length; i++) {
                if (String(myNft[i]) === String(teamId)) {
                    this.setState({
                        isCaptain: true
                    })
                    continue
                }
            }
        }
        
        result.teamTotalInitRewardDisplay = teamTotalInitRewardDisplay
        
        result.totalPowerDisplay = KeepSecimals(balanceFormat(result.totalPower, this.cocosDecimals), 3)
        result.rewardRateDiplay = KeepSecimals(balanceFormat(result.rewardRate, this.cocosDecimals), 7)
        let initRewardDisplay = KeepSecimals(balanceFormat(result.initReward, this.cocosDecimals), 0)

        let income = 0
        if (Number(result.totalPowerDisplay) === 0) {
            income = Number(initRewardDisplay)
        } else {
            income = Number(result.rewardRateDiplay) / Number(result.totalPowerDisplay) * 3600 * 24
        }
        income = KeepSecimals(String(income), 5)
        result.incomeDisplay = income


        let curTimestamp = (new Date()).getTime()
        curTimestamp = String(curTimestamp).slice(0, 10)
        let lastTime = Number(_periodFinish) > Number(curTimestamp) ? Number(curTimestamp) : Number(_periodFinish)
        let curPeriodFinish = Number(lastTime) - Number(_dividentStartTime)
        curPeriodFinish = curPeriodFinish < 0 ? 0 : curPeriodFinish
        
        result.Dividend =
        totalReward - Number(initRewardDisplay) + (Number(result.rewardRateDiplay) * curPeriodFinish)
        result.Dividend = KeepSecimals(String(result.Dividend), 3)
        result.dividendDay = Number(result.rewardRateDiplay) * 3600 * 24
        result.dividendDayDisplay = KeepSecimals(String(result.dividendDay), 3)
        result.captainReward = KeepSecimals(balanceFormat(result.captainReward, _this.cocosDecimals), 3)
        result.captainHadRewardDisplay = KeepSecimals(balanceFormat(result.captainHadReward, _this.cocosDecimals), 3)
        unlock = Number((totalReward * (1 - 0.1))) * 0.2 - Number(result.captainHadRewardDisplay)
        unlock = KeepSecimals(String(unlock), 3)

        this.setState({
            unlock: unlock,
            dividendsDetails: result
        })
    } 

    closeSendMask(){
        clearTimeout(this.punishTimer)
        this.setState({isMask: false})
    }


    async openMaskStaking(val) {
        if (val ===  'staking' && String(this.state.dividendsDetails.captainStatus) === "2") return
        this.getAccPlayerGego()
        this.getAccBalances()
        if (val ===  'staking') {
            this.myNft()
            this.getBalance()
        }
        
        this.setState({
            isMaskStaking: true,
            isMaskStakingType: val
        })
    }

    closeMaskStaking() {
        this.setState({isMaskStaking: false})
    }
    
    componentDidMount () {
        let _this = this
        if (this.account === '') return
        this.getApprovedForAll()
        this.initDate()
        clearTimeout(_this.timer)
        function tickFn() {
            _this.initDate()
            _this.timer = setTimeout(()=>{
                tickFn()
            }, 3000)
        }
        tickFn()
    }

    async listenWallet(){
        let _this = this
        this.account = Store.getStore('global','account')
        let networkType = Store.getStore("global", "networkType")
        this.config = config[networkType]
        this.getApprovedForAll()
        this.setState({
            isMaskStaking: false
        })
        this.initDate()
        clearTimeout(_this.timer)
        function tickFn() {
            _this.initDate()
            _this.timer = setTimeout(()=>{
                tickFn()
            }, 3000)
        }
        tickFn()
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }

    getAccPlayerGego() {
        let _this = this
        let { teamId } = this.props.match.params
        let accPlayerNft = []
        _this.setState({isLoadingAccPlayer: true})
        Store.getAccPlayerGego({poolType: 'DividendsTeam', heroId: teamId}).then(async msy => {
            let result = msy.filter((item)=>{
                return item !== "0"
            })
            if (result.length !== 0) {
                for (let i = 0; i < result.length; i++) {
                    let nftId = result[i]
                        accPlayerNft.push({
                            id: nftId,
                            isLoading: false
                        })
                }
                
                _this.setState({
                    isLoadingAccPlayer: false,
                    accPlayerNft: accPlayerNft
                })
                let accPlayerTotalPower = 0
                let dictionaries = this.state.dictionaries
                if (accPlayerNft.length > 0) {
                    for (let i = 0; i < accPlayerNft.length; i++) {
                        if (dictionaries[accPlayerNft[i].id]) {
                            dictionaries[accPlayerNft[i].id].isLoading = false
                            accPlayerNft[i] = dictionaries[accPlayerNft[i].id]
                        } else {
                            let nftDetailsUri = await Store.getTokenURI({
                                nftVer: _this.nftType,
                                nftId: accPlayerNft[i].id
                            })
                            let heroDetail = await getNftTokenDetails(nftDetailsUri)
                            if (heroDetail.status !== 10001) {
                                accPlayerNft[i] = heroDetail
                            }
                            let amount  = accPlayerNft[i].attributes.find(item => {return item.trait_type === "amount"})
                            amount = amount.value
                            accPlayerNft[i].amount = amount ==='---'?'---':KeepSecimals(balanceFormat(amount, 18), 3)
                            let stakeRate = await Store.getBaseNftStakeInfo({
                                poolType: "Dividends",
                                nftId: accPlayerNft[i].id
                            })
                            accPlayerNft[i].stakeRate = stakeRate.stakeRate
                            accPlayerNft[i].stakeRateDisplay = toPercent(Number(stakeRate.stakeRate)/100000)
                        }
                        
                        accPlayerTotalPower += Number(accPlayerNft[i].amount) * Number(accPlayerNft[i].stakeRate)/100000
                        dictionaries[accPlayerNft[i].id] = accPlayerNft[i]
                    }
                }
                accPlayerTotalPower = KeepSecimals(String(accPlayerTotalPower), 3)

                _this.setState({
                    accPlayerNft: accPlayerNft,
                    accPlayerTotalPower: accPlayerTotalPower,
                    dictionaries: dictionaries
                })
            } else {

                _this.setState({
                    isLoadingAccPlayer: false,
                    accPlayerNft: []
                })
            }
        })
    }
    
    myNft(){
        let _this = this
        _this.setState({isLoadingMyNft: true})
        Store.getTokensOfOwner(_this.nftType).then(async msy => {
            if (msy.length === 0) {
                this.setState({
                    isHttpMask: false,
                    myNftList: []
                })

                _this.setState({isLoadingMyNft: false})
            } else {
                let myNftList = []
                for (let i = 0; i < msy.length; i++) {
                    let nftId = msy[i]
                    let isHero = await Store.isHeros(nftId)
                    if (!isHero) {
                        myNftList.push({
                            id: nftId,
                            isLoading: false
                        })
                    }
                }

                _this.setState({isLoadingMyNft: false})
                _this.setState({myNftList: myNftList})
                let dictionaries = this.state.dictionaries
                if (myNftList.length > 0) {
                    for (let i = 0; i < myNftList.length; i++) {
                        if (dictionaries[myNftList[i].id]) {
                            dictionaries[myNftList[i].id].isLoading = false
                            myNftList[i] = dictionaries[myNftList[i].id]
                        } else {
                            let nftDetailsUri = await Store.getTokenURI({
                                nftVer: _this.nftType,
                                nftId: myNftList[i].id
                            })
                            let heroDetail = await getNftTokenDetails(nftDetailsUri)
                            if (heroDetail.status !== 10001) {
                                myNftList[i] = heroDetail
                            }
                            let amount  = myNftList[i].attributes.find(item => {return item.trait_type === "amount"})
                            amount = amount.value
                            myNftList[i].amount = amount ==='---'?'---':KeepSecimals(balanceFormat(amount, _this.cocosDecimals), 3)
                            let stakeRate = await Store.getBaseNftStakeInfo({
                                poolType: "Dividends",
                                nftId: myNftList[i].id
                            })
                            myNftList[i].stakeRate = stakeRate.stakeRate
                            myNftList[i].stakeRateDisplay = toPercent(Number(stakeRate.stakeRate)/100000)
                            dictionaries[myNftList[i].id] = myNftList[i]
                        }
                        
                    }
                }
                _this.setState({
                    myNftList: myNftList,
                    dictionaries: dictionaries
                })
            }
        })
    }

    async setApprovedForAll(){
        // let _this = this
        this.setState({isLoadingApproved: true})
        let result = await Store.setApprovedForAll({apprAddrKey: 'Dividends', nftType: "v1"})
        this.setState({isLoadingApproved: false})
        if (result) {
            this.getApprovedForAll()
        }
        
    }
    getApprovedForAll(){
        Store.getApprovedForAll({apprAddrKey: 'Dividends', nftType: "v1"}).then(res => {
            this.setState({isApprove: res})
        })
    }

    getCocosAllowance(){
        Store.allowanceBase({
            poolType: "cocos",
            allowanceAddrKey: "Dividends"
        }).then(res => {
            this.setState({isCocosApprove: res})
        })
    }

    approveCocos(){
        let _this = this
        this.setState({isLoadingCocosApprove: true})
        Store.approveBase({
            poolType: "cocos",
            approveAddrKey: "Dividends"
        }).then(res => {
            _this.setState({isLoadingCocosApprove: false})
            if (res) {
                _this.getCocosAllowance()
            }
        })
    }

    async operationNft(nftId){
        // let _this = this
        let { teamId } = this.props.match.params
        let isContinue = false
        if (this.state.isMaskStakingType === "staking") {
            let myNftList = this.state.myNftList
            for (let i = 0; i < myNftList.length; i++) {
                if (myNftList[i].isLoading) {
                    isContinue = true
                }
            }
            if (isContinue) return
            for (let i = 0; i < myNftList.length; i++) {
                if (String(myNftList[i].id) === String(nftId)) {
                    myNftList[i].isLoading = true
                    this.setState({isLoadingOperationNft: true})
                    continue;
                }
            }
            this.setState({myNftList: myNftList})
            let result = await Store.stakeDegoDividendGego({
                poolType: "Dividends",
                _heroId: teamId,
                nftId: nftId
            })  
            if (result) {
                this.myNft()
            } else {

                this.setState({isLoadingOperationNft: false})
                for (let i = 0; i < myNftList.length; i++) {
                    myNftList[i].isLoading = false
                }
                this.setState({myNftList: myNftList})
            }
        } else if (this.state.isMaskStakingType === "withdraw") {
            let accPlayerNft = this.state.accPlayerNft
            for (let i = 0; i < accPlayerNft.length; i++) {
                if (accPlayerNft[i].isLoading) {
                    isContinue = true
                }
            }
            if (isContinue) return
            for (let i = 0; i < accPlayerNft.length; i++) {
                if (String(accPlayerNft[i].id) === String(nftId)) {
                    accPlayerNft[i].isLoading = true
                    this.setState({isLoadingOperationNft: true})
                    continue;
                }
            }
            this.setState({accPlayerNft: accPlayerNft})
            let result = await Store.withdrawDegoDividendGego({
                poolType: "Dividends",
                heroId: teamId,
                nftId: nftId
            })
            if (result) {
                this.getAccPlayerGego()
            } else {

                for (let i = 0; i < accPlayerNft.length; i++) {
                    accPlayerNft[i].isLoading = false
                    this.setState({isLoadingOperationNft: false})
                }
                this.setState({accPlayerNft: accPlayerNft})
            }
        }
    }
    async operationCocos(){
        let _this = this
        if (this.state.isLoadingInput) return
        let { teamId } = this.props.match.params
        let inputAmount = this.inputAmount.value
        inputAmount = String(Number(inputAmount))
        if (inputAmount) {
            _this.setState({isInputDego: true})
        } else {
            _this.setState({isInputDego: false})
            return false
        }
        inputAmount = balanceFormatAdd(inputAmount, _this.cocosDecimals)
        this.setState({isLoadingInput: true})
        if (this.state.isMaskStakingType === "staking") {
            
            let result = await Store.stakeDividendCocos({
                poolType: "Dividends",
                heroId: teamId,
                amount: inputAmount
            })
            this.setState({isLoadingInput: false})
            if (result) {
                this.getBalance()
                this.getAccBalances()
                this.setState({promptCon: this.t[i18next.language].common["44"]}, ()=>{
                    _this.showWeakHintMain()
                })
            }
        } else if (this.state.isMaskStakingType === "withdraw") {
            let result = await Store.withdrawDividendCocos({
                poolType: "Dividends",
                heroId: teamId,
                amount: inputAmount
            })
            this.setState({isLoadingInput: false})
            if (result) {
                this.getAccBalances()
                this.getBalance()
                this.setState({promptCon: this.t[i18next.language].common["45"]}, ()=>{
                    _this.showWeakHintMain()
                })
            }
            
        }
        
        
    }

    maxBtn () {

        if (this.state.isMaskStakingType === "staking") {
            this.inputAmount.value = this.state.cocosBalance
        } else if (this.state.isMaskStakingType === "withdraw") {
            this.inputAmount.value = this.state.stakingBalanceDisplay 
        }
        
    }

    getReward(){
        let _this = this
        let { teamId } = this.props.match.params
        if (this.state.isLoadingReward) return
        clearTimeout(_this.punishTimer)
        _this.setState({
            isMask: false,
            isLoadingReward: true
        })
        Store.getDividendReward({
            poolType: "Dividends",
            heroId: teamId
        }).then(res => {
            _this.setState({isLoadingReward: false})
        })
    }


    stakeHero() {
        let { teamId } = this.props.match.params
        this.setState({isLoadingStakeHero: true})
        Store.stakeHero({
            poolType: "Dividends",
            teamId: teamId
        }).then(res => {
            this.setState({isLoadingStakeHero: false})
        })
    }

    withdrawHero() {
        let { teamId } = this.props.match.params
        if (this.state.isLoadingStakeHero) return
        this.setState({isLoadingStakeHero: true})
        Store.withdrawHero({
            poolType: "Dividends",
            teamId: teamId
        }).then(res => {
            this.setState({isLoadingStakeHero: false})
        })

    }
    async getRewardBtn(){
        let _this = this
        let { teamId } = this.props.match.params
        let accLastStakedTime = await Store.getAccLastStakedTime({
            poolType: 'DividendsTeam', 
            teamId: teamId
        })
        let punishTime = await Store.getPunishTime('Dividends')
        let curData = (new Date()).getTime()
        curData = String(curData).slice(0, 10)
        let threeDays = punishTime
        let overThreeDays = Number(accLastStakedTime) + Number(threeDays)
        if (Number(overThreeDays) > Number(curData)) {
            this.setState({isMask: true})
            function punishTick() {
                let curDate = (new Date()).getTime()
                curDate = String(curDate).slice(0, 10)
                let punishDate = overThreeDays-curDate

                if (punishDate>0) {
                    punishDate = timeFormat(punishDate)
                    _this.setState({punishDate: punishDate})
                    _this.punishTimer = setTimeout(()=>{
                        punishTick()
                    }, 1000)
                } else {
                    clearTimeout(_this.punishTimer)
                    this.setState({isMask: false})
                }
                
            }
            punishTick()
        } else {
            this.getReward()
        }
    }
    changeMaskStakingTab(val){
        this.setState({maskStakingTab: val})
    }
    componentWillUnmount () {
        let _this = this
        clearTimeout(_this.timer)
        this.setState = (state, callback) => {
            return;
        };
    }

    showWeakHintMain() {
        let _this = this
        if (this.state.isShowWeakHintMain) return
        this.setState({isShowWeakHintMain: true})
        setTimeout(() => {
            _this.setState({isShowWeakHintMain: false})
        }, 3000)
    }

    jumpEth(){
        let account = Store.getStore('global', 'account')
        let networkType = Store.getStore("global", "networkType")
        let etherscanSite = config[networkType].etherscanSite + '/address/' + account
        window.open(etherscanSite)
    }
    jumpRouter(val){
        // this.props.history.push(val)
        let networkType = Store.getStore("global", "networkType")
        window.open(`${config[networkType].newProUri}/casting`)
    }
    render() {
        return (
            <div className="dividendsdetails-bg">

                {
                    this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.state.promptCon}</div>:''
                }
                {
                    this.state.isMask?<div className="mask">
                    <div className="mask-container getReward-mask-container">
                        <img alt="" src={close} className="close-btn" onClick={()=>{
                            this.closeSendMask()
                        }}/>
                        <p className="content mart73" dangerouslySetInnerHTML={{__html: showHtmlText(this.t[i18next.language].common["41"], this.state.myEarnedInPuni, 'cocos')}}></p>
                        <div className="punishtime-count-down">
                            <ul className='punishtime-count-down-item'>
                                <p>{this.state.punishDate.d}</p>
                                <p>Days</p>
                            </ul>
                            <ul className='punishtime-count-down-symbol'>:</ul>
                            <ul className='punishtime-count-down-item'>
                                <p>{this.state.punishDate.h}</p>
                                <p>Hours</p>
                            </ul>
                            <ul className='punishtime-count-down-symbol'>:</ul>
                            <ul className='punishtime-count-down-item'>
                                <p>{this.state.punishDate.m}</p>
                                <p>Minutes</p>
                            </ul>
                            <ul className='punishtime-count-down-symbol'>:</ul>
                            <ul className='punishtime-count-down-item'>
                                <p>{this.state.punishDate.s}</p>
                                <p>Seconds</p>
                            </ul>
                        </div>
                        <p className="getReward-confirm-btn" onClick={() => {
                            this.getReward()
                        }}>{this.state.sendLoading?<b className="loading"></b>:this.t[i18next.language].common['13']}</p>
                    </div>
                </div>:''
                }
                {
                    this.state.isMaskStaking?<div className="mask">
                    <div className="staking-mask-container">
                        <ul className="title">{this.state.isMaskStakingType === "staking"?this.t[i18next.language].common['38']:this.t[i18next.language].common['39']}
                            <img alt="" className="close-btn " onClick={()=>{
                                this.closeMaskStaking()
                            }} src={close} />
                        </ul>
                        <div className="content-container">
                            <div className="btn-bar">
                                <p className={this.state.maskStakingTab === "nft"?"btn":"btn disable"} onClick={()=>{
                                    this.changeMaskStakingTab('nft')
                                }}>NFT</p>
                                <p className={this.state.maskStakingTab === "cocos"?"btn":"btn disable"} onClick={()=>{
                                    this.changeMaskStakingTab('cocos')
                                }}>COCOS</p>
                            </div>
                            {
                                this.state.maskStakingTab === 'cocos'?<div className="container-main-cocos">
                                <ul className="total-power-bar">{`COCOS ${this.t[i18next.language].common["40"]}`}:  <span className="fcfb8126">{this.state.stakingBalanceDisplay}</span></ul>
                                
                                <ul className="balance-bar"> <span className="fcfb8126">{this.state.isMaskStakingType === "staking"?this.state.cocosBalance:this.state.stakingBalanceDisplay} COCOS</span>&nbsp; {this.t[i18next.language].common["5"]}</ul>
                                <ul className="input-bar">
                                    <input type="text" className="input-num" ref={(val) => this.inputAmount = val}/>
                                    <p className="max-btn" onClick={()=>{
                                        this.maxBtn()
                                    }}>Max</p>
                                </ul>
                                {
                                    String(this.state.isCocosApprove) === '0'?<ul className="staking-btn" onClick={()=>{
                                        this.approveCocos()
                                    }}>{this.state.isLoadingCocosApprove?<p className="loading"></p>:this.t[i18next.language].common['23']}</ul>:<ul className="staking-btn" onClick={()=>{
                                        this.operationCocos()
                                    }}>{this.state.isLoadingInput?<p className="loading"></p>:this.state.isMaskStakingType === 'staking'?this.t[i18next.language].common['38']:this.t[i18next.language].common['39']}</ul>
                                }<div className='rule-main'>
                                    <p>Rules for Principal Deductions when Staking COCOS</p>
                                    <p>No Less than 30 days: No principal deduction</p>
                                    <p>20-30 days (incl 20 days): 1% principal deduction</p>
                                    <p>10-20 days (incl 10 days): 2% principal deduction</p>
                                    <p>5-10 days (incl 5 days): 5% principal deduction</p>
                                    <p>Less than 5 days: 10% principal deduction</p>
                                </div>
                                
                            </div>:""
                            }
                            {
                                this.state.maskStakingTab === 'nft'?<div className="container-main">
                                <ul className="total-power-bar">{`NFT ${this.t[i18next.language].common["40"]}`}:  <span className="fcfb8126">{KeepDecimals(String(Number(this.state.accPowerBalancesDisplay) - Number(this.state.stakingBalanceDisplay)), 3)}</span></ul>
                                {
                                    this.state[this.state.isMaskStakingType === "staking"?"isLoadingMyNft":"isLoadingAccPlayer"]?<div className="list-main loading-status">
                                    <p className="loading-fb8126"></p>
                                </div>:""
                                }
                                {
                                    !this.state[this.state.isMaskStakingType === "staking"?"isLoadingMyNft":"isLoadingAccPlayer"]&&this.state[this.state.isMaskStakingType === "staking"?"myNftList":"accPlayerNft"].length === 0?<div className="list-main loading-status">
                                    <p className="empty-text">{this.t[i18next.language].common["16"]} <span className="to-casting-btn" onClick={()=>{
                                        this.jumpRouter('/casting')
                                    }}>{this.t[i18next.language].common["29"]}</span> </p>
                                </div>:""
                                }
                                
                                {
                                    !this.state[this.state.isMaskStakingType === "staking"?"isLoadingMyNft":"isLoadingAccPlayer"]&&this.state[this.state.isMaskStakingType === "staking"?"myNftList":"accPlayerNft"].length > 0?<div className="list-main">
                                    {
                                        this.state[this.state.isMaskStakingType === "staking"?"myNftList":"accPlayerNft"].map((item, key) => {
                                            return <div className="li-main" key={key}>
                                            <ul className="icon-main">
                                                {
                                                    item.small_image?<img alt="" src={item.small_image} />:<div className="loading-fb8126"></div>
                                                }
                                                
                                            </ul>
                                            <ul className="nft-info-container">
                                                <p className="name">{returnLocalize(item.i18n_name, i18next.language)}</p>
                                                <p className="attr mart10">COCOS {this.t[i18next.language].common["6"]}: {item.amount}</p>
                                                <p className="attr mart7">{this.t[i18next.language].common["43"]}: {item.stakeRateDisplay}</p>
                                            </ul>
                                            {
                                                this.state.isMaskStakingType === 'staking'?this.state.isApprove?<ul className="li-main-btn" onClick={()=>{
                                                    this.operationNft(item.id)
                                                }}>{item.isLoading?<p className="loading-fb8126"></p>:this.t[i18next.language].common['38']}</ul>:<ul className="li-main-btn" onClick={()=>{
                                                    this.setApprovedForAll()
                                                }}>{this.state.isLoadingApproved?<p className="loading-fb8126"></p>:this.t[i18next.language].common['23']}</ul>:<ul className="li-main-btn" onClick={()=>{
                                                    this.operationNft(item.id)
                                                }}>{item.isLoading?<p className="loading-fb8126"></p>:this.t[i18next.language].common['39']}</ul>
                                            }
                                            
                                        </div>
                                        })
                                    }
                                    
                                </div>:""
                                }
                            </div>:""
                            }
                            
                        </div>
                    </div>
                </div>:""
                }
                
                <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>
                <div className='crumbs-bar'>
                    <p onClick={() => {
                        window.location.href = '/dividends'
                    }}>{`Dividend >`}&nbsp;</p>
                    <p>{` Details`}</p>
                </div>
                <div className="dividendsdetails-info-container">
                    <div className="dividendsdetails-nft-info">
                        {
                            this.state.dividendsDetails&&String(this.state.dividendsDetails.captainStatus) === "1"&&this.state.isCaptain?<ul className="captain-staking-btn" onClick={()=>{
                                this.withdrawHero()
                            }}>{this.state.isLoadingStakeHero?<p className="loading"></p>:this.t[i18next.language].common['39']}</ul>
                            :""
                        }
                        {
                            this.state.dividendsDetails && String(this.state.dividendsDetails.captainStatus) === "2"&&this.state.isCaptain?<ul className="captain-staking-btn" onClick={()=>{
                                this.stakeHero()
                            }}>{this.state.isLoadingStakeHero?<p className="loading"></p>:this.t[i18next.language].common['38']}</ul>:""
                        }
                        
                        <ul className="nft-portrait">
                            {this.state.dividendsDetails?<img alt="" src={this.state.dividendsDetails.small_image} />:""}
                        </ul>
                        <div className="nft-info-main">
                            <ul className="nft-name">{this.state.dividendsDetails?returnLocalize(this.state.dividendsDetails.i18n_name, i18next.language):''}</ul>
                            <ul className="nft-captain">{this.t[i18next.language].dividends["2"]}: &nbsp;
                            
                            {
                                    this.state.dividendsDetails?
                                <span className="captain-address">
                                {
                                    String(this.state.dividendsDetails.captainStatus) === "1"?this.state.dividendsDetails.captain:""
                                }
                                {
                                    String(this.state.dividendsDetails.captainStatus) === "2"?" ---":""
                                }
                            </span>:""
                            }
                             </ul>
                            <div className="nft-attribute-group">
                                <ul className="nft-attribute-item">{this.t[i18next.language].dividends["3"]}: <span className="item-value">{this.state.dividendsDetails?this.state.dividendsDetails.totalPowerDisplay:0} {this.t[i18next.language].common["40"]}</span> </ul>
                                <ul className="nft-attribute-item">{this.t[i18next.language].dividends["5"]}: <span className="item-value">{this.state.dividendsDetails?this.state.dividendsDetails.incomeDisplay:0} COCOS / {this.t[i18next.language].common["40"]}</span></ul>
                                <ul className="nft-attribute-item">{this.t[i18next.language].dividends["4"]}: <span className="item-value">{this.state.dividendsDetails?this.state.dividendsDetails.Dividend:0} COCOS</span></ul>
                            </div>
                        </div>
                        
                    </div>
                    {
                        this.state.isCaptain?<div className="captain-info-container">
                        <p className="captain-title">{this.t[i18next.language].dividends["14"]}</p>
                        <div className="captain-attribute-group">
                            <ul className="captain-attribute">{this.t[i18next.language].dividends["15"]}: <span className="captain-attribute-value">{this.state.unlock} COCOS</span> </ul>
                            <ul className="captain-attribute marl188">{this.t[i18next.language].dividends["16"]}: <span className="captain-attribute-value">{this.state.dividendsDetails?this.state.dividendsDetails.captainHadRewardDisplay:0} COCOS</span> </ul>
                            <ul className="view-btn" onClick={()=>{
                                this.jumpEth()
                            }}>{this.t[i18next.language].common["36"]}</ul>
                        </div>
                    </div>:''
                    }
                    

                </div>

                <div className="dividendsdetails-operation-container">
                    <div className="dividendsdetails-operation-main">
                        <p className="dividendsdetails-operation-title">{this.t[i18next.language].dividends["17"]}</p>
                        <p className="dividendsdetails-operation-name">{this.t[i18next.language].dividends["18"]}</p>
                        <p className="dividendsdetails-operation-con">{this.state.myEarned} COCOS</p>
                        <ul className="dividendsdetails-operation-btn" onClick={()=>{
                            this.getRewardBtn()
                        }}>{this.state.isLoadingReward?<p className="loading"></p>:this.t[i18next.language].common["37"]}</ul>
                        <ul className="dividendsdetails-operation-remarks">{this.t[i18next.language].dividends["19"]}: <span className="remarks-con">{this.state.accTotalRewardsDisplay} COCOS</span> </ul>
                    </div>
                    <div className="dividendsdetails-operation-main">
                        <p className="dividendsdetails-operation-title">{this.t[i18next.language].dividends["22"]}</p>
                        <p className="dividendsdetails-operation-name">{this.t[i18next.language].dividends["21"]}</p>
                        <p className="dividendsdetails-operation-con">{this.state.accPowerBalancesDisplay} POWER</p>
                        <div className="dividendsdetails-operation-btn-bar">
                            {/*<ul className={!!this.state.dividendsDetails && String(this.state.dividendsDetails.captainStatus) === "2" ? "dividendsdetails-operation-btn-left gray" : "dividendsdetails-operation-btn-left"} onClick={()=>{*/}
                            <ul className={"dividendsdetails-operation-btn-left gray"} onClick={()=>{
                                // this.openMaskStaking("staking")
                            }}>{this.t[i18next.language].common["38"]}</ul>
                            <ul className="dividendsdetails-operation-btn-right" onClick={()=>{
                                this.openMaskStaking('withdraw')
                            }}>{this.t[i18next.language].common["39"]}</ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}