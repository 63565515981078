import React from 'react';
import './MiningDetails.css'
import  close from "../../static/images/common/close.png"
import Loading from "../base/Loading/Loading"
import TopCompontent from '../base/TopCompontent/TopCompontent'
import { getNftTokenDetails, getKCSPrice } from '../../libs/Api'
import { miningDetails } from '../../config/business.conf'
import { KeepDecimals, balanceFormat, timeFormat, toPercent, showHtmlText, returnLocalize } from '../../libs/Util'
import config from '../../config/config'
import { lang } from "../../i18n/lang/lang"
import i18next from 'i18next'

import store from "../../stores";
const Store = store.store

export default class MiningDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMask: false,
            KCSPrice: 0,
            miningStartTime: 0,
            miningPeriodFinish: 0,
            miningTotalPower: 0,
            miningInitReward: 0,
            incomeDay: 0,
            earned: 0,
            earnedPunish: 0,
            myTotalPower: 0,
            countDownText: {
                d: '00',
                h: '00',
                m: '00',
                s: '00'
            },
            nftInfoDictionary: {},
            myNftList: [],
            myStakeList: [],
            isOpen: false,

            
            isLoadingReward: false,
            isLoadingExit: false,
            isApproveLoading: false,
            isApprove: false,
            isShowWeakHintMain: false,
            withdrawType: ""
        }
        this.t = lang
        let networkType = Store.getStore("global", "networkType") || "bnb"
        this.config = config[networkType]
        this.account = Store.getStore('global','account')
        this.localize  = Store.getStore("global", "localize")
        this.nftVer = 'v1'
        this.miningPoolId = props.match.params.poolId
        this.rewardsTokenDisplay = miningDetails[this.miningPoolId].rewardsToken.toUpperCase()
        this.timerMining = null
    }



    async getMyNftPlayerIds(){
        let _this = this
        let myStakeList = []
        let nftInfoDictionary = this.state.nftInfoDictionary
        Store.getMyNftPlayerIds(_this.miningPoolId).then(async res=> {
            if (!res) return
            let ids = res
            let myTotalPower = 0
            ids.forEach(element => {
                if (String(element) !== "0") {
                    myStakeList.push({
                        id: element,
                        loading: false
                    })
                }
            });
            _this.setState({myStakeList: myStakeList},async ()=>{
                for (let i = 0; i < myStakeList.length; i++) {
                    let nftId = myStakeList[i].id
                    if (nftInfoDictionary[nftId]) {
                        myStakeList[i] = nftInfoDictionary[nftId]
                        myStakeList[i].loading = false


                        let stakeRate = Number(KeepDecimals(String(Number(myStakeList[i].stakeRate)/100000), 5))
                        myTotalPower += Number(KeepDecimals(String(stakeRate * Number(myStakeList[i].amountDisplay)),5))
                        myStakeList[i].stakeRateDisplay = toPercent(Number(myStakeList[i].stakeRate)/100000)
                    } else {
                        let nftDetailsUri = await Store.getTokenURI({
                            nftVer: _this.nftVer,
                            nftId: nftId
                        })
                        let nftDetails = await getNftTokenDetails(nftDetailsUri)
                        myStakeList[i] = Object.assign(nftDetails, myStakeList[i])
                        let erc20 = myStakeList[i].attributes.erc20
                        let curNftToken = null
                        let unit = ''
                        for (const key in _this.config) {
                            if (_this.config[key].address) {
                                if (_this.config[key].address.toLowerCase() === erc20.toLowerCase()) {
                                    curNftToken = _this.config[key]
                                    unit = key
                                    break;
                                }
                            }
                        }
                        let amount = myStakeList[i].attributes.amount
                        myStakeList[i].amountDisplay = KeepDecimals(balanceFormat(amount, curNftToken.decimals), 3)
                        myStakeList[i].unit = unit
                        let result = await Store.getNftStakeInfo({ 
                            miningPool: _this.miningPoolId,
                            nftId: nftId
                        })
                        let stakeRate = Number(KeepDecimals(String(Number(result.stakeRate)/100000), 5))
                        myTotalPower += Number(KeepDecimals(String(stakeRate * Number(myStakeList[i].amountDisplay)),5))
                        myStakeList[i].stakeRate = result.stakeRate
                        myStakeList[i].stakeRateDisplay = toPercent(Number(result.stakeRate)/100000)
                    }
                    _this.setState({myStakeList: []}, ()=>{
                        _this.setState({myStakeList: myStakeList})
                    })
                }
                myTotalPower = Number(KeepDecimals(String(myTotalPower), 3))
                _this.setState({myTotalPower: myTotalPower})
            })
        })
    }

    myNft() {
        let _this = this
        let myNftList = []
        let nftInfoDictionary = this.state.nftInfoDictionary
        Store.getTokensOfOwner(_this.nftVer).then(async res=> {
            if (!res) return
            let ids = res
            ids.forEach(element => {
                myNftList.push({
                    id: element,
                    loading: false
                })
            });
            _this.setState({myNftList: myNftList},async ()=>{
                for (let i = 0; i < myNftList.length; i++) {
                    let nftId = myNftList[i].id
                    if (nftInfoDictionary[nftId]) {
                        myNftList[i] = nftInfoDictionary[nftId]
                        myNftList[i].loading = false

                        myNftList[i].stakeRateDisplay = toPercent(Number(myNftList[i].stakeRate)/100000)
                    } else {

                        let nftDetailsUri = await Store.getTokenURI({
                            nftVer: _this.nftVer,
                            nftId: nftId
                        })
                        let nftDetails = await getNftTokenDetails(nftDetailsUri)
                        myNftList[i] = Object.assign(nftDetails, myNftList[i])
    
                        let stakeRule = miningDetails[this.miningPoolId].stakeRule
                        if (stakeRule) {
                            let isContinue = true
                            let nftType = myNftList[i].attributes.nftType
                            let ruleId = myNftList[i].attributes.ruleId
                            for (let i = 0; i < stakeRule.length; i++) {
                                if (stakeRule[i].nftType === nftType && stakeRule[i].ruleId === ruleId) {
                                    isContinue = false
                                }
                            }
                            if (isContinue) {
                                myNftList[i].loading = false
                                nftInfoDictionary[nftId] = myNftList[i]
                                continue
                            } else {
                        
                                let erc20 = myNftList[i].attributes.erc20
                                let curNftToken = null
                                let unit = ''
                                for (const key in _this.config) {
                                    if (_this.config[key].hasOwnProperty('address')) {
                                        
                                        if (_this.config[key].address.toLowerCase() === erc20.toLowerCase()) {
                                            curNftToken = _this.config[key]
                                            unit = key
            
                                            break;
                                        }
                                    }
                                }
                                let amount = myNftList[i].attributes.amount
                                myNftList[i].amountDisplay = KeepDecimals(balanceFormat(amount, curNftToken.decimals?curNftToken.decimals:18), 3)
                                myNftList[i].unit = unit
                                let result = await Store.getNftStakeInfo({ 
                                    miningPool: _this.miningPoolId,
                                    nftId: nftId
                                })

                                myNftList[i].stakeRate = result.stakeRate
                                myNftList[i].stakeRateDisplay = toPercent(Number(result.stakeRate)/100000)
                            }
                        }
                    }
                    _this.setState({myNftList: myNftList})
                }
            })
        })
        this.getMyNftPlayerIds()
    }
    
    getApprovedForAll(){
        let _this = this
        Store.getMiningApprovedForAll({
            nftVer: this.nftVer, 
            apprAddrKey: this.miningPoolId
        }).then(res => {
            _this.setState({isApprove: res})
        })
    }

    getReward(){
        let _this = this
        if (!this.state.isOpen) {
            this.showWeakHintMain()
            return
        }
        this.setState({isLoadingReward: true})
        Store.getReward(_this.miningPoolId).then(res => {
            _this.setState({isLoadingReward: false})
            _this.timerTickFn()
        })
    }


    exit(){
        let _this = this
        if (this.state.isLoadingExit) return
        if (!this.state.isOpen) {
            this.showWeakHintMain()
            return
        }
        this.setState({isLoadingExit: true})
        Store.exit(_this.miningPoolId).then(res => {
            _this.setState({isLoadingExit: false})
            if (!res) return
            _this.timerTickFn()
            _this.myNft()
        })
    }


    setApprovedForAll(){
        let _this = this
        if (this.state.isApproveLoading) return
        this.setState({isApproveLoading: true})
        Store.setMiningApprovedForAll({
            nftVer: this.nftVer, 
            apprAddrKey: this.miningPoolId
        }).then(res => {
            _this.setState({
                isApproveLoading: false
            })
            _this.getApprovedForAll(res)
        })
    }

    async getMiningInitReward() {
        let _this = this
        let miningInitReward = {}
        let initReward = await Store.getMiningInitReward(_this.miningPoolId)
        miningInitReward = Number(KeepDecimals(balanceFormat(initReward, miningDetails[_this.miningPoolId].decimals), 3))
        this.setState({miningInitReward: miningInitReward})
    }

    async stakeCowNFT(nftId) {
        let _this = this
        if (!this.state.isOpen) {
            this.showWeakHintMain()
            return
        }
        let myNftList = this.state.myNftList
        let loadingNft = myNftList.filter(item => {
            return item.loading === true
        })
        if (loadingNft.length > 0) return
        for (let i = 0; i < myNftList.length; i++) {
            if (myNftList[i].id === nftId) {
                myNftList[i].loading = true
                continue;
            }
        }
        this.setState({myNftList: myNftList})
        Store.stakeCowNFT({
            miningPool: String(_this.miningPoolId),
            cowNFTId: nftId
        }).then(res => {
            if (res) {
                _this.myNft()
            } else {
                for (let i = 0; i < myNftList.length; i++) {
                    myNftList[i].loading = false
                }
                this.setState({myNftList: myNftList})
            }
        })
    }

    withdrawCowNFT(nftId){
        let _this = this
        if (!this.state.isOpen) {
            this.showWeakHintMain()
            return
        }
        let myStakeList = this.state.myStakeList
        let loadingNft = myStakeList.filter(item => {
            return item.loading === true
        })
        if (loadingNft.length > 0) return
        for (let i = 0; i < myStakeList.length; i++) {
            if (myStakeList[i].id === nftId) {
                myStakeList[i].loading = true
                continue;
            }
        }
        Store.withdrawCowNFT({
            miningPool: String(_this.miningPoolId),
            cowNFTId: nftId
        }).then(res => {
            if (res) {
                _this.myNft()
            } else {
                for (let i = 0; i < myStakeList.length; i++) {
                    myStakeList[i].loading = false
                }
                this.setState({myStakeList: myStakeList})
            }
        })
    }

    async getMingingEarned() {
        let _this = this
        let earned = await Store.getMingingEarned(_this.miningPoolId)
        earned = Number(balanceFormat(earned, miningDetails[_this.miningPoolId].decimals))
        let referral = 0.1
        let punish = 0.1
        let info = (earned).toFixed(2) * (1 - referral)
        info = KeepDecimals(String(info), 3)
        let earnedPunish = info * (1 - punish)
        earnedPunish = KeepDecimals(String(earnedPunish), 3)
        this.setState({earned: ''})
        this.setState({earned: info})
        this.setState({earnedPunish: earnedPunish})
    }

    async getMiningTotalPower() {
        let _this = this
        let miningTotalPower = 0
        let miningInitReward = this.state.miningInitReward
        let startTime = this.state.miningStartTime
        let periodFinish = this.state.miningPeriodFinish
        let incomeDay = 0
        let duration = 0
        
        let totalPower = await Store.getMiningTotalPower(_this.miningPoolId)
        miningTotalPower = Number(KeepDecimals(balanceFormat(totalPower, miningDetails[_this.miningPoolId].decimals), 3))
        if (periodFinish && startTime) {
            duration = Number(periodFinish) - Number(startTime)

            let dayNum = Number(duration)/3600/24
            dayNum = Number(KeepDecimals(String(dayNum), 3))
            if (dayNum === 0 || miningTotalPower === 0) {
                incomeDay = 0
            } else {
                incomeDay = Number(miningInitReward)/dayNum/Number(miningTotalPower)
                incomeDay = KeepDecimals(String(incomeDay), 5)
            }
        } else {
            duration = 0
        }
            
        this.setState({
            miningTotalPower: miningTotalPower,
            incomeDay: incomeDay
        })
    }
    
    extract(){
        if (!this.state.isOpen) {
            this.showWeakHintMain()
            return
        }
        this.setState({isMask: false})
        if (this.state.withdrawType === 'harvest') {
            this.getReward()
        } else {
            this.exit()
        }
    }

    async withdrawFn(withdrawType){
        let _this = this
        if (!this.state.isOpen) {
            this.showWeakHintMain()
            return
        }
        let lastStakedTime = await Store.getLastStakedTime(_this.miningPoolId)
        let curTimestamp = (new Date()).getTime()
        curTimestamp = String(curTimestamp).slice(0, 10)
        lastStakedTime = Number(lastStakedTime)
        let punishTime = await Store.getMiningPunishTime(_this.miningPoolId)
        let threeDayTimestamp = Number(punishTime) + Number(lastStakedTime)
        let timeRemaining = threeDayTimestamp - curTimestamp
        if (timeRemaining >= 0) {
            this.setState({
                withdrawType: withdrawType,
                isMask: true
            })
        } else {

            if (withdrawType === 'harvest') {
                this.getReward()
            } else {
                this.exit()
            }
        }
    }

    async initDate(){
        let _this = this
        getKCSPrice(_this.rewardsTokenDisplay).then(res => {
            _this.setState({KCSPrice: KeepDecimals(String(res.result), 4)})
        })
        this.getApprovedForAll()
        this.myNft()
        let miningStartTime = await Store.getMingingStartTime(_this.miningPoolId)
        let miningPeriodFinish = await Store.getMingingPeriodFinish(_this.miningPoolId)

        this.getMiningInitReward()
        this.setState({
            miningStartTime: miningStartTime,
            miningPeriodFinish: miningPeriodFinish
        })
        // Timer
        function tickFn() {
            let isOpen = false
            let curTimestamp = (new Date()).getTime()
            curTimestamp = String(curTimestamp).slice(0, 10)
            if (Number(curTimestamp) - Number(miningStartTime) > 0) {
                isOpen = true
            } else {
                isOpen = false
            }
            let duration = Number(miningPeriodFinish)  - Number(curTimestamp)
            _this.setState({isOpen: isOpen})
            _this.timerTickFn()

            if (duration > 0) {
                let countDown = timeFormat(duration)
                _this.setState({countDownText: countDown})
                _this.timerMining = setTimeout(function () {
                    tickFn()
                }, 10000)
            } else {
                _this.setState({countDownText: {
                    d: '00',
                    h: '00',
                    m: '00',
                    s: '00'
                }})
                
                clearTimeout(_this.timerMining)
            }
        }
        tickFn()
        
    }

    closeSendMask(){
        this.setState({isMask: false})
    }

    timerTickFn() {
        this.getMingingEarned()
        this.getMiningTotalPower()
    }
    componentDidMount () {
        // let _this = this
        if (this.account === '') return
        this.initDate()
    }

    async listenWallet(){
        this.account = Store.getStore('global','account')
        let networkType = Store.getStore("global", "networkType")
        this.config = config[networkType]
        this.initDate()
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }

    showWeakHintMain() {
        let _this = this
        if (this.state.isShowWeakHintMain) return
        this.setState({isShowWeakHintMain: true})
        setTimeout(() => {
            _this.setState({isShowWeakHintMain: false})
        }, 3000)
    }

    jumpCastingRouter() {
        this.props.history.push(`/casting`)
    }

    componentWillUnmount () {
        let _this = this
        clearTimeout(_this.timerMining)
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        return (
            <div className="miningdetails-component">
                <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>
                
                
                {
                    this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.t[i18next.language].common["2"]}</div>:''
                }
                {
                    this.state.isMask?<div className="mask">
                    <div className="mask-container send-mask-container">
                        <img alt="" src={close} className="close-btn" onClick={()=>{
                            this.closeSendMask()
                        }}/>
                        <p className="content mart73" dangerouslySetInnerHTML={{__html: showHtmlText(this.t[i18next.language].common["19"], this.state.earnedPunish, miningDetails[this.miningPoolId].rewardsToken.toUpperCase())}}></p>
                        
                        <p className="confirm-btn" onClick={() => {
                            this.extract()
                        }}>{this.state.sendLoading?<b className="loading"></b>:this.t[i18next.language].common['13']}</p>
                    </div>
                </div>:''
                }
                <div className="top-compontent" style={{background: `#${miningDetails[this.miningPoolId].bgColor} url(${miningDetails[this.miningPoolId].pic}) no-repeat center 5px` ,backgroundSize: "auto 80%"}}>
                    <div className="stake-info-container">
                        <ul className="stake-info-main">
                            <p className="stake-info-key">{this.t[i18next.language].miningDetails["0"]}</p>
                            <p className="stake-info-value">{this.state.miningTotalPower}</p>
                        </ul>
                        <ul className="stake-info-main">
                            <p className="stake-info-key">{this.t[i18next.language].miningDetails["3"]}(COCOS)</p>
                            <p className="stake-info-value">{this.state.KCSPrice}</p>
                        </ul>
                        <ul className="stake-info-main">
                            <p className="stake-info-key">{this.t[i18next.language].miningDetails["4"]}</p>
                            <p className="stake-info-value fc26BFA2">{this.state.countDownText.d}:{this.state.countDownText.h}:{this.state.countDownText.m}:{this.state.countDownText.s}</p>
                        </ul>
                    </div>
                </div>

                <div className="mining-container">
                    <div className="stake-component">
                        <p className="title">{this.t[i18next.language].miningDetails["5"]}</p>

                        <div className="stake-info-container">
                            <div className="stake-info-main">
                                {/* <p className="stake-info-li">{this.t[i18next.language].miningDetails["2"]}： <span className="fc26BFA2">{this.state.incomeDay} COCOS</span></p> */}
                                <p className="stake-info-li">{this.t[i18next.language].miningDetails["15"]}： <span className="fc26BFA2">{this.state.myTotalPower}</span></p>
                                
                                <p className="stake-info-li">{this.t[i18next.language].miningDetails["6"]}：<span className="fc26BFA2">{this.state.earned}</span></p>
                            </div>
                            <div className="stake-operation-btn">
                                <ul className="harvest" onClick={()=> {
                                    // this.getReward()
                                    this.withdrawFn("harvest")
                                }}>{this.state.isLoadingReward?<p className="loading"></p>:this.t[i18next.language].miningDetails["7"]}</ul>
                                <ul className="harvest-all" onClick={() => {
                                    // this.exit()
                                    this.withdrawFn("harvestAll")
                                }}>{this.state.isLoadingExit?<p className="loading"></p>:this.t[i18next.language].miningDetails["8"]}</ul>
                            </div>
                        </div>

                        <div className="stake-list-container">

                            {
                                this.state.myStakeList.length === 0?<p className="empty-mining-list">{this.t[i18next.language].common['20']}</p>:""
                            }
                            {
                               this.state.myStakeList.map((item, key) => {
                                   return <div className="stake-li" key={key}>
                                   <ul className="stake-icon">
                                       {
                                           item.big_image?<img alt="" src={item.big_image} />:<Loading />
                                       }
                                       
                                   </ul>
                                   <div className="stake-info-group">
                                       <p className="stake-info-title">{returnLocalize(item.i18n_name,i18next.language)}</p>
                                       <p className="stake-amount">{this.t[i18next.language].miningDetails['9']}: {item.amountDisplay}</p>
                                       <p className="stake-rate">{this.t[i18next.language].miningDetails['10']}: {item.stakeRateDisplay}</p>
                                   </div>
                                   <ul className="btn" onClick={()=>{
                                       this.withdrawCowNFT(item.id)
                                   }}>{item.loading?<p className="loading"></p>:this.t[i18next.language].miningDetails['11']}</ul>
                               </div>
                               }) 
                            }
                        </div>
                    </div>


                    <div className="stake-component mynft-component mart40">
                        <p className="title">{this.t[i18next.language].miningDetails['13']}</p>


                        <div className="stake-list-container mynft-list-container">
                            {
                                this.state.myNftList.length === 0?<p className="empty-mining-list">
                                    {this.t[i18next.language].common['16']}
                                    <span className="jump-cast-router" onClick={()=>{
                                        this.jumpCastingRouter()
                                    }}>&nbsp; { this.t[i18next.language].common['29']}>>></span>
                                </p>:""
                            }
                            {
                                this.state.myNftList.map((item, key) => {
                                    return <div className="stake-li" key={key}>
                                    <ul className="stake-icon">
                                       {
                                           item.big_image?<img alt="" src={item.big_image} />:<Loading />
                                       }
                                    </ul>
                                    <div className="stake-info-group">
                                        <p className="stake-info-title">{returnLocalize(item.i18n_name,i18next.language)}</p>
                                        <p className="stake-amount">{this.t[i18next.language].miningDetails['9']}: {item.amountDisplay}</p>
                                        <p className="stake-rate">{this.t[i18next.language].miningDetails['10']}: {item.stakeRateDisplay}</p>
                                    </div>
                                    {
                                        this.state.isApprove?<ul className="btn" onClick={()=>{
                                            this.stakeCowNFT(item.id)
                                        }}>{item.loading?<p className="loading"></p>:this.t[i18next.language].miningDetails['14']}{item.loading}</ul>:<ul className="btn" onClick={()=>{
                                            this.setApprovedForAll()
                                        }}>{this.state.isApproveLoading?<p className="loading"></p>:this.t[i18next.language].common['18']}</ul>
                                    }
                                    
                                </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}