import React from 'react';
import './Mining.css'
import TopCompontent from '../base/TopCompontent/TopCompontent'
import miningRuleEn from '../../static/images/mining/mining-rule-en.png'
import miningRuleCn from '../../static/images/mining/mining-rule-cn.png'
import  close from "../../static/images/common/close.png"
import { miningPoorOrder, miningDetails } from '../../config/business.conf'
import { KeepDecimals, balanceFormat, reRouterPath } from '../../libs/Util'
import { lang } from "../../i18n/lang/lang"
import i18next from 'i18next'

import store from "../../stores";
const Store = store.store

export default class Mining extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            miningTotalPower: {},
            miningInitReward: {},
            miningStartTime: {},
            miningPeriodFinish: {},
            incomeDay: {},
            isOpen: null,

            isMaskMiningRule:false
        }
        this.t = lang
        this.account = Store.getStore('global','account')
        this.localize  = Store.getStore("global", "localize")
        this.miningTimer = null
    }

    async initDate(){
        let _this = this
        this.getStartTime()
        this.getPeriodFinish()
        this.getMiningInitReward()
        // Timer
        function tickFn() {
            _this.getMiningTotalPower()
            _this.miningTimer = setTimeout(()=>{
                tickFn()
            }, 3000)
        }
        tickFn()


    }
    componentDidMount () {
        // let _this = this
        if (this.account === '') return
        this.initDate()
    }

    async getStartTime () {
        let miningStartTime = {}
        for (let i = 0; i < miningPoorOrder.length; i++) {
            miningStartTime[miningPoorOrder[i]] = await Store.getMingingStartTime(miningPoorOrder[i])
        }
        this.setState({
            miningStartTime: miningStartTime
        })
    }

    async getPeriodFinish () {
        let miningPeriodFinish = {}
        for (let i = 0; i < miningPoorOrder.length; i++) {
            miningPeriodFinish[miningPoorOrder[i]] = await Store.getMingingPeriodFinish(miningPoorOrder[i])
        }
        this.setState({miningPeriodFinish: miningPeriodFinish})
    }
    

    async getMiningTotalPower() {
        let miningTotalPower = {}
        let miningTotalPowerDisplay = {}
        let miningInitReward = this.state.miningInitReward
        let miningStartTime = this.state.miningStartTime
        let miningPeriodFinish = this.state.miningPeriodFinish
        let incomeDay = {}
        let duration = {}
        for (let i = 0; i < miningPoorOrder.length; i++) {
            let totalPower = await Store.getMiningTotalPower(miningPoorOrder[i])
            miningTotalPower[miningPoorOrder[i]] = Number(KeepDecimals(balanceFormat(totalPower, miningDetails[miningPoorOrder[i]].decimals), 3))
            miningTotalPowerDisplay[miningPoorOrder[i]] = Number(KeepDecimals(balanceFormat(totalPower, miningDetails[miningPoorOrder[i]].decimals), 1))
            let startTime = miningStartTime[miningPoorOrder[i]]
            let periodFinish = miningPeriodFinish[miningPoorOrder[i]]
            if (periodFinish && startTime) {
                duration[miningPoorOrder[i]] = Number(periodFinish) - Number(startTime)

                let dayNum = Number(duration[miningPoorOrder[i]])/3600/24
                dayNum = Number(KeepDecimals(String(dayNum), 3))
                if (dayNum === 0 || String(miningTotalPower[miningPoorOrder[i]]) === "0") {
                    incomeDay[miningPoorOrder[i]] = 0
                } else {
                    incomeDay[miningPoorOrder[i]] = Number(miningInitReward[miningPoorOrder[i]])/dayNum/Number(miningTotalPower[miningPoorOrder[i]])
                    incomeDay[miningPoorOrder[i]] = KeepDecimals(String(incomeDay[miningPoorOrder[i]]), 5)
                }
                
            } else {
                duration[miningPoorOrder[i]] = 0
            }
        }
        this.setState({
            miningTotalPower: miningTotalPowerDisplay,
            incomeDay: incomeDay
        })
    }

    async getMiningInitReward() {
        let miningInitReward = {}
        for (let i = 0; i < miningPoorOrder.length; i++) {
            let initReward = await Store.getMiningInitReward(miningPoorOrder[i])
            miningInitReward[miningPoorOrder[i]] = Number(KeepDecimals(balanceFormat(initReward, miningDetails[miningPoorOrder[i]].decimals), 3))
        }
        this.setState({miningInitReward: miningInitReward})
    }

    
    routerJump(pool){
        let networkType = Store.getStore('global', 'networkType')
        let curRouterPath = reRouterPath("MiningDetails", networkType)
        this.props.history.push(`${curRouterPath}${pool}`)
    }

    async listenWallet(){
        this.account = Store.getStore('global','account')
        this.initDate()
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }

    componentWillUnmount () {
        let _this = this
        clearTimeout(_this.miningTimer)
        this.setState = (state, callback) => {
            return;
        };
    }

    openMask () {
        this.setState({isMaskMiningRule: true})
    }

    closeMask () {
        this.setState({isMaskMiningRule: false})
    }

    render() {
        return (
            <div className="mining-component">
                <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>
                    <img alt="" className="mining-rule-btn" src={i18next.language === 'cn'?miningRuleCn:miningRuleEn} onClick={()=>{
                        this.openMask()
                    }}/>
                {
                    this.state.isMaskMiningRule?<div className="mask">
                    <div className="mining-rule-container">
                        <img alt="" src={close} className="close-btn " onClick={()=>{
                            this.closeMask()
                        }}/>
                        <p className="title">{this.t[i18next.language].mining.common["1"]}</p>
                        <p className="con mart30">{this.t[i18next.language].mining.common["2"]}</p>
                        <table cellPadding="0" cellSpacing="0" border="0" className="rule-table">
                            <thead>
                                <tr>
                                    {
                                        this.t[i18next.language].miningRule.head.map((item, key) => {
                                            return <td key={key}>{item}</td>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.t[i18next.language].miningRule.body.map((item, key)=>{
                                        return <tr key={key}>
                                            {
                                                item.map((it, k) => {
                                                    return <td key={k}>{it}</td>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <p className="explain">{this.t[i18next.language].mining.common["3"]}</p>
                        {/* <p className="con mart10">{this.t[i18next.language].mining.common["4"]}</p> */}
                        <p className="con mart10">{this.t[i18next.language].mining.common["5"]}</p>
                    </div>
                </div>:""
                }
                
                <div className="container-main">
                    <div className="mining-list-container">
                        {
                            miningPoorOrder.map((item, key) => {
                                return <div className="mining-main" key={key} onClick={()=>{
                                    this.routerJump(item)
                                }}>
                                    {
                                        miningDetails[item].isEnd?<p className="end-icon">{this.t[i18next.language].common[27]}</p>:""
                                    }
                                    
                                    <div className="mining-icon">
                                        <img alt="" src={miningDetails[item].pic} />
                                    </div>
                                    <div className="mining-info-container">
                                        <div className="title-main">
                                            <p>{this.t[i18next.language].mining[item].name}</p>
                                            <p> <span className="name-title">{this.t[i18next.language].miningDetails["0"]}：</span> <span className="power-con">{this.state.miningTotalPower[item]?this.state.miningTotalPower[item]:0}</span></p>
                                        </div>
                                        <ul className="describe">{this.t[i18next.language].mining[item].describe}</ul>
                                        <div className="pool-info">
                                            <div className="pool-info-li">{this.t[i18next.language].miningDetails["1"]}: <span className="fcFF7E00">{this.state.miningInitReward[item]?this.state.miningInitReward[item]:0} cocos</span> </div>
                                            <div className="pool-info-li">{this.t[i18next.language].miningDetails["2"]}:  <span className="fcF65830">{this.state.incomeDay[item]?this.state.incomeDay[item]:0} cocos</span> </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        
                    </div>
                </div>
                
            </div>
        )
    }
}