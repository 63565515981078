import React from 'react';
import './Casting.css'
import TopCompontent from '../base/TopCompontent/TopCompontent'
import CastingNFT from '../CastingNFT/CastingNFT'
import { castingNFTShow } from "../../config/casting.conf"
import PagingComponent from '../base/PagingComponent/PagingComponent'
import { lang } from "../../i18n/lang/lang"
import i18next from 'i18next'
import { getMintLogV2, getNftTokenDetails } from "../../libs/Api"
import { dateCommonFormat, returnLocalize, KeepSecimals, balanceFormat } from "../../libs/Util"

// import 'swiper/dist/css/swiper.min.css'

import store from "../../stores";
const Store = store.store

export default class Casting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resDetailsList:  [
            ],
            isMaskCasting: false,
            isLoadingHistoryList: false,

            pagasize: 6,
            curpage: 1,
            newlist: ["123", "456"],
            historyListDisplay:[],

            castingnft: [],
            curChooseNft: null,
            castingnftDisplay: []
        }
        this.t = lang
        this.account = Store.getStore('global','account')
        this.localize  = Store.getStore("global", "localize")
        this.nftVer = "v1"
    }


    componentDidMount () {
        if (this.account === '' || !this.account) return
        this.initDate()
        

    }

    async listenWallet(){
        this.initDate()
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }


    componentWillUnmount () {

        this.setState = (state, callback) => {
            return;
        };
    }

    pageTurning(res){
        let _this = this
        let pagasize = _this.state.pagasize
        this.setState({curpage: res})
        let beginPage = (Number(res) - 1)*Number(pagasize)
        let endPage = beginPage + Number(pagasize)
        let list = this.state.resDetailsList
        let historyListDisplay = list.slice(beginPage, endPage)
        _this.setState({historyListDisplay: []}, ()=>{
            _this.setState({
                historyListDisplay: historyListDisplay
            })
        })
        
    }

    async initDate(){
        let _this = this
        this.account = Store.getStore('global','account')
        let account = this.account
        this.setState({
            resDetailsList: [],
            historyListDisplay: [],
            isBusinessMask: true,
            isLoadingHistoryList: true
        })
        // return
        let resDetailsList = []
        getMintLogV2(account).then(async res => {
            let result = res.result
            let ids = []
            result.forEach(element => {
                ids.push(element.id)
            });
            if(ids.length === 0){
                _this.setState({historyListDisplay: []})
            } else {
                ids.forEach(element => {
                    resDetailsList.push({id: String(element)})
                });
                for (let i = 0; i < resDetailsList.length; i++) {
                    let nftId = resDetailsList[i].id
                    console.log("nftId>>>>", nftId)
                    let nftDetailsUri = await Store.getTokenURI({
                        nftVer: _this.nftVer,
                        nftId: nftId
                    })
                    console.log('nftDetailsUri', nftDetailsUri)
                    if (nftDetailsUri) {
                        let nftDetails = await getNftTokenDetails(nftDetailsUri)
                        resDetailsList[i] = Object.assign(nftDetails, resDetailsList[i])
                    }
                    
                }
            }
                // resDetailsList = resDetailsList
                let list = []
                resDetailsList.forEach(element => {
                    if (element.name) {
                        let item = {}
                        item.createdTimestamp = element.attributes.createdTime
                        item.createdTime = dateCommonFormat(new Date(Number(element.attributes.createdTime)*1000), 'YYYY-mm-dd')
                        item.id = element.id
                        item.i18n_name = element.i18n_name
                        item.name = returnLocalize(element.name)
                        item.author = element.author
                        item.small_image = element.small_image
                        item.amount = Number(KeepSecimals(balanceFormat(element.attributes.amount, 18), 3))
                        
                        item.resBaseId = element.attributes.resBaseId
                        list.push(item)
                    }
                    
                });
                if (castingNFTShow.length) {
                    list = list.filter((item) => {
                        return castingNFTShow.indexOf(String(item.resBaseId)) > -1
                    })
                }
                
                list = list.sort((a, b) => {
                    return Number(b.createdTimestamp) - Number(a.createdTimestamp)
                });
                _this.setState({resDetailsList: list})
                let beginPage = Number(_this.state.curpage) - 1
                let endPage = beginPage + Number(_this.state.pagasize)
                let historyListDisplay = list.slice(beginPage, endPage)
                _this.setState({
                    historyListDisplay: historyListDisplay,
                    isLoadingHistoryList: false
                })
        })
    }
    openCastingNFT() {
        this.setState({isMaskCasting: true})
    }
    closeCastingNFT(val){
        this.setState({isMaskCasting: false})
    }
    parentConfirmCasting() {
        this.setState({isMaskCasting: false})
        this.initDate()
    }
    render() {
        return (
            <div className="casting-component">
                {
                    this.state.isMaskCasting?<CastingNFT props={this.props} listenClose={this.closeCastingNFT.bind(this)} listenConfirm={this.parentConfirmCasting.bind(this)}></CastingNFT>:""
                }
                
                <TopCompontent props={this.props} listenLanguage={this.listenLanguage.bind(this)} listenWallet={this.listenWallet.bind(this)}></TopCompontent>
                <div className="container-main">
                    <div className="casting-top-main">
                        <p className="casting-btn" onClick={()=>{
                            this.openCastingNFT()
                        }}>{this.t[i18next.language].casting[4]} NFT</p>
                    </div>
                
                    <table cellPadding="0" cellSpacing="0" border="0" className="table-compontent">
                        <thead>
                            <tr>
                                {
                                    this.t[i18next.language].casting.thead.map((item, key) => {
                                        return <td key={key}>{item}</td>
                                    })
                                }
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.historyListDisplay.map((val, key) => {
                                    return <tr key={key}>
                                    <td>
                                        <img alt="" className="icon" src={val.small_image} />
                                        {returnLocalize(val.i18n_name,i18next.language)}
                                    </td>
                                    <td>
                                        {val.id}
                                    </td>
                                    <td>
                                        {val.amount}
                                    </td>
                                    <td>
                                        {val.createdTime}
                                    </td>
                                </tr>
                                })
                            }
                           
                        </tbody>
                    </table>
                    {
                        this.state.isLoadingHistoryList?<div className="table-loading-main">
                        <div className="loading-big"></div>
                    </div>:""
                    }
                    
                    {
                        this.state.resDetailsList.length !== 0?<div>
                        <PagingComponent pagasize={this.state.pagasize} curpage={this.state.curpage} countnum={this.state.resDetailsList.length} pageTurning={this.pageTurning.bind(this)} />
                    </div>:""
                    }
                    
                </div>
               
            </div>
        )
    }
}