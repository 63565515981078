import React from 'react';
import './CastingNFT.css'
import Loading from "../base/Loading/Loading"
// import TopCompontent from '../base/TopCompontent/TopCompontent'
// import PagingComponent from '../base/PagingComponent/PagingComponent'
import starSelect from '../../static/images/casting/star-select.png'
import { lang } from "../../i18n/lang/lang"
import {
    castingNFT,
    castingToken,
    castingTLevelInfo,
    castingTokenInfo,
    castingHref
} from "../../config/casting.conf"
import {
    balanceFormat,
    returnLocalize,
    KeepSecimals,
    showHtmlText,
    getArrEqual,
    balanceFormatAdd
} from "../../libs/Util"
import {
    // getGegoBookList,
    getNftTokenDetails,
    getCocosBookList
} from "../../libs/Api"
import i18next from 'i18next'
import closeBtn from '../../static/images/common/close.png'
import decomposeBtn from '../../static/images/casting/decompose-btn.svg'
import dropIcon from '../../static/images/common/drop-icon.svg'
import backBtn from '../../static/images/common/back.svg'

import Swiper from "swiper";
// import 'swiper/dist/css/swiper.min.css'

import store from "../../stores";
const Store = store.store

export default class Casting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isLoadingApprove: false,
            isLoadingMint: false,
            isCompleteCasting: false,
            isShowWeakHintMain: false,
            isConfirmCasting: false,
            isMaskDrop: false,
            isApprove:{},
            myBalance:{},
            myBalanceDisplay: {},
            resDetails: null,
            curChooseNft: null,
            curChooseCastingToken: "",
            promptText: "",
            curTLevel: [],
            status: [],
            castingnft: [],
            castingnftDisplay: []
        }
        this.t = lang
        this.account = Store.getStore('global','account')
        this.localize  = Store.getStore("global", "localize")
        this.nftVer = "v1"
    }

    confirmBtn (){
        let _this = this
        let castingAmount = this.castinginput.value
        let curChooseNft = this.state.curChooseNft
        let curFee = this.state.curChooseNft.fee
        if (this.state.isLoadingMint) return
        if (!curChooseNft) {
            this.setState({isChooseNftCheck: false})
            return false
        } else {
            this.setState({isChooseNftCheck: true})
        }
        let myBalance = this.state.myBalance
        if (Number(this.state.myBalance.cocos) < Number(this.state.curFee) || Number(this.state.myBalance.cocos) === 0) {
            _this.setState({promptText: showHtmlText(_this.t[i18next.language].common["21"], "COCOS")}, () => {
                _this.showWeakHintMain()
            })
            return false
        }
        let curToken = this.state.curChooseCastingToken.tokenId
        if (this.state.myBalance[curToken] ===  0 || Number(castingAmount) > this.state.myBalance[curToken]) {
            
            _this.setState({promptText: showHtmlText(_this.t[i18next.language].common["21"], curToken.toUpperCase())}, () => {
                _this.showWeakHintMain()
            })
            return false
        }
        let newBookList = null
        if (curChooseNft.includeResBaseId && curChooseNft.includeResBaseId.length > 0) {
            let tokenResBaseId = 0
            let tokenResBaseIds = castingTokenInfo[curToken].includeResBaseId
            let nftResBaseIds = curChooseNft.includeResBaseId
            tokenResBaseId =getArrEqual(tokenResBaseIds, nftResBaseIds)
            
            newBookList = this.state.bookList.filter( res => {
                return String(res.attributes.resBaseId) === String(tokenResBaseId[0])
            })[0]
        }
        
        if (this.state.isDisabled) {
            _this.setState({ComingSoonIsClose: true})
            return false
        }
        
        if (Number(myBalance.cocos) < Number(curFee)) {
            _this.setState({promptText: showHtmlText(_this.t[i18next.language].common["21"], "COCOS")}, () => {
                _this.showWeakHintMain()
            })
            return false
        }
        
        if (!castingAmount || String(castingAmount) === "0") {
            _this.setState({promptText: _this.t[i18next.language].casting["1"]}, () => {
                _this.showWeakHintMain()
            })
            return false
        }
        let resBaseId = newBookList?newBookList.attributes.resBaseId:curChooseNft.resBaseId
        let tLevel = newBookList?newBookList.attributes.tLevel:curChooseNft.tLevel
        let ruleId = newBookList?newBookList.attributes.ruleId:curChooseNft.ruleId
        let nftType = newBookList?newBookList.attributes.nftType:curChooseNft.nftType

        let castingTLvInfo = castingTLevelInfo[curToken][tLevel]

        if (curChooseNft.min) {
            let min = 0
            // if (curToken === 'cocos') {
            //     min = Number(this.state.curFee)
            // }
            // min = min + Number(castingTLvInfo.min)
            if (castingTLvInfo.isIncludeMin) {
                
                min = Number(curChooseNft.min)
                if (Number(castingAmount) < min) {
                    // this.setState({isInputCheck: false})
                    _this.setState({promptText: _this.t[i18next.language].casting["3"]}, () => {
                        _this.showWeakHintMain()
                    })
                    return false
                }
            } else {
                min = Number(curChooseNft.min)
                if (Number(castingAmount) <= min) {
                    _this.setState({promptText: _this.t[i18next.language].casting["3"]}, () => {
                        _this.showWeakHintMain()
                    })
                    return false
                }
            }
        }
        
        if (curChooseNft.max) {
            let max = 0
            if (Number(myBalance[curToken]) > Number(curChooseNft.max)) {
                max = Number(curChooseNft.max)
            } else {
                max = Number(myBalance[curToken])
            }
            if (Number(castingAmount) > max) {

                _this.setState({promptText: _this.t[i18next.language].casting["2"]}, () => {
                    _this.showWeakHintMain()
                })
                return false
            }

            if (curToken === 'cocos') {
                if (Number(castingAmount) + Number(curFee) > Number(myBalance[curToken])) {
                    _this.setState({promptText: showHtmlText(_this.t[i18next.language].common["21"], "COCOS")}, () => {
                        _this.showWeakHintMain()
                    })
                    return false
                }
                
            }
        }

        // let min = Number(curChooseNft.min)
        // if (curToken === "cocos") {
        //     min = Number(curFee) + min
        // }
        if (Number(myBalance[curToken]) < Number(castingAmount)) {
            this.setState({isInputCheck: false})
            return false
        }
        let amountFormat = balanceFormatAdd(String(castingAmount), _this.returnDecimals(curToken))
        this.setState({isLoadingMint: true})
        
        Store.mintNft({
            nftVer: _this.nftVer,
            amount: amountFormat,
            resBaseId: resBaseId,
            ruleId: ruleId,
            nftType: nftType,
            tLevel: tLevel
        }).then(async res => {
            
            if (!res) {
                _this.setState({isLoadingMint: false})
                return false
            }
            let mintNftResult = res
            let resId = mintNftResult.events.CocosNFTAdded.returnValues.id
            let uri = await Store.getTokenURI({
                nftVer: _this.nftVer,
                nftId: resId
            })
            let resDetails = await getNftTokenDetails(uri)
            
            _this.setState({
                resDetails: resDetails,
                isLoadingMint: false,
                isCompleteCasting: true
            })

        })
    }



    chooseNftCasting (item){
        // let _this = this
        let castingnftDisplay = []
        let castingnft = this.state.castingnft
        for (let i = 0; i < castingnft.length; i++) {
            castingnft[i].isSelect = false
            if (castingnft[i].resBaseIdDisplay === item.resBaseIdDisplay) {
                castingnft[i].isSelect = true
            }
        }
        for(var i=0,len=castingnft.length;i<len;i+=6){
            castingnftDisplay.push(castingnft.slice(i,i+6));
        }
        this.setState({castingnftDisplay: castingnftDisplay})
        if (item.resBaseIdDisplay === "") {
            this.setState({curChooseNft: null})
            return
        }
        this.setState({curChooseNft: item})
        
        let curCastingToken = castingToken.filter((val)=>{
            return item.castingToken.indexOf(val.id) > -1
        })
        this.setState({curChooseCastingToken: curCastingToken[0]})
        this.setState({status: []},()=>{
            this.setState({status: curCastingToken})
        })
    }

    async allowanceNft(){
        let _this = this
        let isApprove = {}
        let balance = {}
        let myBalanceDisplay = {}

        // this.setState({isBusinessMask: true})
        let res = await Store.allowanceNft({ poolType: "cocos", nftVer: _this.nftVer})
        isApprove.cocos = String(res)
        let curCastingToken = this.state.curChooseNft.castingToken
        for (let i = 0; i < curCastingToken.length; i++) {
            let curCastingTokenId = curCastingToken[i]
            let curTokenId = castingToken.filter((item) => {
                return String(item.id) === String(curCastingTokenId)
            })[0]
            
            // if (curTokenId.tokenId === "cocos") continue
            let res = await Store.allowanceNft({ poolType: curTokenId.tokenId, nftVer: _this.nftVer})
            isApprove[curTokenId.tokenId] = res
            let balanceRes = await Store.getAccountBalanceOf(curTokenId.tokenId)
            if (String(balanceRes) === "0" ||  !balanceRes) {
                balance[curTokenId.tokenId] = 0
                myBalanceDisplay[curTokenId.tokenId] = "0.00"
            } else {
                balance[curTokenId.tokenId] =  balanceFormat(balanceRes, _this.returnDecimals(curTokenId.tokenId))
                myBalanceDisplay[curTokenId.tokenId] = KeepSecimals(balanceFormat(balanceRes, _this.returnDecimals(curTokenId.tokenId)), 3)
            }
        }
        _this.setState({
            isApprove: isApprove,
            isLoadingApprove: false,
            myBalance: balance,
            myBalanceDisplay: myBalanceDisplay
        })
        // _this.setState({isBusinessMask: false})
    }
    async getAccountBalanceOf(){
        let _this = this
        let balance = {}
        let myBalanceDisplay = {}
        for (let i = 0; i < castingToken.length; i++) {
            let poolType = castingToken[i].tokenId
            let res = await Store.getAccountBalanceOf(poolType)
            if (String(res) === "0" ||  !res) {
                balance[poolType] = 0
                myBalanceDisplay[poolType] = "0.00"
            } else {
                balance[poolType] =  balanceFormat(res, _this.returnDecimals(poolType))
                myBalanceDisplay[poolType] = KeepSecimals(balanceFormat(res, _this.returnDecimals(poolType)), 3)
            }
            
        }
        _this.setState({
            myBalance: balance,
            myBalanceDisplay: myBalanceDisplay
        })
    }

    confirmCasting(){
        let _this = this
        if (this.state.loading) return
        if (!this.state.curChooseNft) {
            this.setState({promptText: _this.t[i18next.language].casting["0"]}, () => {
                _this.showWeakHintMain()
            })
            return
        }
        this.setState({isConfirmCasting: true})
        this.allowanceNft()
    }

    goBack(){
        this.setState({isConfirmCasting: false})
        this.initCastingNft()
    }

    async initDate(){
        this.initCastingNft()
        this.getAccountBalanceOf()
    }

    returnDecimals(token) {
        // return config['Binance'][token].Decimals
        return 18
    }
    async initCastingNft(){
        let _this = this
        let castingnftDisplay = []

        this.setState({
            castingnftDisplay: [],
            loading: true
        })
        let castingNftList = this.state.castingnft
        if (castingNftList.length === 0) {
            let bookList = await getCocosBookList()
            bookList = bookList.result
            for (const key in castingNFT) {
                    let item = castingNFT[key]
                    if (item.resBaseIdDisplay) {
                        let newBookList = bookList.filter( res => {
                            return String(res.attributes.resBaseId) === String(item.resBaseIdDisplay)
                        })[0]
                        if (newBookList) {
                            if (newBookList.attributes) {
                                let tLevel = newBookList.attributes.tLevel
                                let ruleId = newBookList.attributes.ruleId
                                let nftType = newBookList.attributes.nftType
                                item.name = newBookList.name
                                item.tLevel = tLevel
                                item.ruleId = ruleId
                                item.nftType = nftType
                                let fee = await Store.getRuleData({
                                    ruleID: ruleId,
                                    nftVer: _this.nftVer
                                })
                                let minMintAmount = fee.minMintAmount
                                let maxMintAmount = fee.maxMintAmount
                                let costErc20Amount = fee.costErc20Amount
                                item.fee = Number(balanceFormat(String(costErc20Amount), _this.returnDecimals('cocos')))
                                item.min =  Number(balanceFormat(String(minMintAmount), _this.returnDecimals('cocos')))
                                item.max =  Number(balanceFormat(String(maxMintAmount), _this.returnDecimals('cocos')))
                                item.isSelect = false
                                
                            }
                        }
                    }
                    
                    
                    castingNftList.push(item)
            }
            this.setState({bookList: bookList})
        }
        


        for(var i=0,len=castingNftList.length;i<len;i+=6){
            castingnftDisplay.push(castingNftList.slice(i,i+6));
        }
        this.setState({
            castingnftDisplay: castingnftDisplay,
            castingnft: castingNftList,
            loading: false
        }, ()=> {

        
            new Swiper('.swiper-container', {
                autoplay: false,
                loop: false,
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
            });
        })
    }
    componentDidMount () {
        let initLevel = this.state.curTLevel
        for (let i = 0; i < 7; i++) {
            initLevel.push(false)
        }
        
        this.setState({curTLevel: initLevel})
        this.initCastingNft()
    }

    async listenWallet(){
        this.initDate()
    }

    listenLanguage(val) {
        this.setState({lang: i18next.language})
    }


    componentWillUnmount () {
        this.setState = (state, callback) => {
            return;
        };
    }

    confirmOption(item) {
        this.setState({curChooseCastingToken: item})
    }

    openDrop() {
        let curIsMaskDrop = this.state.isMaskDrop
        this.setState({isMaskDrop: !curIsMaskDrop})
    }
    closeCastingNFT(){
        this.props.listenClose(false)
    }
    completeCasting()  {
        this.props.listenConfirm()
    }


    castingJumpHref(tokenKey){
        if (typeof(castingHref[tokenKey]) === 'string') {
            window.open(castingHref[tokenKey])
        } else {
            window.open(castingHref[tokenKey][i18next.language])
        }
        
    }
    // poolType bot
    approveCasting(poolType){
        let _this = this
        if (this.state.isApprove[poolType] !== "0") {
            return false
        }

        this.setState({
            isLoadingApprove: true
        })
        if (this.state.isLoadingApprove) {
            return
        }
        Store.approveCasting({ poolType: poolType, nftVer: 'v1' }).then(res => {

            if (!res) {
                _this.setState({isLoadingApprove: false})
                return false
            }
            _this.allowanceNft()
        })
    }
    showWeakHintMain() {
        let _this = this
        if (this.state.isShowWeakHintMain) return
        this.setState({isShowWeakHintMain: true})
        setTimeout(() => {
            _this.setState({isShowWeakHintMain: false})
        }, 3000)
    }
    jumpMynftRouter() {
        this.props.props.history.push(`/mynft`)
    }
    jumpMiningRouter() {
        if (this.state.curChooseNft.miningId[0] === 'dividends') {
            this.props.props.history.push(`/${this.state.curChooseNft.miningId[0]}`)
        } else {
            this.props.props.history.push(`/${this.state.curChooseNft.miningId[0]}/${this.state.curChooseNft.miningId[1]}`)
        }
    }

    render() {
        return (
                <div className="mask">
                
                {
                    this.state.isShowWeakHintMain?<div className="weak-hint-main">{this.state.promptText}</div>:''
                }
                {
                    !this.state.isCompleteCasting?<div className="casting-nft-container">
                    <img alt="" className="close-btn" src={closeBtn} onClick={()=>{
                        this.closeCastingNFT()
                    }} />
                    {
                        this.state.isConfirmCasting?<img alt="" src={backBtn} onClick={()=>{
                            this.goBack()
                        }} className="back-btn" />:''
                    }
                    <p className="title">{this.t[i18next.language].casting['4']} NFT</p>
                    {
                        !this.state.isConfirmCasting?<div>
                        <div className="swiper-compontent">
                            <div className="swiper-compontent-main">

                                <div className="swiper-container swiper-no-swiping">
                                    <div className="swiper-wrapper">
                                        {
                                            this.state.castingnftDisplay.map((list, key) => {
                                                return <div className="swiper-slide" key={key}>
                                                    {
                                                        list.map((val, k) => {
                                                            return <div className={val.isSelect?"active nft-main":"nft-main"} onClick={()=>{
                                                                this.chooseNftCasting(val)
                                                            }} key={k}>
                                                                <ul className="portrait">
                                                                    <img alt="" src={val.isSelect?val.iconSelect:val.icon} />
                                                                </ul>
                                                                <ul className="nft-main-info">
                                                                    <p className="nft-name">
                                                                        {typeof(val.name)=== 'string'?returnLocalize(val.name,i18next.language):"-- Par"}
                                                                    </p>
                                                                    <p className="fee">{this.t[i18next.language].common['22']}: {val.fee?val.fee:"--"} COCOS</p>
                                                                </ul>
                                                            </div>
                                                        })
                                                    }
                                            </div>
                                            })
                                        }
                                    </div>
                                    <div className="swiper-button-prev swiper-button-black"></div>
                                    <div className="swiper-button-next swiper-button-black"></div>
                                </div>
                            </div>
                        </div>
                        <ul className="casting-confirm-btn" onClick={()=>{
                            this.confirmCasting()
                        }}>{this.state.loading?<Loading />:`${this.t[i18next.language].casting['4']} NFT`}</ul>
                    </div>:<div>
                        <div className="casting-NFT-details">
                            <ul className="decompose-btn">
                                <img alt="" src={decomposeBtn}  onClick={()=>{
                                        this.castingJumpHref("course")
                                    }} />
                            </ul>
                            <div className="casting-details-info">
                                <div className="casting-details-portrait">
                                    <img alt="" src={this.state.curChooseNft.icon} />
                                </div>
                                <div className="casting-info-container">
                                    <p className="casting-name">{returnLocalize(this.state.curChooseNft.name,i18next.language)}</p>
                                    <div className="casting-level">
                                        <ul className="level-display">T{this.state.curChooseNft.tLevel}</ul>
                                        {
                                    this.state.curTLevel.map((item, key) => {
                                        return <ul className="star-com" key={key}>
                                            {
                                                this.state.curChooseNft? 
                                                    Number(key) < Number(this.state.curChooseNft.tLevel)?<img alt="" src={starSelect} />:'':''
                                            }
                                    </ul>
                                    })
                                }
                                    </div>
                                    <p className="casting-fee">{this.t[i18next.language].common['22']}: {this.state.curChooseNft.fee} COCOS</p>
                                </div>
                            </div>
                            <div className="nft-info">
                                <p className="info-content">{this.state.curChooseCastingToken.name} {this.t[i18next.language].common['6']}: {this.state.curChooseNft.min}-{this.state.curChooseNft.max} </p>
                                <p className="buy-btn" onClick={()=>{
                                    this.castingJumpHref("cocos")
                                }}>Buy COCOS</p>
                            </div>
                        </div>
                        <div className="balance-bar">{this.t[i18next.language].common['5']}: {this.state.myBalanceDisplay?this.state.myBalanceDisplay[this.state.curChooseCastingToken.tokenId]:0}</div>
                        <div className="input-value-main">
                            <div className="drop-down-container" onClick={()=>{
                                    this.openDrop()
                                }}>
                                <p className="drop-down-display">{this.state.curChooseCastingToken.name}</p>
                                <img alt="" src={dropIcon} className="drop-icon" />
                                {
                                    this.state.isMaskDrop?<ul className="drop-down-list">
                                        {
                                            this.state.status.map((item, key) => {
                                                return <p className="drop-down-li" key={key} onClick={() => {
                                                    this.confirmOption(item)
                                                }}>{item.name}</p>
                                            })
                                        }
                                </ul>:""
                                }
                                
                            </div>
                            <p className="par-value-key">{this.state.curChooseCastingToken.name} {this.t[i18next.language].common['6']}: </p>
                            <input type="text" className="par-value-input" ref={castinginput => this.castinginput = castinginput}  />

                        </div>
                        {
                            String(this.state.isApprove.cocos) !== '0'&&String(this.state.isApprove[this.state.curChooseCastingToken.tokenId])!== '0' && this.state.isApprove.cocos?<div className="btn-bar confirm-btn-bar">
                            <div className="confirm-btn" onClick={()=>{
                                this.confirmBtn()
                            }}>{this.state.isLoadingMint?<Loading />:`${this.t[i18next.language].casting['4']} NFT`} </div>
                        </div>:<div className="btn-bar  approve-btn-bar">
                            {
                                <div className={String(this.state.isApprove.cocos) !== "0"?"approve-btn disable-btn":"approve-btn"} onClick={()=>{
                                    this.approveCasting('cocos')
                                }}>{this.state.isLoadingApprove?<Loading />:`${this.t[i18next.language].common['23']} COCOS`}</div>
                            }
                            {
                                this.state.curChooseCastingToken.tokenId !== "cocos"?this.state.isApprove[this.state.curChooseCastingToken.tokenId]?<div className="approve-btn disable-btn">{this.t[i18next.language].common['23']} {this.state.curChooseCastingToken.name}</div>:<div className="approve-btn">{this.t[i18next.language].common['23']} {this.state.curChooseCastingToken.name}</div>:""
                            }
                        </div>
                        }
                    </div>
                    }
                </div>:<div className="casting-success-tips">
                    <img alt="" className="close-btn" src={closeBtn} onClick={()=>{
                        this.closeCastingNFT()
                    }} />
                    <p className="title">{this.t[i18next.language].casting['5']}！</p>
                    <div className="nft-pic">
                        <img alt="" src={this.state.resDetails.small_image} />
                    </div>
                    <p className="ok-btn" onClick={()=>{
                        this.completeCasting()
                    }}>Ok</p>
                    <p className="router-nft" onClick={()=>{
                        this.jumpMynftRouter()
                    }}>{`${this.t[i18next.language].common['24']}>>>`}</p>
                    <p className="router-nft" onClick={()=>{
                        this.jumpMiningRouter()
                    }}>{`${this.t[i18next.language].common['28']}>>>`}</p>
                </div>
                }
                


                
            </div>
        )
    }
}