
export const RouterInfoConf = {
    "Home": {
        "path": "/",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "Referral": {
        "path": "/referral",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "BlindBox": {
        "path": "/blindbox",
        "bnb": true,
        "Binance": true,
        "kovan": true,
        "main": true,
        "tipId": "3"
    },
    "MyNft": {
        "path": "/mynft",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "MyNftDetails": {
        "path": "/mynftdetails/",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "FiveFu": {
        "path": "/fivefu",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "Mining": {
        "path": "/mining",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "MiningDetails": {
        "path": "/miningdetails/",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "Casting": {
        "path": "/casting",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "Dividends": {
        "path": "/dividends",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    },
    "DividendsDetails": {
        "path": "/dividendsdetails/",
        "bnb": true,
        "Binance": true,
        "kovan": false,
        "main": false,
        "tipId": "3"
    }
}