import config from "../config/config";
// import { netNetworkType } from "../config/netNetwork"
import { BinanceChainID } from "../config/tokenConfig"

// import { balanceFormat, KeepDecimals, timeFormat } from '../libs/Util'

import {
  CONFIGURE_RETURNED,
  // REGISTRY_REFERRAL,
  // GET_REFERRAL_NAME,
  // GET_REFERRAL_FEE,
  // GET_TOTAL_REFER_REWARD,
  // GET_PLAYER_INFO,
  // GET_BALANCE_OF,
  // GET_EARNED
} from '../constants/constants';

import Web3 from 'web3';

import {
  injected,
  // bscInjected,
  // walletconnect,
  // walletlink,
  // ledger,
  // trezor,
  // frame,
  // fortmatic,
  // portis,
  // squarelink,
  // torus,
  // authereum
} from "./connectors";


// import {
//   ChainId,
//   Token,
//   Route,
//   Fetcher,
//   Trade,
//   Percent,
//   TradeType,
//   TokenAmount,
//   Pair,
// } from '@uniswap/sdk'

import { maxUni256 } from "../config/judge"




// import { SSL_OP_NETSCAPE_CA_DN_BUG } from "constants";


const Dispatcher = require('flux').Dispatcher;
const Emitter = require('events').EventEmitter;

const dispatcher = new Dispatcher();
const emitter = new Emitter();

class Store {
  constructor() {

    this.store = {
      global: {
        connectorsByName: {
          MetaMask: injected,
          MathWallet: injected,
          CoinBase: injected
          // SafePal: injected,
          // TokenPocket: injected,
          // WalletConnect: walletconnect
        },
        exchangeRate: {},
        parentRefName: "",
        myRefName: "",
        registryRefFee: 0.1,
        account: '',
        autoConnect: true,
        myWeb3: null,
        mycontract: {},
        web3: null,
        network: "",
        referralName: '',
        networkType: '',
        isAuthorized: false,
        currentIsLP: false,
        web3context: null,
        languages: [
          {
            language: 'English',
            code: 'en'
          },
        ],
      },
      nft: {},
      currentNftDetailsId: '',
      balanceToken: {
        lpToken: ''
      },
      isApprove: {},
      pairInfo: {},
      test: 'uio',
      rewardPerToken: {},

      startTime: {},

      duration: {},

      totalSupply: {},

      currentOrePool: '23',

      balanceOf: {},

      balanceOfPower: {},

      totalBurnToken: {},

      exchangeRate: {},

      rewardPoolAddress: {},

      totalPower: {},

      hoeTokenAddress: {},

      rewardRate: {},

      reserves: {},

      stageSoldAmount: {},

      earned: {},

      pair: {},

      decimals: {},

      contractFactroy: {}

    }

    // dispatcher.register(
    //   function (payload) {
    //     switch (payload.type) {
    //       case REGISTRY_REFERRAL:
    //         this.registryReferral(payload);
    //         break;
    //       case GET_REFERRAL_NAME:
    //         this.getReferralName(payload);
    //         break;
    //       case GET_REFERRAL_FEE:
    //         this.getReferralFee(payload);
    //         break;
    //       case GET_TOTAL_REFER_REWARD:
    //         this.getTotalReferReward(payload);
    //         break;
    //       case GET_BALANCE_OF:
    //         this.getBalanceOf(payload);
    //         break;
    //       case GET_EARNED:
    //         this.getEarned(payload);
    //         break;
    //       case GET_PLAYER_INFO:
    //         this.getPlayerInfo(payload);
    //       default: {
    //       }
    //     }
    //   }.bind(this)
    // );
  }

  getWeb3Provider() {
    if (this.store["global"]["web3context"] && this.store["global"]["web3context"].library) {
      return this.store["global"]["web3context"].library.provider;
    }
    return null;
  }

  getStore(mainKey, subKey) {

    if (this.store[mainKey]) {
      return (this.store[mainKey][subKey]);
    }
    else {
      return null;
    }
  };

  setStore(mainKey, obj) {

    let keys = [];
    for (let key in obj) {
      keys.push(key);
    }

    let subKey = keys[0]
    if (this.store[mainKey]) {

      this.store[mainKey][subKey] = obj[subKey]
    }
    else {
      this.store[mainKey] = {};
      this.store[mainKey][subKey] = obj[subKey]
    }
    return emitter.emit('StoreUpdated');
  };


  getWeb3() {
    let myWeb3 = store.getStore("global", "myWeb3")
    if (myWeb3) {
      return myWeb3
    } else {
      myWeb3 = new Web3(store.getWeb3Provider());
      store.setStore("global", { 'myWeb3': myWeb3 })
      return myWeb3
    }
  }


  getBaseContract(poolType) {
    // let networkType = store.getStore("global", 'networkType')
    let web3context = store.getStore('global' ,'web3context')
    if (!web3context ) {
      console.log('web3context is emtpy')
      return null;
    }
    let networkType = BinanceChainID[web3context.chainId]
    let abi = config[networkType][poolType].ABI
    let contractAddress = config[networkType][poolType].address
    if (!abi || !contractAddress ) {
      console.log('abi contractAddress is emtpy')
      return null;
    }
    return this.createContract(abi, contractAddress);
  }
  
  
  getNftContract(nftVer) {
    // let networkType = store.getStore("global", 'networkType')
    let web3context = store.getStore('global' ,'web3context')
    let networkType = BinanceChainID[web3context.chainId]
    let abi = config[networkType][nftVer]['nft'].ABI
    let contractAddress = config[networkType][nftVer]['nft'].address
    if (!abi || !contractAddress) {
      console.log('abi contractAddress is emtpy')
      return null;
    }
    return this.createContract(abi, contractAddress);
  }
  getMiningContract(miningPool) {
    // let networkType = store.getStore("global", 'networkType')
    let web3context = store.getStore('global' ,'web3context')
    let networkType = BinanceChainID[web3context.chainId]
    let abi = config[networkType].mining[miningPool].ABI
    let contractAddress = config[networkType].mining[miningPool].address
    if (!abi || !contractAddress) {
      console.log('abi contractAddress is emtpy')
      return null;
    }
    return this.createContract(abi, contractAddress);
  }
  
  getFactoryContract(nftVer) {
    // let networkType = store.getStore("global", 'networkType')

    let web3context = store.getStore('global' ,'web3context')
    let networkType = BinanceChainID[web3context.chainId]
    let abi = config[networkType][nftVer].factory.ABI
    let contractAddress = config[networkType][nftVer].factory.address
    if (!abi || !contractAddress) {
      console.log('abi contractAddress is emtpy')
      return null;
    }
    return this.createContract(abi, contractAddress);
  }

  getBaseProxyContract(nftVer) {
    // let networkType = store.getStore("global", 'networkType')

    let web3context = store.getStore('global' ,'web3context')
    let networkType = BinanceChainID[web3context.chainId]
    let abi = config[networkType][nftVer]['baseProxy'].ABI
    let contractAddress = config[networkType][nftVer]['baseProxy'].address
    if (!abi || !contractAddress) {
      console.log('abi contractAddress is emtpy')
      return null;
    }
    return this.createContract(abi, contractAddress);
  }

  getDividendsContract() {
    let web3context = store.getStore('global' ,'web3context')
    let networkType = BinanceChainID[web3context.chainId]
    let abi = config[networkType].Dividends.ABI
    let contractAddress = config[networkType].Dividends.address
    if (!abi || !contractAddress) {
      console.log('abi contractAddress is emtpy')
      return null;
    }
    return this.createContract(abi, contractAddress);
  }

  getDividendsTeamContract() {
    let web3context = store.getStore('global' ,'web3context')
    let networkType = BinanceChainID[web3context.chainId]
    let abi = config[networkType].DividendsTeam.ABI
    let contractAddress = config[networkType].DividendsTeam.address
    if (!abi || !contractAddress) {
      console.log('abi contractAddress is emtpy')
      return null;
    }
    return this.createContract(abi, contractAddress);
  }

  createContract(abi, contractAddress) {
    if (this.store.contractFactroy[contractAddress]) {
      return this.store.contractFactroy[contractAddress];
    }
    let web3 = this.getWeb3()
    if (web3 === null) {
      console.log('web3 is emtpy')
      return null;
    }

    this.store.contractFactroy[contractAddress] = new web3.eth.Contract(abi, contractAddress);
    this.store.contractFactroy[contractAddress].address = contractAddress;
    this.store.contractFactroy[contractAddress].abi = abi;

    return this.store.contractFactroy[contractAddress];
  }

  configure = async () => {
    const web3 = new Web3(store.getWeb3Provider());
    const currentBlock = await web3.eth.getBlockNumber()
    store.setStore("global", { currentBlock: currentBlock })
    window.setTimeout(() => {
      emitter.emit(CONFIGURE_RETURNED)
    }, 100)
  }

  updateNetwork = async () => {
    // let isAuthorized = await injected.isAuthorized()
    await injected.isAuthorized()
    let a = await injected.activate()
    return a
  }

  getCurNetworkType = async () => {
    let networkType = await store.getNetworkType()
    if (networkType) {
      return networkType
    } else {
      let web3context = store.getStore('global' ,'web3context')
      if (!web3context) {
        console.log("web3context is emtry")
        return
      }
      networkType = BinanceChainID[web3context.chainId]
      return networkType
    }
  }

  getNetworkType = async () => {
    let context = store.getWeb3Provider()
    let networkType = ""
    if (context != null) {
      if (context.library) {
        const web3 = await new Web3(context.library.provider)
        let getId = await web3.eth.net.getId()
        if (getId ===  97 || getId ===  56) {
          networkType = BinanceChainID[getId]
        } else {
          networkType = await web3.eth.net.getNetworkType()
        }
        return networkType
      }
      else {
        const web3 = await new Web3(context)
        let getId = await web3.eth.net.getId()
        if (getId ===  97 || getId ===  56) {
          networkType = BinanceChainID[getId]
        } else {
          networkType = await web3.eth.net.getNetworkType()
        }
        return networkType
      }
    } else {
      return null
    }
  }

// ================================


  getReferralFee = async () => {

    return new Promise(async (resolve) => {

      const account = store.getStore('global', 'account')

      let networkType = await store.getCurNetworkType()
      if (!networkType) return
      let abi = config[networkType]["common"].playerBookABI
      let address = config[networkType]["common"].playerBookAddress
      let contract = this.createContract(abi, address)
      
      try {
        let registrationFee = await contract.methods.getRegistrationFee().call({ from: account });
        store.setStore("global", { 'registryRefFee': registrationFee })

        resolve(registrationFee)

      } catch (ex) {
        console.log('error', ex)
        resolve(false)
      }
    })

  }
  registerNameXName = (payload) => {

    return new Promise(async (resolve) => {
      try {
        let { refName } = payload
        
        const registryRefFee = store.getStore('global', 'registryRefFee')
        
        const account = store.getStore('global', 'account')
        let web3 = store.getStore('global', 'myWeb3')
        let value = web3.utils.toWei(registryRefFee, "wei")
        let networkType = await store.getCurNetworkType()
        if (!networkType) return
        let abi = config[networkType]["common"].playerBookABI
        let address = config[networkType]["common"].playerBookAddress
        let contract = this.createContract(abi, address)

        let parentRefName = localStorage.getItem('parentRefName')
        if (!parentRefName) {
          parentRefName = ''
        }
        
        let result = await contract.methods.registerNameXName(refName, parentRefName).send({
          from: account,
          value: value
        })
        resolve(result)
      } catch (ex) {
        console.log('error', ex)
        resolve(false)
      }
    })
  }

  getReferralName = async () => {

    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        const networkType = await store.getCurNetworkType()
        if (!networkType) return
        let abi = config[networkType]["common"].playerBookABI
        let address = config[networkType]["common"].playerBookAddress
        let contract = this.createContract(abi, address)
        let nameASCII = await contract.methods.getPlayerName(account).call({ from: account });
        //let web3 = store.getStore('global','myWeb3')
        let myWeb3 = store.getStore("global", "myWeb3")
        let name = myWeb3.utils.toAscii(nameASCII);
        store.setStore("global", { 'myRefName': name })
        resolve(name)
      }
      catch (ex) {
        console.log('-----..........', ex)
        resolve(false)
      }
    })
  }


  playerBookClaim = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        const networkType = await store.getCurNetworkType()
        if (!networkType) return
        let abi = config[networkType]["common"].playerBookABI
        let address = config[networkType]["common"].playerBookAddress
        let contract = this.createContract(abi, address)
        let result = await contract.methods.claim().send({
          from: account,
        })
        resolve(result)
      }
      catch (ex) {
        console.log('-----..........', ex)
        resolve(false)
      }
    })
  }

  getPlayerInfo = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        const networkType = await store.getCurNetworkType()
        if (!networkType) return
        let abi = config[networkType]["common"].playerBookABI
        let address = config[networkType]["common"].playerBookAddress
        let contract = this.createContract(abi, address)
        let info = await contract.methods.getPlayerInfo(account).call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error....", ex)
        resolve(false)
      }
    }) 
  }

  getIsClaimStart = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('blindbox')
        let info = await contract.methods._isClaimStart().call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error...._isClaimStart", ex)
        resolve(false)
      }
    }) 
  }


  getClaimMembers = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('blindbox')
        let info = await contract.methods._claimMembers(account).call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error...._claimMembers", ex)
        resolve(false)
      }
    }) 
  }
  getIsWhitelist = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('blindbox')
        let info = await contract.methods._whitelist(account).call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error...._whitelist", ex)
        resolve(false)
      }
    }) 
  }

  claimBox = async () => {
    return new Promise(async (resolve) => {
      let gasLimit = 650000
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('blindbox')
        let info = await contract.methods.claim().send({
          from: account,
          // gasPrice: web3.utils.toHex(gasPrice),
          gasLimit: Web3.utils.toHex(gasLimit)
        });
        resolve(info)
      } catch (ex) {
        console.log("error....claim", ex)
        resolve(false)
      }
    }) 
  }

  getTokensOfOwner = async (nftVer) => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getNftContract(nftVer)
        let info = await contract.methods.tokensOfOwner(account).call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error....getTokensOfOwner", ex)
        resolve(false)
      }
    }) 
  }

  getTokenURI = async (payloads) => {
    return new Promise(async (resolve) => {
      try {
        let {nftVer, nftId} = payloads
        const account = store.getStore('global', 'account')
        let contract = this.getNftContract(nftVer)
        let info = await contract.methods.tokenURI(nftId).call({ from: account });
        resolve(info)
      } catch (ex) {
        resolve(`https://finance.cocosbcx.io/bsccommon/cocos/nft/${payloads.nftId}`)
        // console.log("error....getTokenURI", ex)
        // resolve(false)
      }
    }) 
  }

  transferFromNft = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { nftVer, toAddress, tokenId } = payload
        let contract = this.getNftContract(nftVer)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.transferFrom(account, toAddress, tokenId).send({
          from: account
        })
        resolve(result)
      } catch (ex) {
        resolve(false)
      }
    })
  }

  getCommonBalance = (token) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getBaseContract(token)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.balanceOf(account).call({ from: contract.address });
        resolve(result)
      } catch (ex) {
        console.log("getCommonBalance", token, ex)
        resolve(false)
      }
    })
  }

  getETHBalance = () => {
    return new Promise(async (resolve) => {
      try {
        let web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/9cf149c39e364cdabc3d5129fa7fa1af"));
        const account = store.getStore('global', 'account')
        web3.eth.getBalance(account).then(res => {
          resolve(res)
        })
      } catch (error) {
        console.log('getETHBalance', error)
        resolve(false)
      }
      
    })
  }

  getWalletBalance = () => {
    return new Promise(async (resolve) => {
      const networkType = await store.getCurNetworkType()
      if (!networkType) return
      let nodeUri = config[networkType].nodeUri
      try {
        let web3 = new Web3(new Web3.providers.HttpProvider(nodeUri));
        const account = store.getStore('global', 'account')
        web3.eth.getBalance(account).then(res => {
          resolve(res)
        })
      } catch (error) {
        console.log('getETHBalance', error)
        resolve(false)
      }
      
    })
  }


  getFivefuNFT = async (tokenId) => {

    return new Promise(async (resolve) => {

      const account = store.getStore('global', 'account')

      let networkType = await store.getCurNetworkType()
      if (!networkType) return
      let abi = config[networkType].fiveFu.factory.ABI
      let address = config[networkType].fiveFu.factory.address
      let contract = this.createContract(abi, address)
      
      try {
        let registrationFee = await contract.methods.getCocosNFT(Number(tokenId)).call({ from: account });
        resolve(registrationFee)
      } catch (ex) {
        console.log('error', ex)
        resolve(false)
      }
    })

  }


  mergeFiveBlessing = async (cocosNFTIds) => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('blindbox')
        let info = await contract.methods.mergeFiveBlessing(cocosNFTIds).send({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error....claim", ex)
        resolve(false)
      }
    }) 
  }

  fiveBlessingEndTime = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('blindbox')
        let info = await contract.methods._fiveBlessingEndTime().call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error....fiveBlessingEndTime", ex)
        resolve(false)
      }
    }) 
  }

  fiveBlessingTotals = async (cocosId) => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('blindbox')
        let info = await contract.methods.fiveBlessingTotals(cocosId).call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error....fiveBlessingTotals", ex)
        resolve(false)
      }
    }) 
  }
  

  getApprovedForAll = async (payload) => {
    return new Promise(async (resolve) => {
      let { nftType, apprAddrKey } = payload
      nftType = nftType || 'v1'
      const account = store.getStore('global', 'account')
      try {
        let networkType = await store.getCurNetworkType()
        if (!networkType) return
        let abi = config[networkType][nftType].nft.ABI
        let contractAddress = config[networkType][nftType].nft.address
        let address = config[networkType][apprAddrKey].address
        let contract = this.createContract(abi, contractAddress)
        var result = await contract.methods.isApprovedForAll(account, address).call({ from: contractAddress });
        resolve(result)
      } catch (ex) {
        console.log("getApprovedForAll.....", ex)
        resolve(false)
      }
    })
  }


  setApprovedForAll = async (payload) => {
    return new Promise(async (resolve) => {
      let { nftType, apprAddrKey } = payload
      const account = store.getStore('global', 'account')
      try {
        let networkType = await store.getCurNetworkType()
        if (!networkType) return
        let abi = config[networkType][nftType].nft.ABI
        let contractAddress = config[networkType][nftType].nft.address
        let address = config[networkType][apprAddrKey].address
        let contract = this.createContract(abi, contractAddress)
        let result = await contract.methods.setApprovalForAll(address, true).send({ from: account });
        resolve(result)
      } catch (ex) {
        console.log("setApprovedForAll.....", ex)
        resolve(false)
      }
    })
  }
  

  getMiningApprovedForAll = async (payload) => {
    return new Promise(async (resolve) => {
      let { nftVer, apprAddrKey } = payload
      const account = store.getStore('global', 'account')
      try {
        let networkType = await store.getCurNetworkType()
        if (!networkType) return
        let abi = config[networkType][nftVer].nft.ABI
        let contractAddress = config[networkType][nftVer].nft.address
        let address = config[networkType].mining[apprAddrKey].address
        let contract = this.createContract(abi, contractAddress)
        var result = await contract.methods.isApprovedForAll(account, address).call({ from: contractAddress });
        resolve(result)
      } catch (ex) {
        console.log("getApprovedForAll.....", ex)
        resolve(false)
      }
    })
  }


  setMiningApprovedForAll = async (payload) => {
    return new Promise(async (resolve) => {
      let { nftVer, apprAddrKey } = payload
      const account = store.getStore('global', 'account')
      try {
        let networkType = await store.getCurNetworkType()
        if (!networkType) return
        let abi = config[networkType][nftVer].nft.ABI
        let contractAddress = config[networkType][nftVer].nft.address
        let address = config[networkType].mining[apprAddrKey].address
        let contract = this.createContract(abi, contractAddress)
        let result = await contract.methods.setApprovalForAll(address, true).send({ from: account });
        resolve(result)
      } catch (ex) {
        console.log("setApprovedForAll.....", ex)
        resolve(false)
      }
    })
  }

  getFactoryApprovedForAll = async (payload) => {
    return new Promise(async (resolve) => {
      let { nftVer } = payload;
      const account = store.getStore('global', 'account')
      try {
        let networkType = store.getStore('global', 'networkType')
        let abi = config[networkType][nftVer].nft.ABI
        let contractAddress = config[networkType][nftVer].nft.address
        let address = config[networkType][nftVer].factory.address
        let contract = this.createContract(abi, contractAddress)
        var result = await contract.methods.isApprovedForAll(account, address).call({ from: contractAddress });
        resolve(result)
      } catch (ex) {
        console.log("getFactoryApprovedForAll....", ex)
        resolve(false)
      }
    })
  }


  setFactoryApprovedForAll = async (payload) => {
    return new Promise(async (resolve) => {
      let { nftVer } = payload;
      const account = store.getStore('global', 'account')
      try {
        let networkType = store.getStore('global', 'networkType')
        let abi = config[networkType][nftVer].nft.ABI
        let contractAddress = config[networkType][nftVer].nft.address
        let address = config[networkType][nftVer].factory.address
        
        let contract = this.createContract(abi, contractAddress)
        let result = await contract.methods.setApprovalForAll(address, true).send({ from: account });
        resolve(result)
      } catch (ex) {
        console.log("setFactoryApprovedForAll....", ex)
        resolve(false)
      }
    })
  }



  fiveBlessingReward = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('blindbox')
        let info = await contract.methods._fiveBlessingReward().call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error...._fiveBlessingReward", ex)
        resolve(false)
      }
    }) 
  }


  getMiningTotalPower = (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getMiningContract(miningPool)
        let result = await contract.methods.totalSupply().call({ from: contract.address });
        resolve(result)
      } catch (ex) {
        console.log("error....getMiningTotalPower", ex)
        resolve(false)
      }
    })
  }

  getMiningInitReward = (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getMiningContract(miningPool)
        let result = await contract.methods.initReward().call({ from: contract.address });
        resolve(result)
      } catch (ex) {
        console.log("error....getMiningTotalPower", ex)
        resolve(false)
      }
    })
  }
  
  getMingingStartTime = (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getMiningContract(miningPool)
        let result = await contract.methods.startTime().call({ from: contract.address });
        resolve(result)
      } catch (ex) {
        console.log("error....getMingingStartTime", ex)
        resolve(false)
      }
    })
  }
  
  
  getMingingPeriodFinish = (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getMiningContract(miningPool)
        let result = await contract.methods.periodFinish().call({ from: contract.address });
        resolve(result)
      } catch (ex) {
        console.log("error....getMingingPeriodFinish", ex)
        resolve(false)
      }
    })
  }
  
  getMingingEarned = (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getMiningContract(miningPool)
        let result = await contract.methods.earned(account).call({ from: account });
        resolve(result)
      } catch (ex) {
        console.log("error....getMingingEarned", ex)
        resolve(false)
      }
    })
  }

  stakeCowNFT = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { miningPool, cowNFTId } = payload
        const account = store.getStore('global', 'account')
        let contract = this.getMiningContract(miningPool)
        let affCode = localStorage.getItem("parentRefName")
        if (!affCode) {
          affCode = "";
        }
        let result = await contract.methods.stakeCowNFT(cowNFTId, affCode).send({ from: account });
        resolve(result)
      } catch (ex) {
        console.log("error....getMingingEarned", ex)
        resolve(false)
      }
    })
  }

  withdrawCowNFT = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { miningPool, cowNFTId } = payload
        const account = store.getStore('global', 'account')
        let contract = this.getMiningContract(miningPool)
        let result = await contract.methods.withdrawCowNFT(cowNFTId).send({ from: account });
        resolve(result)
      } catch (ex) {
        console.log("error....withdrawCowNFT", ex)
        resolve(false)
      }
    })
  }
  exit = (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getMiningContract(miningPool)
        let result = await contract.methods.exit().send({ from: account });
        resolve(result)
      } catch (ex) {
        console.log("error....getReward", ex)
        resolve(false)
      }
    })
  }
  getReward = (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getMiningContract(miningPool)
        let result = await contract.methods.getReward().send({ from: account });
        resolve(result)
      } catch (ex) {
        console.log("error....getReward", ex)
        resolve(false)
      }
    })
  }

  getMyNftPlayerIds = (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let networkType = await store.getCurNetworkType()
        if (!networkType) return
        let contract = this.getMiningContract(miningPool)
        let address = config[networkType].mining[miningPool].address
        let result = await contract.methods.getPlayerIds(account).call({ from: address });
        resolve(result)
      } catch (ex) {
        console.log("error....getMingingEarned", ex)
        resolve(false)
      }
    })
  }

  getNftStakeInfo = async (payload) => {
    return new Promise(async (resolve) => {
      let { miningPool, nftId } = payload;
      // const account = store.getStore('global', 'account')
      try {
        let networkType = await store.getCurNetworkType()
        if (!networkType) return
        let address = config[networkType].mining[miningPool].address
        let contract = this.getMiningContract(miningPool)
        var result = await contract.methods.getStakeInfo(nftId).call({ from: address });
        resolve(result)
      } catch (ex) {
        console.log("getNftStakeInfo....", ex)
        resolve(false)
      }
    })

  }

  getBaseNftStakeInfo = async (payload) => {
      return new Promise(async (resolve) => {
        try {
          let { poolType, nftId } = payload
          let networkType = await store.getCurNetworkType()
          let address = config[networkType][poolType].address
          let contract = this.getBaseContract(poolType)
          let result = await contract.methods.getStakeGegoInfo(nftId).call(
            {
              from: address
            }
          );
          resolve(result)
        } catch (ex) {
          console.log("getBaseNftStakeInfo ex...", ex)
          resolve(false)
        }
      })

  }

  getLastStakedTime = async (miningPool) => {
    return new Promise(async (resolve) => {
      const account = store.getStore('global', 'account')
      try {
        let networkType = await store.getCurNetworkType()
        if (!networkType) return
        let address = config[networkType].mining[miningPool].address
        let contract = this.getMiningContract(miningPool)
        var result = await contract.methods.lastStakedTime(account).call({ from: address });
        resolve(result)
      } catch (ex) {
        resolve(false)
      }
    })
  }

  getMiningPunishTime = async (miningPool) => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getMiningContract(miningPool)
        var result = await contract.methods.punishTime().call({ from: account });
        resolve(result)
      } catch (ex) {
        resolve(false)
      }
    })
  }

  burn = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { nftVer, tokenId } = payload
        let contract = this.getFactoryContract(nftVer)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.burn(tokenId).send({
          from: account
        })
        resolve(result)
      } catch (ex) {
        resolve(false)
        // return emitter.emit(ERROR, ex);
      }
    })
  }

  mintNft = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { nftVer, amount, nftType, resBaseId, ruleId, tLevel } = payload
        let contract =this.getFactoryContract(nftVer)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.mint([amount, resBaseId, nftType, ruleId, tLevel], [0,0,0,"0x0000000000000000000000000000000000000000"], [0,0,0,"0x0000000000000000000000000000000000000000"]).send(
          {
            from: account
          }
        )
        resolve(result)
      } catch (ex) {
        console.log('mintNft', ex)
        resolve(false)
        // return emitter.emit(ERROR, ex);
      }
    })
  }



//  --------------------
  getRuleData = async (payload) => {
    let { ruleID, nftVer } = payload
    return new Promise(async (resolve) => {

      let contract = this.getBaseProxyContract(nftVer);
      const account = store.getStore('global', 'account')
      try {
        let result = await contract.methods._ruleData(ruleID).call(
          {
            from: account
          })
        resolve(result)
      }
      catch (ex) {
        console.log('-----..........', ex)
        resolve(false)
      }
    })
  }

  allowanceNft = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, nftVer } = payload

        const account = store.getStore('global', 'account')
        let networkType = store.getStore("global", 'networkType')
        let abi = config[networkType][poolType].ABI;
        let contractAddress = config[networkType][poolType].address;
        let contract = this.createContract(abi, contractAddress)
        const baseProxyAddress = config[networkType][nftVer].baseProxy.address
        let result = await contract.methods.allowance(account, baseProxyAddress).call({ from: account })
        resolve(result)
      } catch (ex) {
        console.log("e x", ex)
        resolve(false)
        // return emitter.emit(ERROR, ex);
      }
    })
  }


  allowanceBase = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, allowanceAddrKey } = payload
        const account = store.getStore('global', 'account')
        let networkType = store.getStore("global", 'networkType')
        let contract = this.getBaseContract(poolType)
        const allowanceAddress = config[networkType][allowanceAddrKey].address
        let result = await contract.methods.allowance(account, allowanceAddress).call({ from: account })
        resolve(result)
      } catch (ex) {
        console.log("e x", ex)
        resolve(false)
        // return emitter.emit(ERROR, ex);
      }
    })
  }


  approveBase = async (payload) => {
    return new Promise(async (resolve) => {
      let { poolType, approveAddrKey } = payload;

      let networkType = store.getStore('global', 'networkType')
      let contract = this.getBaseContract(poolType)
      const approveAddress = config[networkType][approveAddrKey].address

      const account = store.getStore('global', 'account')

      try {
        let result = await contract.methods.approve(approveAddress, maxUni256).send(
          {
            from: account
          })

        resolve(result)
        //return result
      }
      catch (ex) {
        console.log("")
        resolve(false)
      }
    })
  }

  getAccountBalanceOf = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let networkType = store.getStore('global', 'networkType')
        let address = config[networkType][payload].address
        // let abi = config[networkType][poolType].stakeTokenABI
        // let contract = this.createContract(abi, address)
        let contract = this.getBaseContract(payload)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.balanceOf(account).call({ from: address })
        resolve(result)
      } catch (ex) {
        console.log('getAccountBalanceOf err>>>>>', ex)
        resolve(false)
        // return emitter.emit(ERROR, ex);
      }
    })
  }


  getRuleProxys = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { nftVer, nftType } = payload
        const account = store.getStore('global', 'account')
        let contract = this.getFactoryContract(nftVer)
        let result = await contract.methods._ruleProxys(nftType).call({
          from: account
        })
        resolve(result)
      } catch (ex) {
        console.log("ex..", ex)
        resolve(false)
      }
    })
  }

  getAutoRuleData = async (payload) => {
    let { ruleID, proxyAddress, nftVer } = payload
    return new Promise(async (resolve) => {

      let networkType = store.getStore('global', 'networkType')
      // const contractAddress = config[networkType]['v2']['gegoFactory'].baseProxyAddress
      const contractAddress = proxyAddress
      const baseProxyABI = config[networkType][nftVer]['baseProxy'].ABI
      let contract = this.createContract(baseProxyABI, contractAddress);

      const account = store.getStore('global', 'account')

      try {
        let result = await contract.methods._ruleData(ruleID).call(
          {
            from: account
          })
        resolve(result)
        //return result
      }
      catch (ex) {
        resolve(false)
        console.log('-----..........', ex)
      }
    })
  }



  approveCasting = async (payload) => {
    return new Promise(async (resolve) => {
      let { poolType, nftVer } = payload;

      let networkType = store.getStore('global', 'networkType')
      let abi = config[networkType][poolType].ABI;
      let contractAddress = config[networkType][poolType].address;
      let contract = this.createContract(abi, contractAddress)
      const baseProxyAddress = config[networkType][nftVer].baseProxy.address

      const account = store.getStore('global', 'account')

      try {
        let result = await contract.methods.approve(baseProxyAddress, maxUni256).send(
          {
            from: account
          })

        resolve(result)
        //return result
      }
      catch (ex) {
        resolve(false)

        // console.log('-----..........',poolType, ex)
        // return emitter.emit(ERROR, ex);
      }
    })
  }

  
  getDividentStartTime = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('Dividends')
        let info = await contract.methods._startTime().call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error....getDividentStartTime", ex)
        resolve(false)
      }
    }) 
  }


  getDividentPeriodFinish = async () => {
    return new Promise(async (resolve) => {
      try {
        const account = store.getStore('global', 'account')
        let contract = this.getBaseContract('Dividends')
        let info = await contract.methods._periodFinish().call({ from: account });
        resolve(info)
      } catch (ex) {
        console.log("error....getDividentPeriodFinish", ex)
        resolve(false)
      }
    }) 
  }

  isHeros = (nftId) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getBaseContract('Dividends')
        const account = store.getStore('global', 'account')
        let result = await contract.methods._heros(nftId).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("_heros ex...", ex)
        resolve(false)
      }
    })
  }


  // stakeHero = (nftId) => {
  //   return new Promise(async (resolve) => {
  //     try {
  //       let contract = this.getBaseContract('Dividends')
  //       const account = store.getStore('global', 'account')
  //       let affName = localStorage.getItem("parentRefName")
  //       if (!affName) {
  //         affName = "";
  //       }
        
  //       let result = await contract.methods.stakeHero(nftId, affName).send(
  //         {
  //           from: account
  //         }
  //       );
  //       resolve(result)
  //     } catch (ex) {
  //       console.log("stakeHero ex...", ex)
  //       resolve(false)
  //     }
  //   })
  // }

  getTeamIndexes = () => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getBaseContract('DividendsTeam')
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getTeamIndexes().call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getTeamIndexes ex...", ex)
        resolve(false)
      }
    })
  }

  getTeams = (heroId) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getBaseContract('DividendsTeam')
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getTeams(heroId).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getTeams ex...", ex)
        resolve(false)
      }
    })
  }

  getTeamTotalInitReward = (heroId) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getBaseContract('DividendsTeam')
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getTeamTotalInitReward(heroId).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getTeamTotalInitReward ex...", ex)
        resolve(false)
      }
    })
  }

  getBasePeriodFinish(payload){
    return new Promise(async (resolve) => {
      try {
        let { poolType } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods._periodFinish().call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getAccTotalRewards ex...", ex)
        resolve(false)
      }
    })
  }

  getBaseDuration(payload){
    return new Promise(async (resolve) => {
      try {
        let { poolType } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods._duration().call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("_duration ex...", ex)
        resolve(false)
      }
    })
  }
  
  getAccPowerBalances = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getAccPowerBalances(heroId, account).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getAccPowerBalances ex...", ex)
        resolve(false)
      }
    })
  }

  getDividendEarned = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.earned(heroId, account).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getDividendEarned ex...", ex)
        resolve(false)
      }
    })
  }
  
  getAccTotalRewards = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getAccTotalRewards(heroId, account).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getAccTotalRewards ex...", ex)
        resolve(false)
      }
    })
  }

  getAccPlayerGego = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getAccPlayerGego(heroId, account).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getAccPlayerGego ex...", ex)
        resolve(false)
      }
    })
  }


  stakeDegoDividendGego = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, _heroId, nftId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let affName = localStorage.getItem("parentRefName")
        if (!affName) {
          affName = "";
        }
        let result = await contract.methods.stakeGego(_heroId, nftId, affName).send(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("stakeDegoDividendGego ex...", ex)
        resolve(false)
      }
    })
  }

  
  withdrawDegoDividendGego = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId, nftId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.withdrawGego(heroId, nftId).send(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("withdrawDegoDividendGego ex...", ex)
        resolve(false)
      }
    })
  }

  stakeDividendCocos = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId, amount } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let affName = localStorage.getItem("parentRefName")
        if (!affName) {
          affName = "";
        }
        let result = await contract.methods.stakeDego(heroId, amount, affName).send(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("stakeDegoDividendGego ex...", ex)
        resolve(false)
      }
    })
  }

  withdrawDividendCocos = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId, amount } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let affName = localStorage.getItem("parentRefName")
        if (!affName) {
          affName = "";
        }
        let result = await contract.methods.withdrawDego(heroId, amount).send(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("stakeDegoDividendGego ex...", ex)
        resolve(false)
      }
    })
  }


  getAccBalances = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getAccBalances(heroId, account).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getAccBalances ex...", ex)
        resolve(false)
      }
    })
  }
  getAccLastStakedTime = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, teamId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getAccLastStakedTime(teamId, account).call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getAccLastStakedTime ex...", ex)
        resolve(false)
      }
    })
  }

  getPunishTime = (poolType) => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods._punishTime().call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getPunishTime ex...", ex)
        resolve(false)
      }
    })
  }

  getDividendReward = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, heroId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.getReward(heroId).send(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("getDividendReward ex...", ex)
        resolve(false)
      }
    })
  }

  withdrawHero = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { poolType, teamId } = payload
        let contract = this.getBaseContract(poolType)
        const account = store.getStore('global', 'account')
        let result = await contract.methods.withdrawHero(teamId).send(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("withdrawHero ex...", ex)
        resolve(false)
      }
    })
  }

  stakeHero = (payload) => {
    return new Promise(async (resolve) => {
      try {
        let { teamId } = payload
        let contract = this.getBaseContract("Dividends")
        const account = store.getStore('global', 'account')
        let affName = localStorage.getItem("parentRefName")
        if (!affName) {
          affName = "";
        }
        let result = await contract.methods.stakeHero(teamId, affName).send(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("stakeHero ex...", ex)
        resolve(false)
      }
    })
  }
  
  getCueCycleRewardConfig = () => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getBaseContract('Dividends')
        const account = store.getStore('global', 'account')
        let result = await contract.methods._cueCycleRewardConfig().call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("_cueCycleRewardConfig ex...", ex)
        resolve(false)
      }
    })
  }

  
  
  
  getCurCycleStartTime = () => {
    return new Promise(async (resolve) => {
      try {
        let contract = this.getBaseContract('Dividends')
        const account = store.getStore('global', 'account')
        let result = await contract.methods._curCycleStartTime().call(
          {
            from: account
          }
        );
        resolve(result)
      } catch (ex) {
        console.log("_curCycleStartTime ex...", ex)
        resolve(false)
      }
    })
  }

  
  
// =================================================

}

let store = new Store();

export default {
  store,
  dispatcher,
  emitter
};
